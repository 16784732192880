import IndexedAnnotation from './indexed-annotation.vue';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'BuildingPlanTable',
    components: {
        IndexedAnnotation: IndexedAnnotation,
    },
    computed: {
        getPageIndexedAnnotations: function () {
            var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
            var page = buildingPlanController.getPage();
            return page.annotations;
        },
    },
};
