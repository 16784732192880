import eventBus from '../../../../../commons/EventBus';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'CatalogProduct',
    props: {
        reference: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        thumbnailPath: {
            type: String,
            default: '',
        },
        customClickHandling: {
            type: Boolean,
            default: false,
        },
        isRawThumbnailPath: {
            type: Boolean,
            default: false,
        },
        isAvailable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['click', 'meshInitializing', 'meshInitializingError'],
    data: function () {
        return {
            thumbnailUrl: this.buildThumbnailUrl(),
            isSelected: false,
        };
    },
    mounted: function () {
        var _this = this;
        window[APP_SYMBOL].events.on('@selection-manager.end-initialization', function () {
            _this.isSelected = false;
        });
    },
    methods: {
        /**
         * Add the selected product to the scene and start the drag and drop
         */
        addToScene: function (event) {
            var _this = this;
            this.isSelected = true;
            if (window[APP_SYMBOL].modules.measurementManager.controller.measureModeActivated) {
                window[APP_SYMBOL].modules.measurementManager.controller.toggleMeasureMode();
            }
            if (window[APP_SYMBOL].modules.catalogManager.catalogLoaded) {
                if (window[APP_SYMBOL].modules.catalogManager.products[this.reference].isAvailable) {
                    window[APP_SYMBOL].events.emit('showLoader');
                    window[APP_SYMBOL].modules.projectManager.controller
                        .addToScene(this.reference)
                        .then(function (struct) {
                        window[APP_SYMBOL].events.emit('hideLoader');
                        if (!struct) {
                            // Mesh couldn't be instantiate
                            _this.thumbnailUrl = '/assets/images/unavailable.png';
                        }
                        else {
                            _this.$emit('meshInitializing');
                            window[APP_SYMBOL].modules.selectionManager.initializingEntityOnScene(struct, {
                                x: event.clientX,
                                y: event.clientY,
                            });
                            eventBus.emit('activating-tool');
                        }
                    })
                        .catch(function (exception) {
                        window[APP_SYMBOL].log.error('EXCEPTION WHILE LOADING MESH: ', exception);
                        window[APP_SYMBOL].events.emit('hideLoader');
                        _this.$emit('meshInitializingError');
                    });
                }
                else {
                    window[APP_SYMBOL].log.warn("The reference : ".concat(this.reference, ", is currently unavailable !"));
                }
            }
            else {
                window[APP_SYMBOL].log.info('Library not loaded yet ..');
            }
        },
        /**
         * If the url loading fails the thumbnail is set to a default one
         */
        setFallbackThumbnailUrl: function () {
            this.thumbnailUrl = '/assets/images/default_thumbnail.png';
        },
        /**
         * Compute the thumbnail url
         */
        buildThumbnailUrl: function () {
            if (this.isRawThumbnailPath) {
                return this.thumbnailPath;
            }
            if (this.isAvailable) {
                return "".concat(this.thumbnailPath).concat(this.reference, ".jpg");
            }
            return '/assets/images/unavailable.png';
        },
    },
};
