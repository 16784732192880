/* eslint max-classes-per-file: 0 */
import MeshUtility from './utility/mesh-utility';
import EntitiesData from '../../entities-data';
import ConsoleHelper from '../../helpers/console-helper';
import config from 'defaultConfig';

import { SceneLoader, MeshBuilder, Vector3, BoundingSphere } from '@babylonjs/core';
import '@babylonjs/loaders/glTF';

import { v4 as uuid } from 'uuid';

const { ENTITY_TYPE } = EntitiesData;

const CORNER_SIZE = 62;

class MeshInfos {
    constructor(mesh, { hasEnvSnapping = false }) {
        this.mesh = mesh;
        this.hasEnvSnapping = hasEnvSnapping;
    }
}

export default class MeshManager {
    /**
     * @class bematrix.MeshManager
     * */
    constructor(context) {
        this.context = context;
        /** @type String
         * Meshes extension */
        this.fileExtension = config.fileType;

        /** @type String
         * Meshes path */
        this.path = '/assets/models/';

        /** @type Number
         * Scale applied to the loaded meshes */
        this.scaling = 0.001;

        this.MaterialManager = this.context.modules.materialManager;

        this.loadedMeshes = {};
        ConsoleHelper.expose('loadedMeshes', this.loadedMeshes);

        this.context.events.on('@obsidian-engine.engine-ready', (scene) => {
            /** @type Scene  */
            this.scene = scene;
        });
    }

    applyMaterialToMesh(mesh, materialId, ref, entityInfo = null) {
        const originalMesh = this.loadedMeshes[ref].mesh;
        mesh.material = this.MaterialManager.loadMaterial(materialId);

        const ralColor = materialId.startsWith('RAL') ? materialId.split('-').pop() : null;
        for (let i = 0; i < mesh.getChildMeshes().length; i++) {
            const originalSubMesh = originalMesh.getChildMeshes()[i];
            const clonedSubMesh = mesh.getChildMeshes()[i];
            const hasCustomMaterial = clonedSubMesh.name.toLowerCase().includes('custom');
            if (!hasCustomMaterial) {
                if (entityInfo?.category === 'INFILLS') {
                    if (clonedSubMesh.name.includes('isFront')) {
                        clonedSubMesh.material = this.MaterialManager.loadMaterial(materialId);
                    }
                } else if (clonedSubMesh.parent.name !== 'infill') {
                    if (clonedSubMesh.material?.lightmapTexture) {
                        clonedSubMesh.material = this.MaterialManager.createRalVersionFromMaterial(mesh.material, originalSubMesh.material);
                    } else if (ralColor) {
                        clonedSubMesh.material = this.MaterialManager.loadMaterial(materialId);
                    }
                }
            }
        }
    }

    /**
     * Return a new instance of the corresponding mesh and material
     * Its geometry must already be loaded
     * @param {String} ref
     * @param {String} materialId
     * @param {Object} meshInfos
     * @returns {Mesh}
     */
    getMeshFromLoadedGeometry(ref, materialId, meshInfos = null, entityInfo = null) {
        let infos = meshInfos;
        if (!infos) {
            infos = this.loadedMeshes[ref];
        }

        if (!infos) {
            this.context.log.error(`MeshManager : trying to instanciate a mesh from an undefined geometry ! (ref : ${ref} )`);
            return null;
        }

        const clonedMesh = infos.mesh.clone(`${ref}-${uuid()}`);

        this.applyMaterialToMesh(clonedMesh, materialId, ref, entityInfo);

        // const instance = infos.clones[materialId];
        clonedMesh.isVisible = true;
        clonedMesh.setEnabled(true);

        // Add subcolliders to the clone
        if (infos.mesh.subCollidersBB) {
            clonedMesh.subCollidersBB = infos.mesh.subCollidersBB;
        }

        if (infos.mesh.boundingSphere) {
            clonedMesh.boundingSphere = new BoundingSphere(infos.mesh.boundingSphere.minimum, infos.mesh.boundingSphere.maximum);
        }

        clonedMesh.position = Vector3.Zero();
        clonedMesh.computeWorldMatrix(true);

        MeshUtility.AddMetadataProperties(clonedMesh, { ref });

        return clonedMesh;
    }

    loadMesh(catalogItem, url) {
        if (this.loadedMeshes[catalogItem.ref]) {
            return this.loadedMeshes[catalogItem.ref];
        }
        return SceneLoader.LoadAssetContainerAsync(url, '', this.scene).then((assetContainer) => {
            const meshInfos = this.getMeshInfos(assetContainer, catalogItem);
            this.loadedMeshes[catalogItem.ref] = meshInfos;
            const { mesh } = meshInfos;
            mesh.setEnabled(false);
            return meshInfos;
        });
    }

    addDebugMesh() {
        SceneLoader.Append('assets/data/', 'xyz.glb', this.scene);
    }

    getMeshInfos(assetContainer, catalogItem) {
        let mainMesh;
        const otherMeshes = [];
        const subCollidersBB = [];
        let hasNativeRoot = false;
        let hasEnvSnapping = false;
        let __root__;

        for (let i = assetContainer.meshes.length - 1; i >= 0; i--) {
            const mesh = assetContainer.meshes[i];
            if (mesh.name.includes('ROOT')) {
                hasNativeRoot = true;
                mainMesh = mesh;
                mainMesh.parent = null;
            } else if (mesh.name.toLowerCase().includes('collider')) {
                mesh.parent = null;
                subCollidersBB.push(MeshUtility.GenerateSubBoundingBox(mesh));
                mesh.dispose();
            } else if (mesh.name !== '__root__') {
                if (mesh.name.includes('ENV_SNAPPING')) {
                    hasEnvSnapping = true;
                    mesh.visibility = 0;
                }
                otherMeshes.push(mesh);
            } else {
                __root__ = mesh;
            }
        }

        if (!mainMesh) {
            mainMesh = MeshBuilder.CreatePlane('plane', { width: 0.001, height: 0.001 });
            mainMesh.isVisible = false;
        } else {
            mainMesh.material.dispose(true, true);
            mainMesh.material = this.MaterialManager.loadMaterial('frames-ano');
        }

        if (hasEnvSnapping) {
            mainMesh.isPickable = false;
        }

        mainMesh.subCollidersBB = subCollidersBB;

        otherMeshes.forEach((mesh) => {
            if (!hasNativeRoot) {
                mesh.parent = mainMesh;
            }
            if (hasEnvSnapping) {
                if (!mesh.name.includes('ENV_SNAPPING')) {
                    mesh.isPickable = false;
                }
            }
            if (catalogItem.category === 'INFILLS') {
                if (mesh.name.includes('isFront')) {
                    mesh.material = this.MaterialManager.loadMaterial('base-infill-material');
                } else {
                    mesh.material = this.MaterialManager.loadMaterial('back-infill-material');
                }
            } else if (mesh.material) {
                mesh.material = this.MaterialManager.createBecadMaterialFromGlbMaterial(mesh.material);
            } else {
                mesh.material = this.MaterialManager.loadMaterial('frames-ano');
            }
        });

        mainMesh.computeWorldMatrix(true);
        mainMesh.setBoundingInfo(MeshUtility.GetBoundingBox(mainMesh));

        __root__?.dispose();

        return new MeshInfos(mainMesh, { hasEnvSnapping });
    }

    /**
     * Extract dimensions of the object out of it's reference
     * /!\ CAREFULL ! This is mainly used for Bematrix rules like infill positionning or swapping an
     * object. This must not be used in a geometrical case, when you should instead use the mesh
     * bounging box. /!\
     * @param {String} ref reference of the object
     * @param {*} entityType needed to know the reference format
     * @returns {*} object containing with, height and depth properties
     */
    getDimensionsFromRef(ref, entityType = ENTITY_TYPE.STRAIGHT) {
        const refArray = ref.split(' ');
        let depth = parseInt(refArray[3], 10) * this.scaling;
        let width = parseInt(refArray[1], 10) * this.scaling;
        let height = parseInt(refArray[2], 10) * this.scaling;

        if (entityType === ENTITY_TYPE.COVER) {
            if (refArray[refArray.length - 1] === 'D00') {
                depth = width;
                height = 0.0085;
                width = 0.062;
            } else {
                depth = height;
            }
        } else if (entityType === ENTITY_TYPE.CORNER || entityType === ENTITY_TYPE.STRUCTURAL) {
            depth = width;
            width = CORNER_SIZE * this.scaling;
            height = CORNER_SIZE * this.scaling;
        } else if (entityType === ENTITY_TYPE.PERFECT) {
            depth = width;
            if (refArray[0] === '691' || refArray[0] === '671') {
                width = config.gridResolution; // Only width for 691
            }
        } else if (entityType === ENTITY_TYPE.TRACKSTART) {
            depth = height;
            width = CORNER_SIZE * this.scaling;
            height = CORNER_SIZE * this.scaling;
        } else if (ENTITY_TYPE.SIDELED) {
            if (refArray[0] === '786') {
                depth = 0.184;
            } else if (refArray[0] === '781') {
                depth = 0.124;
            } else {
                depth = 0.058;
            }
        } else if (
            entityType === ENTITY_TYPE.STRAIGHT ||
            entityType === ENTITY_TYPE.BACKLED ||
            entityType === ENTITY_TYPE.MOTIONSKIN ||
            entityType === ENTITY_TYPE.GLASS
        ) {
            depth = 0.062;
        }

        if (Number.isNaN(width) || Number.isNaN(height) || Number.isNaN(depth)) {
            this.context.log.error('NaN dimension');
        }

        return { width, height, depth };
    }
}
