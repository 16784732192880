// Uncomment to activate debugLayer
// import "@babylonjs/core/Debug/debugLayer";
// import "@babylonjs/inspector";

import EventHandler from '../event-handler';
import * as Tween from '@tweenjs/tween.js';

import { Color3, Engine, Mesh, Scene, Vector3 } from '@babylonjs/core';

/**
 * Contains main function used to interact with object in the scene
 */
export default class EngineController {
    constructor(context) {
        this.context = context;
        this.engine = null;
        this.scene = null;
        this.canvas = null;
        this.ready = false;
        this.context.events.on('@bematrix-ui.canvas-ready', () => {
            this.initEngine();
        });
    }

    /** Called once the canvas is initialized by the UI module */
    initEngine() {
        this.initBabylonScene();

        // events
        this.eventHandler = new EventHandler(this.scene, this.canvas, this.context);

        // listeners
        this.initListening();

        // 3D engine ready !
        this.ready = true;
        this.context.events.emit('engine-ready', this.scene);
    }

    /**
     * Init. babylon engine and scene
     * Emits the engine-ready event afterward
     */
    initBabylonScene() {
        this.canvas = document.getElementById('main-canvas');
        this.canvas.oncontextmenu = () => false;

        this.engine = new Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });
        // Disable mesh manifest check
        // => TODO : once in production, remove this line and actually add manifests for meshes
        // Manifests allow storage of meshes in the local IndexedDB to reload it later
        this.engine.enableOfflineSupport = false;

        this.scene = new Scene(this.engine);

        // Uncomment to activate debugLayer
        // this.scene.debugLayer.show();

        // add Axes to the scene
        if (process.env.NODE_ENV === 'development') {
            const size = 1;
            const axisX = Mesh.CreateLines('axisX', [Vector3.Zero(), new Vector3(size, 0, 0)], this.scene);
            axisX.color = new Color3(1, 0, 0);

            const axisY = Mesh.CreateLines('axisY', [Vector3.Zero(), new Vector3(0, size, 0)], this.scene);
            axisY.color = new Color3(0, 1, 0);

            const axisZ = Mesh.CreateLines('axisZ', [Vector3.Zero(), new Vector3(0, 0, size)], this.scene);
            axisZ.color = new Color3(0, 0, 1);
        }

        // Render loop
        this.engine.runRenderLoop(() => {
            this.scene.render();

            this.context.events.emit('main-scene-rendered');

            this.context.events.emit('animate');

            Tween.update();
        });
    }

    /**
     * Init event listeners handled by this controller :
     * Selection, duplication, gizmos, drags, windows resize
     */
    initListening() {
        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }
}
