import textArrow from './text-arrow.vue';
import EventBus from '../../eventBus';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import { IMAGE_MODES } from '../../../../building-plan-manager/src/controller/building-plan-image-controller';
import { APP_SYMBOL } from '../../..';
export default {
    components: { textArrow: textArrow },
    props: {
        textObject: {
            default: function () { return ({}); },
            type: Object,
        },
    },
    data: function () {
        return {
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
            localTextObject: this.textObject,
            dragging: false,
            canvasContainer: null,
            startOffsetX: 0,
            startOffsetY: 0,
            draggableElement: null,
            textElement: null,
            sizeInfos: {
                x: 0,
                y: 0,
            },
        };
    },
    computed: {
        isFocused: function () {
            return this.localTextObject.isFocused;
        },
        isSettingArrow: function () {
            return this.localTextObject.settingArrow;
        },
        isScenePage: function () {
            return this.bpController.getPage().type === PAGE_TYPES.SCENE;
        },
    },
    mounted: function () {
        this.draggableElement = this.$refs['draggable-element'];
        this.textElement = this.$refs['text-element'];
        this.textElement.innerText = this.textObject.text;
        this.canvasContainer = document.getElementById('canvas-container');
        // Restore text position
        this.draggableElement.style.left = this.localTextObject.position.x;
        this.draggableElement.style.top = this.localTextObject.position.y;
        // When a textbox get focused, other ones get blurred
        this.computeSizeInfos();
        EventBus.$on('textbox-focus', this.onOtherTextboxFocus);
    },
    beforeUnmount: function () {
        this.blur();
        EventBus.$off('textbox-focus', this.onOtherTextboxFocus);
    },
    methods: {
        // The following methods handle manual focus of the text box
        onOtherTextboxFocus: function (targetElement) {
            if (targetElement !== this.$el) {
                this.onClickOutside();
            }
        },
        onClickOutside: function () {
            this.blur();
            this.bpController.imageController.captureImage(IMAGE_MODES.HTML);
        },
        focus: function () {
            if (!this.isFocused) {
                this.localTextObject.isFocused = true;
                this.textElement.contentEditable = true;
                EventBus.$emit('textbox-focus', this.$el);
                this.canvasContainer.addEventListener('click', this.onClickOutside);
                window[APP_SYMBOL].events.on('@obsidian-engine.unselect', this.onClickOutside);
            }
        },
        blur: function () {
            if (this.isFocused) {
                this.localTextObject.isFocused = false;
                this.textElement.contentEditable = false;
                this.updateText();
                this.canvasContainer.removeEventListener('click', this.onClickOutside);
                window[APP_SYMBOL].events.removeListener('@obsidian-engine.unselect', this.onClickOutside);
            }
            if (this.dragging) {
                this.dragEnd();
            }
        },
        updateText: function () {
            this.localTextObject.text = this.textElement.innerText;
            this.computeSizeInfos();
        },
        addArrow: function () {
            this.bpController.addArrowToText();
            window[APP_SYMBOL].events.removeListener('@obsidian-engine.unselect', this.onClickOutside);
        },
        computeSizeInfos: function () {
            this.sizeInfos.x = this.textElement.clientWidth / 2;
            this.sizeInfos.y = this.textElement.clientHeight / 2;
        },
        // Manual dragging management
        dragStart: function (event) {
            this.dragging = true;
            // Initial offset on the drag handle
            this.startOffsetX = event.offsetX;
            this.startOffsetY = event.offsetY;
            this.draggableElement.style.pointerEvents = 'none';
            this.textElement.contentEditable = false;
            this.canvasContainer.addEventListener('mousemove', this.drag);
        },
        drag: function (event) {
            // Subtract the canvas offset in the window + the drag handle initial offset from
            // the mouse position in the window
            var positionX = event.offsetX - this.startOffsetX;
            var positionY = event.offsetY - this.startOffsetY;
            this.draggableElement.style.left = positionX;
            this.draggableElement.style.top = positionY;
            this.localTextObject.position.x = positionX;
            this.localTextObject.position.y = positionY;
        },
        dragEnd: function () {
            this.draggableElement.style.pointerEvents = 'auto';
            this.textElement.contentEditable = true;
            this.canvasContainer.removeEventListener('mousemove', this.drag);
            this.dragging = false;
            this.startOffsetX = 0;
            this.startOffsetY = 0;
        },
    },
};
