import MeasurementHelper, { MEASUREMENT_SPHERE_TYPE } from '../helpers/measurement-helper';
import { v4 as uuid } from 'uuid';
import Class from 'abitbol';

/**
 * A mixin given to every structure that can have measurement point
 */
const MeasurableMixin = Class.$extend({
    initMeasurement() {
        this.isMeasurable = true;
        this.measurementPoints = [];
    },

    /**
     * Displays (or hide) the measurement points of the measurable entity
     * @param {*} visibility
     */
    toggleMeasurementPointsVisibility(visibility) {
        if (!this.visible) {
            return;
        }
        if (visibility) {
            this.measurementPoints.forEach((measurementPoint) => {
                if (!measurementPoint.pointMesh) {
                    const point = measurementPoint.transformNode;
                    const sphere = MeasurementHelper.getNewSphere(`${point.name} ${uuid()}`, MEASUREMENT_SPHERE_TYPE.Basic);
                    MeasurementHelper.setMeasurementSphereParent(sphere, this.mesh, point);
                    measurementPoint.pointMesh = sphere;
                    Object.assign(sphere, { metadata: { measurementPoint } });
                    if (!MeasurementHelper.checkCollisionBetweenNewPointsAndExistingPoints(sphere)) {
                        MeasurementHelper.measurementPoints.push(sphere);
                    } else {
                        sphere.dispose();
                    }
                }
            });
        } else {
            this.measurementPoints.forEach((measurementPoint) => {
                if (measurementPoint.pointMesh) {
                    MeasurementHelper.removePoint(measurementPoint.pointMesh);
                    measurementPoint.pointMesh.dispose();
                    measurementPoint.pointMesh = null;
                }
            });
        }
    },
});

export default MeasurableMixin;
