import product from './group-product.vue';
import EventBus from '../../eventBus';
import RightsHelper from '../../../../../helpers/rights-helper';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import { APP_SYMBOL } from '../../..';
import { directive as onClickaway } from 'vue-clickaway';
export default {
    name: 'Group',
    directives: {
        onClickaway: onClickaway,
    },
    components: { product: product },
    props: {
        group: {
            type: Object,
            default: function () { return ({}); },
        },
        showOptions: {
            type: Boolean,
            default: function () { return true; },
        },
    },
    data: function () {
        return {
            productList: this.group.productList,
            // The info if we are editing the group label/title
            isEditing: !this.group.default && !this.group.nameLocked,
            isDisplayed: true,
            showFrames: true,
            // Info used when we rename the group and want to check if something has changed
            tempName: this.group.name,
            buildingPlanData: null,
            PAGE_TYPES: PAGE_TYPES,
        };
    },
    computed: {
        // Check if a group is selected, easy to compute
        isSelected: function () {
            var groupSelection = window[APP_SYMBOL].modules.groupManager.GroupSelection;
            return groupSelection.isSelectedGroup(this.group);
        },
        updatedProductList: function () {
            return this.productList.filter(function (currentProduct) { return currentProduct.__name__ !== 'connector'; });
        },
        // The css class of the components, easy to compute
        displayClass: function () {
            var measurementController = window[APP_SYMBOL].modules.measurementManager.controller;
            return {
                selected: this.isSelected,
                hidden: !this.isDisplayed ||
                    (RightsHelper.isModeBuildingPlan() && this.buildingPlanData.isCameraFixed) ||
                    measurementController.measureModeActivated ||
                    this.buildingPlanData.currentPage.type === PAGE_TYPES.HTML,
            };
        },
    },
    watch: {
        isEditing: function (val) {
            if (val) {
                // When we start editing the group name we store it
                // in the tempName data for checking changes
                this.tempName = this.group.name;
            }
        },
    },
    created: function () {
        this.buildingPlanData = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController.vueData;
    },
    mounted: function () {
        var _this = this;
        var displayHelper = window[APP_SYMBOL].modules.groupManager.DisplayHelper;
        this.isDisplayed = displayHelper.isGroupVisible(this.group);
        this.initListening();
        // Happens when we click on the "collapse all group" button
        EventBus.$on('collapseGroups', function () {
            _this.hideFrames();
        });
        window[APP_SYMBOL].events.on('@history.history-go', function () {
            _this.$forceUpdate(); // Name doesn't update unless we interact with
            // the group view
        });
    },
    beforeUnmount: function () {
        var _this = this;
        EventBus.$off('collapseGroups', function () {
            _this.hideFrames();
        });
    },
    methods: {
        startNameEdition: function () {
            if (!RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan() && !this.group.default) {
                this.isEditing = true;
            }
        },
        // Select or unselect a group on the UI
        selectGroup: function () {
            if (!this.buildingPlanData.isCameraFixed && this.buildingPlanData.currentPage.type !== PAGE_TYPES.HTML) {
                if (!this.isDisplayed) {
                    return;
                }
                var groupSelection = window[APP_SYMBOL].modules.groupManager.GroupSelection;
                if (this.isSelected) {
                    groupSelection.unselectGroup(this.group);
                }
                else {
                    groupSelection.selectGroup(this.group);
                }
            }
        },
        // Display all the product of a group
        displayGroup: function () {
            var displayHelper = window[APP_SYMBOL].modules.groupManager.DisplayHelper;
            displayHelper.setGroupVisibility(this.group, true);
            this.isDisplayed = true;
        },
        // Initiate all the events
        initListening: function () {
            var _this = this;
            window[APP_SYMBOL].events.on('@group-manager.entity-added-to-group', function (newGroup, oldGroup) {
                if (newGroup.id === _this.group.id || oldGroup.id === _this.group.id) {
                    _this.updateVisibility();
                }
            });
            [
                '@data-store.entity-removed',
                '@group-manager.product-added-group',
                '@group-manager.product-removed-group',
                '@group-manager.cleaned-group',
                '@history.history-go',
                '@entity-manager.history-change-visibility',
                '@building-plan-manager.restore-page-products-visibility',
            ].forEach(function (eventName) {
                window[APP_SYMBOL].events.on(eventName, function () {
                    _this.updateVisibility();
                });
            });
        },
        // Hide all the product of a group
        hideGroup: function () {
            if (this.isSelected) {
                this.selectGroup();
            }
            var groupSelection = window[APP_SYMBOL].modules.groupManager.GroupSelection;
            this.group.productList.forEach(function (currentProduct) {
                if (groupSelection.isSelectedProduct(currentProduct)) {
                    groupSelection.unselectProduct(currentProduct);
                }
            });
            var displayHelper = window[APP_SYMBOL].modules.groupManager.DisplayHelper;
            displayHelper.setGroupVisibility(this.group, false);
            this.isDisplayed = false;
        },
        updateVisibility: function () {
            var displayHelper = window[APP_SYMBOL].modules.groupManager.DisplayHelper;
            this.isDisplayed = displayHelper.isGroupVisible(this.group);
        },
        // When we click on the arrow icon to collapse/uncolapse the group
        toggleFrames: function () {
            this.showFrames = !this.showFrames;
        },
        // Collapse the group
        hideFrames: function () {
            this.showFrames = false;
        },
        // When we click outside after editing or when we press 'enter' key
        saveGroupName: function () {
            if (!this.isEditing) {
                return;
            }
            var groupController = window[APP_SYMBOL].modules.groupManager.Controller;
            this.isEditing = false;
            this.group.nameLocked = true;
            if (this.tempName !== this.group.name) {
                if (this.group.name.trim() === '') {
                    this.group.name = this.tempName;
                }
                else {
                    groupController.checkNamesDuplicates(this.group);
                    // Snapshot only when the name of the group has changed
                    window[APP_SYMBOL].modules.history.snapshot();
                }
            }
        },
    },
};
