import HighlightBehaviorHelper from '../helpers/highlight-behavior-helper';
import ConsoleHelper from '../../../../helpers/console-helper';
import { Color3 } from '@babylonjs/core';

export default class HighlightController {
    constructor(context) {
        this.context = context;
        this.zOffset = -2;
        this.edgesWidth = 1;
        this.highlightLayer = null;
        this.edgesDetection = 0.95; // default value
        ConsoleHelper.expose('highlightController', this);
    }

    /**
     * Enable/disable highlight a given mesh to a given color
     * @param {Mesh} selectedMesh
     * @param {Boolean} highlightValue true if we enable the highlight, false if we disable
     * @param {Color3} color (Optional, Green by default, useless if
     *                         highlightValue is false) the color of the highlight
     */
    toggleHighlightMesh(selectedMesh, highlightValue, color = Color3.Green()) {
        const mesh = selectedMesh;
        if (highlightValue) {
            mesh.enableEdgesRendering(this.edgesDetection);
            mesh.edgesColor = color.toColor4();
            mesh.edgesRenderer.lineShader.zOffset = this.zOffset;
            mesh.edgesWidth = this.edgesWidth;
            mesh.getChildMeshes(true).forEach((childMesh) => {
                childMesh.enableEdgesRendering(this.edgesDetection);
                childMesh.edgesColor = mesh.edgesColor;
                childMesh.edgesRenderer.lineShader.zOffset = this.zOffset;
                childMesh.edgesWidth = this.edgesWidth;
            });
        } else {
            mesh.disableEdgesRendering();
            mesh.getChildMeshes(true).forEach((childMesh) => {
                childMesh.disableEdgesRendering();
            });
        }
    }

    /** *
     * Enable/disable highlight to a given mesh LIST to a given color
     * @param {Array<Mesh>} mesh
     * @param {Boolean} highlightValue true if we enable the highlight, false if we disable
     * @param {Color3} color (Optional, Green by default, useless if
     *                         highlightValue is false) the color of the highlight
     */
    toggleHighlightMeshes(meshList, highlightValue, color = Color3.Green()) {
        meshList.forEach((mesh) => {
            this.toggleHighlightMesh(mesh, highlightValue, color);
        });
    }

    createNewHighlightBehavior(mesh) {
        return new HighlightBehaviorHelper(this, mesh);
    }
}
