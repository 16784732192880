export default class AbstractOptionController {
    constructor(optionController, context) {
        this.context = context;
        this.selectionController = null;
        this.optionController = optionController;
    }

    /**
     * Load meshes and assign the selectionController when corresponding modules are ready
     */
    initializeEvents(meshRefs, meshesLoadedCallback = () => {}) {
        this.context.events.on('@obsidian-engine.engine-ready', () => {
            this.scene = this.context.modules.obsidianEngine.controller.scene;
        });

        this.context.events.on('@catalog-manager.catalog-initialized', () => {
            const promises = [];
            Object.values(meshRefs).forEach((ref) => {
                try {
                    const { catalogManager } = this.context.modules;
                    promises.push(catalogManager.tryRegisterMeshGeometry(ref));
                } catch (err) {
                    this.context.log.error(`Could not add option ${ref} in catalog:`, err);
                }
            });
            Promise.all(promises).then(() => {
                meshesLoadedCallback();
            });
        });
    }
}
