import MeasurementController from './src/controllers/measurement-controller';
import MeasurementHelper from './src/helpers/measurement-helper';

const MODULE_NAME = 'measurement-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['obsidian-engine', 'gui-manager', 'material-manager', 'highlight-manager', 'data-store', 'history'],
    load(app) {
        window[APP_SYMBOL] = app;
        MeasurementHelper.setContext(app);
        return {
            controller: new MeasurementController(app),
        };
    },
    unload() {},
};
