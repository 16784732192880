import config from 'defaultConfig';

export default class RequestController {
    constructor(context) {
        this.context = context;
        this.token = this.context.modules.iframeApi.$class.$getConfig().token || config.token;
    }

    setAuthHeaders(headers) {
        headers.append('X-AUTH-TOKEN', this.token);
        return headers;
    }

    async doRequest(url, shouldAuth = false) {
        const headers = new Headers();
        if (this.token && shouldAuth) {
            this.setAuthHeaders(headers);
        }
        const requestInit = {
            headers,
        };
        return fetch(url, requestInit);
    }

    getRaw(url, shouldAuth = false) {
        return this.doRequest(url, shouldAuth);
    }

    async postRaw(url, shouldAuth = false) {
        const headers = new Headers();
        if (this.token && shouldAuth) {
            this.setAuthHeaders(headers);
        }
        const requestInit = {
            method: 'POST',
            headers,
        };
        return fetch(url, requestInit);
    }

    getJson(url, shouldAuth = false) {
        return this.doRequest(url, shouldAuth).then((res) => res.json());
    }

    postJson(url, shouldAuth = false) {
        return this.postRaw(url, shouldAuth).then((res) => res.json());
    }
}
