import TextBox from './text-box.vue';
import { APP_SYMBOL } from '../../..';
export default {
    components: {
        TextBox: TextBox,
    },
    computed: {
        getPageTexts: function () {
            var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
            var page = buildingPlanController.getPage();
            return page.texts;
        },
    },
};
