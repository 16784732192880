import PdfHtmlContent from './pdf-mode/pdf-html-content.vue';
import RightsHelper from '../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../..';
import config from 'defaultConfig';
export default {
    name: 'ObsidianCanvas',
    components: {
        PdfHtmlContent: PdfHtmlContent,
    },
    props: {
        isCanvasReady: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
            engineController: window[APP_SYMBOL].modules.obsidianEngine.controller,
            cameraController: window[APP_SYMBOL].modules.cameraManager.controller,
            // In mm, landscape orientation
            canvasWidth: 640,
            canvasHeight: 480,
            a4Height: 210,
            a4Width: 297,
            config: config,
        };
    },
    watch: {
        config: {
            handler: function () {
                this.reajustCanvas();
            },
            deep: true,
        },
    },
    mounted: function () {
        var _this = this;
        // This avoid bad resizing of canvas when the ui load and the window is not focused
        window.addEventListener('focus', function () {
            _this.reajustCanvas();
        });
        window.addEventListener('resize', function () {
            _this.reajustCanvas();
        });
        this.reajustCanvas();
        var engineCallback = function () {
            _this.engineController.engine.resize();
            _this.cameraController.adaptOrthoSizeToCanvas();
        };
        if (this.engineController.engine) {
            engineCallback();
        }
        else {
            window[APP_SYMBOL].events.once('@obsidian-engine.engine-ready', engineCallback);
        }
    },
    methods: {
        reajustCanvas: function () {
            var _this = this;
            setTimeout(function () {
                var canvasContainer = document.getElementById('canvas-container');
                var canvasWidth = Math.ceil(canvasContainer.clientWidth);
                var canvasHeight = Math.ceil(canvasContainer.clientHeight);
                _this.$refs.canvas3D.width = canvasWidth;
                _this.$refs.canvas3D.height = canvasHeight;
                _this.engineController.engine.resize();
                _this.cameraController.adaptOrthoSizeToCanvas();
                window[APP_SYMBOL].events.emit('canvas-resized', { width: canvasWidth, height: canvasHeight });
            }, 0);
        },
        validateEventPropagation: function (e) {
            if (RightsHelper.isModeBuildingPlan() && this.bpController.isCameraFixed) {
                e.stopImmediatePropagation();
            }
        },
    },
};
