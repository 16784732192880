import OptionController from './controller/option-controller';
import RalColors from './utility/ral-colors';
import OptionableMixin from './model/optionable-mixin';
import ColorableMixin from './model/colorable-mixin';
import InfillHelper from './helper/infill-helper';

const MODULE_NAME = 'option-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'geometry-utility',
        'iframe-api',
        'mesh-manager',
        'material-manager',
        'data-store',
        'highlight-manager',
        'collision-manager',
        'catalog-manager',
        'assets-manager',
        'history',
        'obsidian-engine',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        InfillHelper.setContext(app);
        return {
            optionController: new OptionController(app),
            OptionableMixin,
            ColorableMixin,
            RalColors,
        };
    },
    unload() {},
};
