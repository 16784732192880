import { APP_SYMBOL } from '../..';
import EventBus from '../eventBus';
export default {
    name: 'EnvironmentSelectionDialog',
    props: {
        modelValue: {
            type: String,
            default: function () { return ''; },
        },
    },
    emits: ['update:modelValue'],
    data: function () {
        return {
            floors: {
                default: '/assets/textures/default_grid.png',
                white: '/assets/textures/white_floor.jpg',
                marble: '/assets/textures/marble_01_1k_jpg/marble_01_diff_1k.jpg',
                concrete: '/assets/textures/Concrete_011_SD/Concrete_011_COLOR.jpg',
                fabric_007: '/assets/textures/Fabric_007_SD/Fabric_007_COLOR.jpg',
                fabric_011: '/assets/textures/Fabric_011_SD/Fabric_011_COLOR.jpg',
                fabric_015: '/assets/textures/Fabric_015_SD/Fabric_015_COLOR.jpg',
                grass: '/assets/textures/Grass_001_SD/Grass_001_COLOR.jpg',
                rug: '/assets/textures/Rug_001/Rug_001_COLOR.jpg',
                tweed: '/assets/textures/Tweed_001_SD/Tweed_001_COLOR.jpg',
                wood_007: '/assets/textures/Wood_007_SD/Wood_007_COLOR.jpg',
                wood_020: '/assets/textures/Wood_020_SD/Wood_020_basecolor.jpg',
                wood_floor: '/assets/textures/Wood_Floor_007_SD/Wood_Floor_007_COLOR.jpg',
            },
        };
    },
    computed: {
        canConfirm: function () {
            return this.modelValue;
        },
    },
    methods: {
        confirm: function () {
            window[APP_SYMBOL].modules.iframeApi.sendEvent('screenshot');
            EventBus.$emit('close-environment-selector');
        },
        cancel: function () {
            EventBus.$emit('close-environment-selector');
            this.resetSelection();
        },
        resetSelection: function () {
            this.$emit('update:modelValue', null);
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
