import OptionableMixin from '../../../option-manager/model/optionable-mixin';
import { PAGE_TYPES } from '../model/page-structure';
import config from 'defaultConfig';

let _context;

export default {
    setContext(context) {
        _context = context;
    },

    /**
     * Return the provided filename or the config versionName
     * @param {String || null} filename
     * @returns {String} the filename to use when exporting a building plan pdf
     */
    getPdfFilename(filename) {
        return filename || config.versionName;
    },

    /**
     * Must be in another modules that manages the scene frames states
     * Return all the visible mesh of the scene refs in an array
     * @returns {Array[string]}
     */
    getActiveProductsReferences() {
        const visibleFrames = _context.modules.dataStore.listEntities('/products/*').filter((frame) => frame.visible);
        const visibleFramesRefs = visibleFrames.map((frame) => frame.ref);
        const visibleConnectorsRefs = _context.modules.dataStore
            .listEntities('/connectors/*')
            .filter((connector) => connector.visible)
            .map((connector) => connector.ref);

        const visibleProductsRefs = [...visibleFramesRefs, ...visibleConnectorsRefs];

        visibleFrames.forEach((frame) => {
            if (frame.isOptionable) {
                const visibleOptionsRefs = this.getActiveOptionsReferences(frame);

                visibleProductsRefs.push(...visibleOptionsRefs);
            }
        });

        return visibleProductsRefs;
    },

    getActiveOptionsReferences(product) {
        const visibleOptionsReferences = [];

        if (product.optionsVisibility.screens) {
            const screenRef = _context.modules.optionManager.optionController.getOptionReference(
                _context.modules.optionManager.optionController.optionsFamilies.SCREEN,
                product,
            );

            if (screenRef) {
                const optionNumber = _context.modules.optionManager.optionController.getEntityOptionsNumber(product).screenNumber;

                visibleOptionsReferences.push(...new Array(optionNumber).fill(screenRef));
            }
        }

        if (product.optionsVisibility.baseplates) {
            const baseplateRef = _context.modules.optionManager.optionController.getOptionReference(
                _context.modules.optionManager.optionController.optionsFamilies.BASEPLATE,
                product,
            );

            if (baseplateRef) {
                visibleOptionsReferences.push(baseplateRef);
            }
        }

        if (product.optionsVisibility.lights) {
            const lightRef = _context.modules.optionManager.optionController.getOptionReference(
                _context.modules.optionManager.optionController.optionsFamilies.LIGHT,
                product,
            );

            if (lightRef) {
                const multiplier = product.lightOption;
                const optionNumber = _context.modules.optionManager.optionController.getEntityOptionsNumber(product).lightNumber;

                visibleOptionsReferences.push(...new Array(optionNumber * multiplier).fill(lightRef));
            }
        }

        if (product.optionsVisibility.shelves) {
            const shelfRef = _context.modules.optionManager.optionController.getOptionReference(
                _context.modules.optionManager.optionController.optionsFamilies.SHELF,
                product,
            );

            if (shelfRef) {
                const optionNumber = _context.modules.optionManager.optionController.getEntityOptionsNumber(product).shelfNumber;

                visibleOptionsReferences.push(...new Array(optionNumber).fill(shelfRef));
            }
        }

        if (product.optionsVisibility.infills) {
            const referencePrefix = _context.modules.optionManager.optionController.getOptionReference(
                _context.modules.optionManager.optionController.optionsFamilies.INFILL,
                product,
            );

            if (referencePrefix) {
                // Some infills references are only prefixes of a real reference
                // so we look for all the references including the supposed prefix
                const references = Object.keys(_context.modules.catalogManager.products).filter((ref) => ref.includes(referencePrefix));

                // Infill have the same reference for both sides
                if (references.length === 1) {
                    visibleOptionsReferences.push(...new Array(product.infillOption).fill(references[0]));
                } else if (references.length === 2) {
                    // Different ref for each side
                    // Find the ref corresponding the infill side
                    if (product.infillOption === OptionableMixin.INFILL_OPTION.ONE_FACE) {
                        let infillRef = null;

                        if (product.swappedOptions.INFILL) {
                            infillRef = references.find((ref) => ref.includes('Rear') || ref.includes('IN'));
                        } else {
                            infillRef = references.find((ref) => ref.includes('Front') || ref.includes('OUT'));
                        }

                        visibleOptionsReferences.push(infillRef);
                    } else {
                        // Both refs are used
                        visibleOptionsReferences.push(...references);
                    }
                } else {
                    _context.log.error(`Found ${references.length}
                        infills references for the : ${referencePrefix},
                        reference prefix. An infill reference prefix should match at least 1
                        reference and no more that 2 references !`);
                }
            }
        }

        return visibleOptionsReferences;
    },

    /**
     * Must be in another modules that manages the scene frames states
     * Count occurences of the same products in the product array
     * @param {Array[string]} productsRefs
     * @returns {Object} key: product ref, value: product quantity
     */
    getProductsQuantities(productsRefs) {
        const productsQuantities = {};

        productsRefs.forEach((ref) => {
            productsQuantities[ref] = productsQuantities[ref] + 1 || 1;
        });

        return productsQuantities;
    },

    /**
     * Get all products visibility infos into an array of objects
     *
     * Each object contains an entity id and it's visibility infos
     * @returns {Array[Object]}
     */
    getProductVisibility() {
        const entities = _context.modules.dataStore.listEntities('/products/*');
        entities.push(..._context.modules.dataStore.listEntities('/connectors/*'));

        return entities.map((entity) => {
            const visbilityInfos = {
                id: entity.id,
                visible: entity.visible,
            };

            if (entity.isOptionable) {
                visbilityInfos.optionsVisibility = {
                    lights: entity.optionsVisibility.lights,
                    baseplates: entity.optionsVisibility.baseplates,
                    shelves: entity.optionsVisibility.shelves,
                    screens: entity.optionsVisibility.screens,
                    infills: entity.optionsVisibility.infills,
                };
            }

            return visbilityInfos;
        });
    },

    /**
     * Loop through every entry of the page productsVisibility metadata, to apply the visibility
     * infos of each products into the scene ones
     *
     * @param {Page} page the page we get the visibility infos from
     */
    restoreProductsVisibility(page) {
        if (page.scenePageMetadata.productsVisibility.length) {
            page.scenePageMetadata.productsVisibility.forEach((entityVisibilityInfos) => {
                const entity = _context.modules.dataStore.getEntity(entityVisibilityInfos.id);
                entity.visible = entityVisibilityInfos.visible;

                _context.modules.geometryUtility.toggleMeshVisibility(entity.mesh, entity.visible, false);

                if (entity.isOptionable) {
                    const { optionsVisibility } = entityVisibilityInfos;
                    if (optionsVisibility.infills && !_context.modules.optionManager.optionController.showInfills) {
                        _context.modules.optionManager.optionController.showInfills = true;
                        _context.modules.optionManager.optionController.infillsVisibilityLocked = false;
                    }
                    Object.keys(entity.optionsVisibility).forEach((key) => {
                        if (entity.optionsMeshes[key].length) {
                            entity.optionsVisibility[key] = optionsVisibility[key];
                            if (key === 'infills' && page.scenePageMetadata.connectorSelectionModeEnabled) {
                                // Don't show infills if we are in connectorSelectionMode
                                _context.modules.geometryUtility.toggleMeshesVisibility(entity.optionsMeshes[key], false, true);
                            } else {
                                _context.modules.geometryUtility.toggleMeshesVisibility(
                                    entity.optionsMeshes[key],
                                    entity.optionsVisibility[key],
                                    true,
                                );
                            }
                        }
                    });
                }
            });
        } else {
            const entities = _context.modules.dataStore.listEntities('/products/*');
            entities.push(..._context.modules.dataStore.listEntities('/connectors/*'));

            entities.forEach((entity) => {
                entity.visible = true;
                _context.modules.geometryUtility.toggleMeshVisibility(entity.mesh, true, false);

                if (entity.isOptionable) {
                    if (entity.optionsVisibility.infills && !_context.modules.optionManager.optionController.showInfills) {
                        _context.modules.optionManager.optionController.showInfills = true;
                        _context.modules.optionManager.optionController.infillsVisibilityLocked = false;
                    }
                    Object.keys(entity.optionsVisibility).forEach((key) => {
                        entity.optionsVisibility[key] = true;

                        if (entity.optionsMeshes[key].length) {
                            _context.modules.geometryUtility.toggleMeshesVisibility(entity.optionsMeshes[key], true, true);
                        }
                    });
                }
            });
        }
        _context.events.emit('restore-page-products-visibility');
    },

    /**
     * Displays the measurements of a page
     * @param {Page} page the page we want the measurements from
     */
    restoreMeasurements(page) {
        if (page.type === PAGE_TYPES.SCENE && page.scenePageMetadata.measurements.length) {
            page.scenePageMetadata.measurements.forEach((measurementEntity) => {
                // We use only context.modules.dataStore to avoid snapshot
                // Also we don't send a measurement-created event to avoid
                // an infinite event loop
                _context.modules.dataStore.addEntity(measurementEntity, '/measurements');
            });
        }
    },

    /**
     * Remove the measurements of the given page;
     * @param {Page} page
     */
    removePageMeasurements(page) {
        if (page.type === PAGE_TYPES.SCENE) {
            this.removeMeasurementList(page.scenePageMetadata.measurements);
        }
    },

    /**
     * Remove the measurement from context.modules.dataStore of a givenList
     * @param {Array<MeasurementEntity>} measurementList the list we want to remove from context.modules.dataStore
     */
    removeMeasurementList(measurementList) {
        measurementList.forEach((measurementEntity) => {
            _context.modules.dataStore.removeEntity(measurementEntity);
        });
    },

    /**
     * Set the connector mode of the page
     * Set the frames and infills visibility states
     * @param {Page} page
     * @param {boolean} connectorSelectionModeEnabled
     */
    setConnectorSelectionMode(page, connectorSelectionModeEnabled) {
        _context.modules.selectionManager.unselectAll();

        page.scenePageMetadata.connectorSelectionModeEnabled = connectorSelectionModeEnabled;

        _context.events.emit('toggle-connector-selection', connectorSelectionModeEnabled);

        _context.modules.materialManager.setFramesTransparency(connectorSelectionModeEnabled);

        // When enabling transparency mode, hide infills, same when disabling

        if (!connectorSelectionModeEnabled) {
            this.restoreProductsVisibility(page);
        } else {
            _context.modules.optionManager.optionController.setAllOptionsVisibility(
                !connectorSelectionModeEnabled,
                _context.modules.optionManager.optionController.optionsFamilies.INFILL,
            );
        }
    },

    /**
     * Return an annotation id for the selecion
     * if it's a single entity => returns its id
     * is it's a multi selection => returns the stringified array of ids
     * @returns {String}
     */
    selectionAnnotationId() {
        if (_context.modules.selectionManager.selectedMeshes.length === 1) {
            const selectedProduct =
                _context.modules.selectionManager.selectedMeshes[0].entity ||
                _context.modules.selectionManager.selectedMeshes[0].originalMesh;
            return selectedProduct.id;
        }
        const ids = _context.modules.selectionManager.selectedMeshes.map((mesh) => mesh.entity.id);
        ids.sort();
        return JSON.stringify(ids);
    },
};
