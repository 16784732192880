import LightController from './src/light-controller';

const MODULE_NAME = 'light-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['obsidian-engine'],
    load(app) {
        window[APP_SYMBOL] = app;
        return new LightController(app);
    },
    unload() {},
};
