import GroupController from './src/controllers/group-controller';
import GroupSelectionController from './src/controllers/group-selection-controller';
import DisplayHelper from './src/helpers/display-group-helper';
import SelectionHelper from './src/helpers/selection-helper';

const MODULE_NAME = 'group-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'data-store',
        'data-exporter',
        'geometry-utility',
        'obsidian-engine',
        'entity-manager',
        'selection-manager',
        'stonejs',
        'history',
        'option-manager',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        SelectionHelper.setContext(app);
        DisplayHelper.setContext(app);
        const Controller = new GroupController(app);
        const GroupSelection = new GroupSelectionController(Controller, app);
        return {
            Controller,
            DisplayHelper,
            GroupSelection,
        };
    },
    unload() {},
};
