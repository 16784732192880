import { Vector3, MeshBuilder, StandardMaterial, Color3, CreateSphere, LinesMesh } from '@babylonjs/core';

export const MEASUREMENT_SPHERE_TYPE = {
    Basic: 0,
    Hovered: 1,
    Active: 2,
};

let _context = null;

const MeasurementHelper = {
    setContext(context) {
        _context = context;
    },

    measurementPoints: [],

    /**
     * Initialize the cone mesh that will be used at the end of the mesurement arrow
     * @param {BABYLON.Scene} scene
     */
    initConeMesh(scene) {
        this.coneMesh = MeshBuilder.CreateCylinder(
            'cone',
            {
                diameterTop: 0,
                diameterBottom: 0.05,
                height: 0.05,
                tessellation: 10,
            },
            scene,
        );
        const mat = new StandardMaterial('coneMat', scene);
        this.coneMesh.rotate(Vector3.Left(), Math.PI / 2);
        this.coneMesh.bakeCurrentTransformIntoVertices();
        mat.emissiveColor = Color3.Black();
        mat.disableLighting = true;
        this.coneMesh.material = mat;
        this.coneMesh.renderingGroupId = 1;
        this.coneMesh.setEnabled(false);
    },

    /**
     * Draws a 3D line from given pointA to pointB
     * Uses BABYLON Meshes as the lines needs a real world position not like GUI
     * @param {BABYLON.TransformNode} pointA
     * @param {BABYLON.TransformNode} pointB
     * @param {BABYLON.Scene} scene
     */
    createLine(pointA, pointB, scene) {
        // Line part
        const length = Vector3.Distance(pointA, pointB);
        const line =
            length < 0.1
                ? new LinesMesh()
                : MeshBuilder.CreateDashedLines(
                      'line',
                      {
                          points: [pointA, pointB],
                          updatable: true,
                          size: 12,
                          dashNb: length * 10,
                          useVertexAlpha: false,
                      },
                      scene,
                  );
        line.color = Color3.Black();
        line.renderingGroupId = 1;

        // Cones part
        if (!this.coneMesh) {
            this.initConeMesh(scene);
        }
        const direction = pointB.subtract(pointA);

        const cone1 = this.coneMesh.clone('cone1');
        cone1.position = pointB.clone();
        cone1.lookAt(pointA);
        cone1.position = cone1.position.add(direction.clone().normalize().scale(-0.025));

        const cone2 = this.coneMesh.clone('cone2');
        cone2.position = pointA.clone();
        cone2.lookAt(pointB);
        cone2.position = cone2.position.add(direction.clone().normalize().scale(0.025));
        return {
            points: [pointA, pointB],
            cones: [cone1, cone2],
            lineMesh: line,
        };
    },

    checkCollisionBetweenNewPointsAndExistingPoints(measurementPointMesh) {
        // As the points all have the same width we only check their position.
        // Only true if the points are in the same position (or nearly)
        return this.measurementPoints.some((pointToCheck) => {
            pointToCheck.computeWorldMatrix(true);
            measurementPointMesh.computeWorldMatrix(true);
            const ptcAbsolutePosition = pointToCheck.getAbsolutePosition();
            const mpAbsolutePosition = measurementPointMesh.getAbsolutePosition();
            return ['x', 'y', 'z'].every(
                (coordinate) =>
                    ptcAbsolutePosition[coordinate] < mpAbsolutePosition[coordinate] + 0.001 &&
                    ptcAbsolutePosition[coordinate] > mpAbsolutePosition[coordinate] - 0.001,
            );
        });
    },

    /**
     * Create three differents spheres that has the three measurement object materials
     */
    initMeasurementSphere() {
        this.basicMeasurementPointMesh = CreateSphere(
            'measurementPointBasic',
            { diameter: 0.07, segments: 6 },
            _context.modules.obsidianEngine.controller.scene,
        );
        this.basicMeasurementPointMesh.material = _context.modules.materialManager.loadedMaterials['basic-green-material'];

        this.hoveredMeasurementPointMesh = CreateSphere(
            'measurementPointHovered',
            { diameter: 0.07, segments: 6 },
            _context.modules.obsidianEngine.controller.scene,
        );
        this.hoveredMeasurementPointMesh.material = _context.modules.materialManager.loadedMaterials['hover-green-material'];

        this.activeMeasurementPointMesh = CreateSphere(
            'measurementPointActive',
            { diameter: 0.07, segments: 6 },
            _context.modules.obsidianEngine.controller.scene,
        );
        this.activeMeasurementPointMesh.material = _context.modules.materialManager.loadedMaterials['active-green-material'];

        this.hoveredMeasurementPointMesh.setEnabled(false);
        this.basicMeasurementPointMesh.setEnabled(false);
        this.activeMeasurementPointMesh.setEnabled(false);
    },

    /**
     * Set sphereMesh its parent and it's world position
     * @param {BABYLON.Mesh} sphere the sphere to place
     * @param {BABYLON.Mesh} parent the parent of the point
     * @param {BABYLON.TransformNode} point the point that has the coordinate informations
     */
    setMeasurementSphereParent(sphere, parent, point) {
        sphere.parent = parent;
        sphere.position = point.position;
        sphere.rotationQuaternion = point.rotationQuaternion;
        if (!sphere.metadata) {
            sphere.metadata = {};
        }
        sphere.computeWorldMatrix(true);
    },

    /**
     * Create an instance of sphere of given sphereType
     * @param {String} name the name of the new sphere
     * @param {MEASUREMENT_SPHERE_TYPE} sphereType the type of sphere we want to create
     */
    getNewSphere(name, sphereType) {
        let sphere;
        if (sphereType === MEASUREMENT_SPHERE_TYPE.Hovered) {
            sphere = this.hoveredMeasurementPointMesh.createInstance(name);
        } else if (sphereType === MEASUREMENT_SPHERE_TYPE.Active) {
            sphere = this.activeMeasurementPointMesh.createInstance(name);
        } else {
            sphere = this.basicMeasurementPointMesh.createInstance(name);
        }
        sphere.setEnabled(true);
        return sphere;
    },

    /**
     * Creates a new sphere that replace a given sphere by taking its coordinate but change
     * the material
     * @param {BABYLON.Mesh} sphereToReplace the sphere to replace
     * @param {MEASUREMENT_SPHERE_TYPE} sphereType the new type of sphere
     */
    replaceSphere(sphereToReplace, sphereType) {
        const newSphere = this.getNewSphere(sphereToReplace.name, sphereType);
        newSphere.parent = sphereToReplace.parent;
        newSphere.position = sphereToReplace.position;
        newSphere.rotationQuaternion = sphereToReplace.rotationQuaternion;
        newSphere.computeWorldMatrix(true);
        if (sphereToReplace.metadata && sphereToReplace.metadata.measurementPoint) {
            const { measurementPoint } = sphereToReplace.metadata;
            Object.assign(newSphere, {
                metadata: { measurementPoint },
            });
            measurementPoint.pointMesh = newSphere;
        }
        sphereToReplace.dispose();
        return newSphere;
    },

    /**
     * remove given point from measurmentPoints list
     * @param {BABYLON.Mesh} measurementPointMesh
     */
    removePoint(measurementPointMesh) {
        const index = this.measurementPoints.findIndex((pointToCheck) => pointToCheck.name === measurementPointMesh.name);
        this.measurementPoints.splice(index, 1);
    },

    /**
     * Create a point gizmo
     * @param {scene} scene scene where the gizmo will be added
     * @param {material} material the point material
     * @param {number} diameter the point diameter
     * @returns the point gizmo
     */
    createMeasurePointGizmo(scene, material, diameter = 0.05) {
        const newGizmo = CreateSphere('measurePointGizmo', { diameter, segments: 6 }, scene);
        newGizmo.material = material;
        return newGizmo;
    },
};

export default MeasurementHelper;
