import MeshManager from './mesh-manager';
import MeshUtility from './utility/mesh-utility';

const MODULE_NAME = 'mesh-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['material-manager'],
    load(app) {
        window[APP_SYMBOL] = app;
        MeshUtility.setContext(app);
        return {
            meshController: new MeshManager(app),
            MeshUtility,
        };
    },
    unload() {},
};
