import overlay from './components/overlay/overlay.vue';
import obsidianCanvas from './components/obsidian-canvas.vue';
import topMenu from './components/top-menu.vue';
import bottomMenu from './components/bottom-menu/bottom-menu.vue';
import pdfBottomMenu from './components/pdf-mode/bottom-menu.vue';
import rightMenu from './components/right-menu.vue';
import leftMenu from './components/left-menu.vue';
import pdfEditorLeftMenu from './components/pdf-mode/left-menu.vue';
import environmentSelector from './components/environment-selector.vue';
import textPopup from './components/overlay/text-popup.vue';
import ColorPickerIro from './components/color-picker-iro.vue';
import EventBus from './eventBus';
import { APP_SYMBOL } from '..';
import RightsHelper from '../../../helpers/rights-helper';
import config from 'defaultConfig';
export default {
    name: 'App',
    components: {
        overlay: overlay,
        topMenu: topMenu,
        bottomMenu: bottomMenu,
        rightMenu: rightMenu,
        leftMenu: leftMenu,
        obsidianCanvas: obsidianCanvas,
        environmentSelector: environmentSelector,
        textPopup: textPopup,
        pdfEditorLeftMenu: pdfEditorLeftMenu,
        pdfBottomMenu: pdfBottomMenu,
        ColorPickerIro: ColorPickerIro,
    },
    data: function () {
        return {
            isCanvasReady: false,
            isWebGL2Supported: true,
            isExpanded: false,
            gridData: null,
            cameraData: null,
            catalogData: {
                catalog: [],
                templates: [],
                groupedObjects: [],
            },
            layersData: null,
            gizmoData: null,
            measurementData: null,
            config: config,
            // We must set this data to make the UI update when we change properties
            groupSelectionData: null,
            selectionData: null,
            unsaved: true,
            unloadLocked: RightsHelper.isModePublic(),
            isEnvSelectorOpen: false,
            screenshotEnvironment: null,
            buildingPlanData: null,
        };
    },
    computed: {
        canDisplayLeftMenu: function () {
            return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayPdfLeftMenu: function () {
            return RightsHelper.isModeBuildingPlan() && RightsHelper.isPlanEssential();
        },
        canDisplayBottomMenu: function () {
            return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayPdfBottomMenu: function () {
            return RightsHelper.isModeBuildingPlan() && RightsHelper.isPlanEssential();
        },
    },
    mounted: function () {
        var _this = this;
        EventBus.$on('toggle-groups', function (open) {
            _this.isExpanded = !open;
        });
        if (window[APP_SYMBOL].modules.initUtility.isConfigurationLoaded) {
            this.canvasReady();
        }
        else {
            window[APP_SYMBOL].events.on('@init-utility.configuration-loaded', function () {
                _this.canvasReady();
            });
        }
        window[APP_SYMBOL].events.on('lockUnload', function () {
            _this.unloadLocked = true; // Avoid to open the onUnload popup when we don't want
            // confirmation
        });
        window.addEventListener('beforeunload', function (e) {
            var confirmationMessage = "".concat(_this._('Are you sure to leave the application ? All the unsaved changes will be lost.'));
            if (_this.unsaved && !_this.unloadLocked) {
                (e || window.event).returnValue = confirmationMessage; // Gecko + IE
                return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
            }
            return null;
        });
        window[APP_SYMBOL].events.on('@catalog-manager.catalog-initialized', function () {
            _this.catalogData = window[APP_SYMBOL].modules.catalogManager.vueData;
        });
        EventBus.$on('open-environment-selector', function () {
            _this.isEnvSelectorOpen = true;
        });
        EventBus.$on('close-environment-selector', function () {
            _this.isEnvSelectorOpen = false;
        });
    },
    methods: {
        canvasReady: function () {
            window[APP_SYMBOL].events.emit('canvas-ready');
            this.gridData = window[APP_SYMBOL].modules.gridManager.vueData;
            this.layersData = window[APP_SYMBOL].modules.groupManager.Controller.vueData;
            // We must set this data to make the UI update when we change properties
            this.cameraData = window[APP_SYMBOL].modules.cameraManager.controller.vueData;
            this.groupSelectionData = window[APP_SYMBOL].modules.groupManager.GroupSelection.vueData;
            this.selectionData = window[APP_SYMBOL].modules.selectionManager.vueData;
            this.optionsData = window[APP_SYMBOL].modules.optionManager.optionController.vueData;
            this.screenshotEnvironment = window[APP_SYMBOL].modules.cameraManager.screenshotController.vueData;
            this.gizmoData = window[APP_SYMBOL].modules.gizmoManager.controller.vueData;
            this.materialData = window[APP_SYMBOL].modules.materialManager.vueData;
            this.measurementData = window[APP_SYMBOL].modules.measurementManager.controller.vueData;
            this.isCanvasReady = true;
            // Uncomment when a webgl2 feature is absolutely necessary
            // this.isWebGL2Supported = Boolean(
            //     engineController.engine.webGLVersion >= 2
            // );
            //
            // Remove if a webgl2 feature is absolutely necessary
            this.isWebGL2Supported = true;
            this.buildingPlanData = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController.vueData;
        },
        updateFloorName: function (name) {
            this.screenshotEnvironment.floorName = name;
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
