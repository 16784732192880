import SceneController from './src/scene-controller';

const MODULE_NAME = 'scene-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['obsidian-engine', 'collision-manager', 'highlight-manager'],
    load(app) {
        window[APP_SYMBOL] = app;
        return new SceneController(app);
    },
    unload() {},
};
