import RalColors from '../utility/ral-colors';
import { APP_SYMBOL } from '..';
import classUtils from 'vendors/class-utils';
import Class from 'abitbol';

/**
 * Mixin for entities able to have their color changed
 * Allow to change between ral and eco
 */
const ColorableMixin = Class.$extend({
    isColorable: true,

    /**
     * Init the colorable mixin
     * @param {Boolean} ralOnly does the object support eco too
     */
    initColor(ralOnly = true) {
        this.ralOnly = ralOnly;

        if (!this.color) {
            if (this.ralOnly) {
                this.color = RalColors.getDefaultColor();
            } else {
                this.color = null; // Forece default to null to prevent history discrepancies
            }
        }
    },

    setRalMaterial() {
        if (this.color) {
            const { materialManager } = window[APP_SYMBOL].modules;
            this.materialId = materialManager.createRalMaterial(this.__name__, this.color);
        }
    },

    setEcoMaterial() {
        this.materialId = window[APP_SYMBOL].modules.materialManager.defaultMaterial;
        this.color = null;
    },

    /**
     * Change the entity's color
     * @param  {String} newColor color hex string
     */
    changeColor(newColor) {
        this.color = newColor;
    },

    getColor: classUtils.getter,
    setColor(color) {
        this.$data.color = color;
        this.setRalMaterial();
    },
});

export default ColorableMixin;
