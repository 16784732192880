import CollisionController from './src/controller/collision-controller';
import CollisionHelper from './src/helpers/collision-helper';
import { CollisionBoundingSphereDebug } from './src/helpers/collision-debug';

const MODULE_NAME = 'collision-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['data-store', 'highlight-manager', 'mesh-manager', 'obsidian-engine'],
    load(app) {
        window[APP_SYMBOL] = app;
        CollisionHelper.setContext(app);
        CollisionBoundingSphereDebug.setContext(app);
        return {
            Controller: new CollisionController(app),
            CollisionHelper,
        };
    },
    unload() {},
};
