/** Used to manage object on the scene and ONLY object
 * for cameras or light checkout their respective manager.
 */

import ConsoleHelper from '../../../helpers/console-helper';
import { MeshBuilder, Mesh, ImageProcessingConfiguration, Color3, Vector3 } from '@babylonjs/core';

export default class SceneManager {
    /** The manager only manage a scene instance by adding, removing or
     * interacting with object in it, the scene must be initialised in the engine manager first.
     */
    constructor(context) {
        this.context = context;
        this.ground = null;
        this.groundWidth = 10;
        this.groundHeight = 12;
        this.ready = false;
        this.scene = null;

        this.context.events.on('@obsidian-engine.engine-ready', () => {
            this.initScene();
        });

        ConsoleHelper.expose('BABYLON', {
            Vector3,
        });
    }

    initScene() {
        this.scene = this.context.modules.obsidianEngine.controller.scene;
        ConsoleHelper.expose('scene', this.scene);
        this.scene.clearColor = new Color3(1, 1, 1);

        this.scene.imageProcessingConfiguration.toneMappingEnabled = true;
        this.scene.imageProcessingConfiguration.toneMappingType = ImageProcessingConfiguration.TONEMAPPING_ACES; // eslint-disable-line

        this.scene.ambientColor = new Color3(1, 1, 1);

        this.addGround();
    }

    addGround() {
        if (this.ground) {
            this.context.log.warn("can't add a ground, there's already one. Are you trying to add a plane ?");
            return;
        }
        this.ground = MeshBuilder.CreateGround(
            'main-ground',
            {
                width: this.groundWidth,
                height: this.groundHeight,
                updatable: true,
            },
            this.scene,
        );
        this.ready = true;
        this.context.modules.collisionManager.Controller.setGround(this.ground);
        this.context.events.emit('ground-ready');
    }

    updateGround(data) {
        this.ground.dispose();
        const newGround = MeshBuilder.CreateGround(
            'main-ground',
            {
                height: data.height,
                width: data.width,
                updatable: true,
            },
            this.scene,
        );
        this.ground = newGround;
    }

    addObjectToScene(object) {
        this.scene.addMesh(object);
    }

    createObjectFromGeometry(geometry, name) {
        const mesh = new Mesh(name, this.scene);
        geometry.applyToMesh(mesh);
        return mesh;
    }

    getScene() {
        return this.scene;
    }

    getSceneObjects() {
        return this.scene.meshes;
    }
}
