import EntityHelper from '../helpers/entity-helper';
import { Quaternion, Vector3 } from '@babylonjs/core';

// Serializers for babylon object
import '../model/serializers/babylon-vector3';
import '../model/serializers/babylon-quaternion';

class EntityController {
    constructor(context) {
        this.context = context;
        this.initEvents();
    }

    initEvents() {
        this.context.events.on('@data-store.entity-added', (entity) => {
            this.onEntityAdded(entity);
        });
        this.context.events.on('@data-store.entity-removed', (entity) => {
            if (EntityHelper.PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
                entity.destroy();
            }
        });
        this.context.events.on('@project-manager.project-loaded', () => {
            this.freezeAll();
        });
        this.context.events.on('@project-manager.connectors-generated', () => {
            this.freezeAll(false);
        });
        this.context.events.on('@history.history-go', () => {
            this.onHistoryGo();
        });
    }

    /**
     * Instanciate the mesh of the added entity if this one is a
     * "meshable" entity (frame, connector, parts ...)
     * @param {*} entity
     */
    onEntityAdded(entity) {
        if (EntityHelper.PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
            this.context.modules.catalogManager
                .tryRegisterMeshGeometry(entity.ref)
                .then((meshInfos) => {
                    if (meshInfos) {
                        entity.isAvailable = true;
                        this.prepareEntityMesh(entity);
                    }
                })
                .catch((error) => {
                    entity.isAvailable = false;
                    this.context.log.error('Error while instancing mesh ', entity.ref, ': ', error);
                });
        }

        if (entity.isEntity) {
            this.handleDynamicRalFinishing(entity);
        }
    }

    /**
     * Called when we go back or go forward in the history
     * Handle if the some serialized value in entity changed but not in its mesh
     */
    onHistoryGo() {
        const products = this.context.modules.dataStore.listEntities('/products/default');
        products.forEach((product) => {
            if (!product.mesh) {
                return;
            }
            if (product.visible === product.mesh.isVisible) {
                return;
            }
            // Update the entity's mesh visibility after history go
            this.context.modules.geometryUtility.toggleMeshVisibility(product.mesh, product.visible);
            this.context.events.emit('history-change-visibility', product.mesh);
        });

        this.freezeAll();
    }

    /**
     * Called when we instanciate the mesh of a given entity
     * @param {*} entity
     */
    prepareEntityMesh(entity) {
        if (entity.mesh) {
            return;
        }
        // QUICKFIX !!!!! This must be changed it's only a temporary fix for retrocompatibility
        if (entity.materialId === 'ano') {
            if (entity.__name__ === 'frame' || entity.__name__ === 'door') {
                entity.setMaterialId('frames-ano');
            } else if (entity.__name__ === 'connector') {
                entity.setMaterialId('connectors-ano');
            }
        }
        // ENDOF QUICKFIX !!!

        entity.mesh = this.context.modules.meshManager.meshController.getMeshFromLoadedGeometry(entity.ref, entity.materialId, null, {
            category: entity.getCategory(),
            subCategory: entity.getSubCategory(),
            ref: entity.ref,
        });
        const meshInfos = this.context.modules.meshManager.meshController.loadedMeshes[entity.ref];
        entity.meshInfos = meshInfos;
        if (!entity.initializing) {
            const position = new Vector3(entity.position.x, entity.position.y, entity.position.z);
            const rotationQuaternion = new Quaternion(
                entity.rotationQuaternion.x,
                entity.rotationQuaternion.y,
                entity.rotationQuaternion.z,
                entity.rotationQuaternion.w,
            );
            entity.mesh.position = position;
            entity.mesh.rotationQuaternion = rotationQuaternion;
        } else {
            entity.setRotationQuaternion(Quaternion.Identity());
            entity.handleInitialRotation();
            entity.setPosition(new Vector3(0, entity.getBoundingBox().extendSizeWorld.y, 0));
            // if bbox minimumWorld.y is not near from 0 , it means that the mesh is not on the ground
            if (entity.getBoundingBox().minimumWorld.y > 0.0001 || entity.getBoundingBox().minimumWorld.y < -0.0001) {
                entity.setPosition(new Vector3(0, entity.getBoundingBox().extendSizeWorld.y - entity.getBoundingBox().minimumWorld.y, 0));
            }

            entity.mesh.computeWorldMatrix(true);
            entity.initializing = false;
        }
        const boundingInfo = entity.getBoundingBox();
        entity.height = Math.abs(boundingInfo.maximumWorld.y - boundingInfo.minimumWorld.y);
        entity.width = Math.abs(boundingInfo.maximumWorld.x - boundingInfo.minimumWorld.x);
        entity.depth = Math.abs(boundingInfo.maximumWorld.z - boundingInfo.minimumWorld.z);
        entity.mesh.entity = entity;
        entity.mesh.getChildMeshes().forEach((child) => {
            child.entity = entity;
        });
        entity.mesh.computeWorldMatrix(true);
        entity.mesh.checkCollisions = true;
        this.context.modules.geometryUtility.toggleMeshVisibility(entity.mesh, entity.visible);
        this.context.events.emit('entity-mesh-ready', entity);
    }

    /**
     * Modify the passed entity material depending on the product ral finish value
     * @param {*} entity
     */
    handleDynamicRalFinishing(entity) {
        const currentProduct = this.context.modules.catalogManager.products[entity.ref];
        if (currentProduct) {
            const notRalFinishes = Object.keys(currentProduct).filter(
                (finishingKey) => finishingKey.includes('finishing') && !finishingKey.includes('Ral'),
            );
            const ralOnly = notRalFinishes.every((finishingKey) => !currentProduct[finishingKey]) && currentProduct.finishingRal;
            entity.isColorable = currentProduct.finishingRal;

            if (entity.isColorable) {
                entity.initColor(ralOnly);
            }

            // Not colorable anymore
            if (entity.color && !entity.isColorable) {
                entity.setEcoMaterial();
            }
        }
    }

    /**
     * freeze world matrixes of all entities and connectors
     * @param {Boolean} freezeEntities=true
     * @param {Boolean} freezeConnectors=true
     */
    freezeAll(freezeEntities = true, freezeConnectors = true) {
        if (freezeEntities) {
            const entities = this.context.modules.dataStore.listEntities('/products/default');
            entities.forEach((e) => {
                e.freeze();
            });
        }
        if (freezeConnectors) {
            const connectors = this.context.modules.dataStore.listEntities('/connectors/default');
            connectors.forEach((c) => {
                c.freeze();
            });
        }
    }
}
export default EntityController;
