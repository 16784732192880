import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';
import MeasurableMixin from '../../../measurement-manager/src/models/measurable-mixin';

const LedskinStructure = EntityStructure.$extend({
    __name__: 'ledskin',

    __init__(params) {
        this.$super(params);
        this.initMeasurement();
    },

    __include__: [MeasurableMixin.prototype],
});
Entity.$register(LedskinStructure);
export default LedskinStructure;
