import SwapManager from './src/controller/swap-manager';

const MODULE_NAME = 'swap-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'collision-manager',
        'geometry-utility',
        'project-manager',
        'group-manager',
        'selection-manager',
        'mesh-manager',
        'history',
        'entity-manager',
        'option-manager',
        'gui-manager',
        'gizmo-manager',
        'obsidian-engine',
        'catalog-manager',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        return new SwapManager(app);
    },
    unload() {},
};
