import { Color3, MeshBuilder, StandardMaterial } from '@babylonjs/core';

let debugSpheres = [];

let _context = null;
const CollisionBoundingSphereDebug = {
    setContext(context) {
        _context = context;
    },

    reset() {
        debugSpheres.forEach((sphere) => {
            sphere.parent = null;
            sphere.dispose();
        });
        debugSpheres = [];
    },

    addDebugSphere(mesh) {
        const { scene } = _context.modules.obsidianEngine.controller;
        const sphere = MeshBuilder.CreateSphere('sphere', { diameter: mesh.boundingSphere.radiusWorld * 2 }, scene);
        sphere.material = new StandardMaterial('sphereMat', scene);
        sphere.material.diffuseColor = new Color3(0.3, 0.3, 1);
        sphere.material.specularColor = new Color3(0, 0, 0);
        sphere.material.alpha = 0.3;
        sphere.position = mesh.boundingSphere.centerWorld;
        debugSpheres.push(sphere);
        mesh.debugSphere = sphere;
    },

    updateToCollideColor(meshes) {
        meshes.forEach((mesh) => {
            mesh.debugSphere.material.diffuseColor = new Color3(1, 0, 0);
        });
    },
};

export { CollisionBoundingSphereDebug };
