import EventBus from '../eventBus';
import RightsHelper from '../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../..';
import { Compact } from 'vue-color';
var defaultProps = {
    hex: '#A5A5A5',
};
export default {
    components: {
        'compact-picker': Compact,
    },
    props: {
        palette: {
            type: Array,
            default: function () { return []; },
        },
        colorableEntities: {
            type: Array,
            default: function () { return []; },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            colors: defaultProps,
            isExpanded: false,
            isPublic: RightsHelper.isModePublic(),
            isRalMode: this.colorableEntities.every(function (colorableEntity) { return colorableEntity.color; }),
            ralOnly: this.colorableEntities.some(function (colorableEntity) { return colorableEntity.ralOnly; }),
        };
    },
    mounted: function () {
        var _this = this;
        EventBus.$on('toggle-groups', function (open) {
            _this.isExpanded = !open;
        });
    },
    methods: {
        updateColor: function (color) {
            this.colorableEntities.forEach(function (colorableEntity) { return colorableEntity.changeColor(color.hex); });
            this.isRalMode = true;
            window[APP_SYMBOL].modules.history.snapshot(); // Update history at the end (for global undo)
        },
        setEcoMaterial: function () {
            this.colorableEntities.forEach(function (colorableEntity) { return colorableEntity.setEcoMaterial(); });
            this.isRalMode = false;
            window[APP_SYMBOL].modules.history.snapshot();
        },
    },
};
