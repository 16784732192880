import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const BeTrussB30Structure = EntityStructure.$extend({
    __name__: 'betruss-b30',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(BeTrussB30Structure);
export default BeTrussB30Structure;
