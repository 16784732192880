import CameraController, { CameraViews } from './src/controller/camera-controller';
import ScreenshotController from './src/controller/screenshot-controller';
import ScreenshotHelper from './src/helpers/screenshot-helper';

const MODULE_NAME = 'camera-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['obsidian-engine', 'iframe-api', 'material-manager', 'grid-manager'],
    load(app) {
        window[APP_SYMBOL] = app;
        const cameraController = new CameraController(app);
        ScreenshotHelper.setContext(app);
        return {
            controller: cameraController,
            CameraViews,
            screenshotController: new ScreenshotController(cameraController, app),
            ScreenshotHelper,
        };
    },
    unload() {},
};
