import ObsidianApi from 'obsidian-api';

let api = null;

const MODULE_NAME = 'iframe-api';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [],

    load(app) {
        if (api) {
            app.log.warn('Iframe API should be loaded only once!');
            return api;
        }

        window[APP_SYMBOL] = app;

        api = new ObsidianApi();

        if (app.isStarted) {
            app.log.warn('Iframe API may not be loaded after the application started!');
            setTimeout(api.ready, 1); // deferred
        } else {
            app.events.on('@obsidian.ready', api.ready);
        }

        return api;
    },

    unload() {
        // pass
    },
};
