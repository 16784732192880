import DragSizeViewer from '../../../gui-manager/src/gui/drag-size-viewer';
import CollisionHelper from '../../../collision-manager/src/helpers/collision-helper';
import GizmoHelper from '../helpers/gizmo-helper';
import { PLANES, NORMALS, COLORS, Plane, addHoverMaterial } from '../helpers/gizmo-meshes-helper';
import { PositionGizmo, Vector3, AbstractMesh, TransformNode, Mesh, Matrix, PointerDragBehavior } from '@babylonjs/core';

/**
 * Extends Babylon base Gizmo
 * Ready to be modified to fit beMatrix demands
 */

export default class PlaneGizmo extends PositionGizmo {
    constructor(highlightManager, scene, context) {
        super();
        this.context = context;
        this.highlightManager = highlightManager;
        this.attachedEntity = null;
        this.highlightBehavior = null;

        this.guiManager = this.context.modules.guiManager;
        this.history = this.context.modules.history;

        this.dragSizeViewer = new DragSizeViewer(this.guiManager.GuiController, scene, this.context);

        this.planes = [];

        Object.values(NORMALS).forEach((value, index) => {
            this.planes.push(new Plane(value, COLORS[index]));
        });

        // Translation and rotation to move the gizmo
        this.planes[PLANES.YZ].mesh.rotate(Vector3.Up(), -Math.PI / 2);
        this.planes[PLANES.XZ].mesh.rotate(Vector3.Right(), Math.PI / 2);
        this.planes.forEach((plane) => {
            plane.mesh.translate(Vector3.Up(), 0.025);
            plane.mesh.translate(Vector3.Right(), 0.025);
        });

        // Remove the old behavior
        [this.xGizmo, this.yGizmo, this.zGizmo].forEach((gizmo, index) => {
            gizmo._rootMesh.removeBehavior(gizmo._rootMesh.behaviors[0]);

            gizmo.setCustomMesh(this.planes[index].mesh);

            // Create a new behavior for each axis
            gizmo.dragBehavior = new PointerDragBehavior({
                dragPlaneNormal: NORMALS[index],
            });
            gizmo.dragBehavior.moveAttached = false;
            PlaneGizmo.addVirtualGridMovementBehavior(gizmo);

            gizmo.dragBehavior.onDragStartObservable.add(() => {
                if (gizmo.attachedMesh && gizmo.attachedMesh instanceof Mesh) {
                    gizmo.attachedMesh.oldPosition = gizmo.attachedMesh.position.clone();
                } else if (gizmo.attachedMesh && gizmo.attachedMesh instanceof TransformNode) {
                    gizmo.attachedMesh.getChildMeshes(true).forEach((mesh) => {
                        mesh.oldPosition = mesh.getAbsolutePivotPoint();
                    });
                }
            });

            gizmo.dragBehavior.onDragObservable.add(() => {
                if (gizmo.attachedMesh && gizmo.attachedMesh instanceof Mesh) {
                    CollisionHelper.isUnderGrid(gizmo.attachedMesh.entity, { isEntity: true, respond: true });
                }
            });

            gizmo.dragBehavior.onDragEndObservable.add(() => {
                if (gizmo.attachedMesh && gizmo.attachedMesh instanceof TransformNode && !(gizmo.attachedMesh instanceof AbstractMesh)) {
                    GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                    GizmoHelper.updateGizmoAttachedMeshChildren(gizmo);
                } else {
                    this.notificateEntity();
                }
                this.history.snapshot();
                if (this.highlightBehavior) {
                    this.highlightBehavior.startHighlightBehavior();
                }
            });

            this.bindGuiBehavior(gizmo, index);

            addHoverMaterial(gizmo, COLORS[index], this.planes[index].material);

            // Add the new behavior
            gizmo._rootMesh.addBehavior(gizmo.dragBehavior);
            gizmo.dragBehavior.attach(gizmo._rootMesh);
        });
    }

    notificateEntity() {
        if (this.attachedEntity) {
            this.attachedEntity.updatePosition();
        }
    }

    /**
     * Add the behavior that makes the mesh move on the virual grid
     * @param {*} gizmo
     */
    static addVirtualGridMovementBehavior(gizmo) {
        const tmpVector = new Vector3();
        const localDelta = new Vector3();
        const tmpMatrix = new Matrix();
        let currentSnapDragDistanceX = 0;
        let currentSnapDragDistanceY = 0;
        let currentSnapDragDistanceZ = 0;
        gizmo.dragBehavior.onDragObservable.add((event) => {
            if (gizmo.attachedMesh) {
                // Convert delta to local translation if it has a parent
                if (gizmo.attachedMesh.parent) {
                    gizmo.attachedMesh.parent.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(event.delta, tmpMatrix, localDelta);
                } else {
                    gizmo.attachedMesh.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(event.delta, tmpMatrix, localDelta);
                }
                // Snapping logic
                if (gizmo.snapDistance === 0) {
                    gizmo.attachedMesh.position.addInPlace(localDelta);
                } else {
                    const tmpSnapEvent = { snapDistance: 0 };

                    // Code is almost the same for the three axis
                    currentSnapDragDistanceX += localDelta.x;
                    if (Math.abs(currentSnapDragDistanceX) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(Math.abs(currentSnapDragDistanceX) / gizmo.snapDistance);
                        const xAxisDelta = gizmo.attachedMesh.right.scale(Math.sign(currentSnapDragDistanceX));
                        currentSnapDragDistanceX %= gizmo.snapDistance;
                        xAxisDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                    }

                    currentSnapDragDistanceY += localDelta.y;
                    if (Math.abs(currentSnapDragDistanceY) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(Math.abs(currentSnapDragDistanceY) / gizmo.snapDistance);
                        const yAxisDelta = gizmo.attachedMesh.up.scale(Math.sign(currentSnapDragDistanceY));
                        currentSnapDragDistanceY %= gizmo.snapDistance;
                        yAxisDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                    }

                    currentSnapDragDistanceZ += localDelta.z;
                    if (Math.abs(currentSnapDragDistanceZ) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(Math.abs(currentSnapDragDistanceZ) / gizmo.snapDistance);
                        const zAxisDelta = gizmo.attachedMesh.forward.scale(Math.sign(currentSnapDragDistanceZ));
                        currentSnapDragDistanceZ %= gizmo.snapDistance;
                        zAxisDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                    }

                    if (tmpSnapEvent.snapDistance) {
                        gizmo.onSnapObservable.notifyObservers(tmpSnapEvent);
                    }
                }
                gizmo.attachedMesh.computeWorldMatrix(true);
                gizmo.attachedMesh.getChildMeshes(true).forEach((mesh) => {
                    mesh.computeWorldMatrix(true);
                });
                if (gizmo.attachedMesh && gizmo.attachedMesh instanceof TransformNode) {
                    GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                }
            }
        });
    }

    set attachedMesh(mesh) {
        super.attachedMesh = mesh;
        GizmoHelper.attachMeshToMovableGizmo(this, mesh);
    }

    /** Bind functions on dragStart, drag and dragEnd in order to display lines and distances
     * of the translation on each axis and display it on labels
     * @param {Gizmo} gizmo
     * @param {Number} planeIndex a constant associated with a gizmo, a translation plane, etc
     * see PLANES in gizmo-meshes-helper.js
     */
    bindGuiBehavior(gizmo, planeIndex) {
        // On start initialize gui, link mesh, toggle gui elements visibility and store
        // selectedMesh position in metadata
        gizmo.dragBehavior.onDragStartObservable.add(() => {
            this.dragSizeViewer.setPointFromMesh(this._meshAttached, true);
            this.dragSizeViewer.togglePlaneModeVisibility(true);
        });

        // On drag update end point position
        gizmo.dragBehavior.onDragObservable.add(() => {
            let axis = this._meshAttached.up;
            if (planeIndex === 1) {
                axis = this._meshAttached.forward;
            } else if (planeIndex === 2) {
                axis = this._meshAttached.right;
            }
            this.dragSizeViewer.setPointFromMeshInAxis(this._meshAttached, axis);
        });

        // On end drag toggle gui elements visibility
        gizmo.dragBehavior.onDragEndObservable.add(() => {
            this.dragSizeViewer.togglePlaneModeVisibility(false);
        });
    }
}
