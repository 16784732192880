import group from './group/group.vue';
import EventBus from '../eventBus';
import RightsHelper from '../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../..';
export default {
    name: 'RightMenu',
    components: { group: group },
    props: {
        groups: {
            type: Array,
            default: function () { return []; },
        },
        engineData: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            isOpen: true,
            showOptions: true,
            updatedGroups: this.groups,
            multiSelectedMesh: this.engineData.multiSelectedMesh,
        };
    },
    computed: {
        canStoreProducts: function () {
            return RightsHelper.isMode3dAuthenticated() && this.isMultiSelectedProducts;
        },
        canGroupProducts: function () {
            return !RightsHelper.isModeBuildingPlan() && !RightsHelper.isModePublic();
        },
        // Enable to know wich action to set on the buttons (group/ungroup)
        groupActionStatus: function () {
            return this._(window[APP_SYMBOL].modules.groupManager.GroupSelection.getGroupActionStatus());
        },
        isMultiSelectedProducts: function () {
            return this.multiSelectedMesh.length > 0;
        },
        toggleButtonText: function () {
            if (this.isOpen) {
                return this._('Hide');
            }
            return this._('Show');
        },
    },
    mounted: function () {
        var _this = this;
        // CLOSE RIGHT MEMU EVENT
        ['@project-manager.loading-project'].forEach(function (event) {
            window[APP_SYMBOL].events.on(event, function () {
                _this.isOpen = false;
            });
        });
        // OPEN RIGHT MEMU EVENT
        ['@project-manager.project-loaded'].forEach(function (event) {
            window[APP_SYMBOL].events.on(event, function () {
                _this.isOpen = true;
            });
        });
        window[APP_SYMBOL].modules.groupManager.Controller.updateVueData();
    },
    methods: {
        /**
         * Show/hide the group UI
         */
        toggleGroups: function () {
            this.isOpen = !this.isOpen;
            // We expand/reduce the top menu when opening right menu
            EventBus.$emit('toggle-groups', this.isOpen);
        },
        toggleOptions: function () {
            this.showOptions = !this.showOptions;
        },
        // When we click on collapse all group
        collapseGroups: function () {
            EventBus.$emit('collapseGroups');
        },
        storeProducts: function () {
            var popinDescription = this._('Please insert a name for your product:');
            var popinWarning = this._('Warning : Please give a valid product name' +
                ', if you write any special characters like (!,_-^%)' +
                ' or give an empty version name,the submission will be blocked.');
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Save a group product'),
                description: "".concat(popinDescription, "\n                    <br/><input type=\"text\" id=\"product-name\"required pattern= \"^([a-zA-Z0-9.]|\\s)+$\"><br/>\n                    <span class=\"warning-info\">\n                        ").concat(popinWarning, "\n                    </span>"),
                onValidate: function () {
                    var productName = document.getElementById('product-name').value;
                    window[APP_SYMBOL].modules.iframeApi.sendEvent('store-product', productName);
                },
            });
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
