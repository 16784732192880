import RequestController from './src/controllers/request-controller';

const MODULE_NAME = 'request-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['iframe-api'],
    load(app) {
        window[APP_SYMBOL] = app;
        return {
            controller: new RequestController(app),
        };
    },
    unload() {},
};
