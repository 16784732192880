import CollisionHelper from '../../../collision-manager/src/helpers/collision-helper';
import { Sprite, Vector3 } from '@babylonjs/core';

export default class DuplicateControl extends Sprite {
    constructor({ name, manager, entity, direction, scene, context }) {
        super(name, manager);
        this.context = context;
        this.collisionController = this.context.modules.collisionManager.Controller;

        this.attachedEntity = entity;
        // Direction is a vector 3 (1,0,0), (0,1,0), (-1,0,0) ...
        this.direction = direction;
        const offset = 0.02; // Offset from the object
        this.computePosition(offset);

        this.scaleRatio = 0.05;
        this.scene = scene;
        this.computeScale();

        this.duplicationAllowed().then((isAllowed) => {
            if (isAllowed) {
                this.isPickable = true;
                this.isVisible = true;
            } else {
                this.isPickable = false;
                this.isVisible = false;
            }
            this.animateCallback = () => {
                this.computeScale();
            };
            this.context.events.on('@obsidian-engine.animate', this.animateCallback);
        });
    }

    /**
     * Compute the sprite scale by checking the distance between the camera and its target
     */
    computeScale() {
        const distance = this.scene.activeCamera.getTarget().subtract(this.scene.activeCamera.position.clone());
        this.size = distance.length() * this.scaleRatio;
    }

    /**
     * Compute the sprite position by scaling the direction of the sprite with an offset
     * @param {Vector3} offset - the offset of the sprite
     */
    computePosition(offset) {
        const boundingBox = this.attachedEntity.getBoundingBox();
        const size = boundingBox.maximum.subtract(boundingBox.minimum);
        const localPosition = new Vector3(
            this.direction.x * (size.x + offset),
            this.direction.y * (size.y + offset),
            this.direction.z * (size.z + offset),
        );
        this.attachedEntity.mesh.computeWorldMatrix(true);
        let worldCentered = this.attachedEntity.mesh.getWorldMatrix();
        worldCentered = worldCentered.setTranslation(boundingBox.centerWorld.clone());
        const worldPosition = Vector3.TransformCoordinates(localPosition, worldCentered);
        this.position = worldPosition;
    }

    /**
     * Callback called when the sprite is clicked
     */
    async onSpriteClicked() {
        const newStructure = await this.duplicate();
        this.context.events.emit('duplicated', newStructure.mesh);
        this.context.modules.history.snapshot();
    }

    /**
     * Check if the mesh generated when clicking on the sprite will respect the collision rules
     * Will also return false if the generated mesh will be too close to mesh
     * @return {Boolean}
     */
    duplicationAllowed() {
        /* TODO: Uncomment this if the client validates the "smart" buttons
        const boundingBox = this.attachedEntity.getBoundingBox();
        const size = boundingBox.maximum.subtract(boundingBox.minimum);
        if (new Vector3(
            this.direction.x * size.x,
            this.direction.y * size.y,
            this.direction.z * size.z
        ).length() < 0.2) {
            return Promise.resolve(false);
        } */

        return this.duplicate(false).then((testEnt) => {
            testEnt.mesh.isVisible = false;
            testEnt.mesh.getChildMeshes().forEach((mesh) => {
                mesh.isVisible = false;
            });

            // To not check collisions with itself
            testEnt.mesh.checkCollisions = false;
            const notColliding = !this.collisionController.checkStaticCollisionsEntity(testEnt);
            const isUnderGrid = CollisionHelper.isUnderGrid(testEnt, {
                isEntity: true,
                epsilonGrid: -0.01, // Baseplates are a ltl bit into the ground
            });
            testEnt.destroy();
            return !isUnderGrid && notColliding;
        });
    }

    /**
     * Clone the entity (and its mesh) attached to the sprite in the right position
     * @param {Boolean} store - If the duplicated object need to be stored or not in the datastore
     * @return {bematrix.EntityStructure}
     */
    duplicate(store = true) {
        const boundingBox = this.attachedEntity.getBoundingBox();
        const size = boundingBox.maximum.subtract(boundingBox.minimum);
        const localPos = new Vector3(this.direction.x * size.x, this.direction.y * size.y, this.direction.z * size.z);
        const newPos = Vector3.TransformCoordinates(localPos, this.attachedEntity.mesh.getWorldMatrix());
        const newEntity = this.attachedEntity.clone({ position: newPos });

        const meshPromise = new Promise((resolve) => {
            // We must wait that the mesh is fetched before returning structure
            // Resolve promise once the entity-mesh-ready event is emitted
            const onEntityMeshReady = (entityFetched) => {
                if (entityFetched.id === newEntity.id) {
                    this.context.events.removeListener('@entity-manager.entity-mesh-ready', onEntityMeshReady);
                    resolve();
                }
            };
            this.context.events.on('@entity-manager.entity-mesh-ready', onEntityMeshReady);
            if (store) {
                const path = this.context.modules.entityManager.Helper.getEntityPath(newEntity);
                this.context.modules.dataStore.addEntity(newEntity, path);
            } else {
                this.context.modules.entityManager.controller.prepareEntityMesh(newEntity);
            }
        });
        return meshPromise.then(() => {
            newEntity.rotationQuaternion = this.attachedEntity.mesh.rotationQuaternion.clone();
            if (this.attachedEntity.updateRectsMatrixes) {
                this.attachedEntity.updateRectsMatrixes();
            }
            return newEntity;
        });
    }

    removeEvents() {
        this.context.events.removeListener('@obsidian-engine.animate', this.animateCallback);
    }
}
