import ProjectController from './src/controllers/project-controller';
import BecadHelper from './src/helper/becad-helper';
import ConfigHelper from './src/helper/config-helper';
import patchHelper from './src/helper/patch-helper';
import QuoteHelper from './src/helper/quote-helper';

const MODULE_NAME = 'project-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'data-store',
        'catalog-manager',
        'iframe-api',
        'request-manager',
        'data-exporter',
        'group-manager',
        'grid-manager',
        'scene-manager',
        'camera-manager',
        'option-manager',
        'entity-manager',
        'obsidian-engine',
        'material-manager',
        'selection-manager',
        'mesh-manager',
        'assets-manager',
        'history',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        ConfigHelper.setContext(app);
        BecadHelper.setContext(app);
        patchHelper.setContext(app);
        QuoteHelper.setContext(app);
        return {
            controller: new ProjectController(app),
        };
    },
    unload() {},
};
