let _context = null;
export default class DisplayGroupHelper {
    static setContext(context) {
        _context = context;
    }

    static setProductVisibility(product, visibility, fromGroup = false) {
        if (!product.mesh) {
            return;
        }
        product.visible = visibility;

        _context.modules.geometryUtility.toggleMeshVisibility(product.mesh, visibility, false);

        if (product.isSelected) {
            _context.modules.geometryUtility.toggleMeshVisibility(product.isSelected, visibility, false);
        }

        _context.modules.optionManager.optionController.toggleOptionsVisibility(visibility, {
            entity: product,
            optionFlag: _context.modules.optionManager.optionController.optionsFamilies.ALL,
        });
        if (!fromGroup) {
            _context.modules.history.snapshot();
        }
    }

    static setGroupVisibility(group, visibility) {
        group.productList.forEach((product) => {
            DisplayGroupHelper.setProductVisibility(product, visibility, true);
        });
        _context.modules.history.snapshot();
    }

    static isGroupVisible(group) {
        // Only for default group
        if (group.productList.length === 0) {
            return true;
        }
        return group.productList.some((product) => product.visible);
    }
}
