import EventBus from '../eventBus';
import eventBus from '../../../../commons/EventBus';
import RightsHelper from '../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../..';
export default {
    name: 'Tools',
    data: function () {
        return {
            coversMenuOpened: false,
            snappingActivated: false,
            normalsModeActivated: false,
            hasSelection: false,
        };
    },
    computed: {
        canEnterSnappingMode: function () {
            return true;
        },
        canGenerateCoverPlacement: function () {
            return true;
        },
        canCalculateConnectors: function () {
            return RightsHelper.isRoleDesigner() && RightsHelper.isPlanEssential() && RightsHelper.isMode3dAuthenticated();
        },
        canGeneratePanelPlan: function () {
            return RightsHelper.isRoleDesigner() && RightsHelper.isPlanEssential() && RightsHelper.isMode3dAuthenticated();
        },
        canCreateBuildingPlan: function () {
            return RightsHelper.isRoleDesigner() && RightsHelper.isPlanEssential() && RightsHelper.isMode3dAuthenticated();
        },
        canRequestQuote: function () {
            return RightsHelper.isRoleFinance() && RightsHelper.isMode3dAuthenticated();
        },
        snappingClass: function () {
            return {
                'rounded-button': true,
                'quote-button': this.snappingActivated,
                'mx-0': true,
                'mx-2': true,
            };
        },
        snappingNormalsClass: function () {
            return {
                'rounded-button': true,
                'quote-button': this.normalsModeActivated,
                'mx-0': true,
                'mx-2': true,
            };
        },
    },
    mounted: function () {
        var _this = this;
        eventBus.on('activating-tool', function (toolName) {
            if (toolName !== 'snapping') {
                _this.setSnappingMode(false);
            }
        });
        window[APP_SYMBOL].events.on('@obsidian-engine.cancel', function () {
            _this.setSnappingMode(false);
        });
        window[APP_SYMBOL].events.on('@obsidian-engine.snapping-mode', function () {
            if (!_this.snappingActivated) {
                _this.setSnappingMode(true);
            }
            else {
                _this.toggleNormalsMode();
            }
        });
        window[APP_SYMBOL].events.on('@group-manager.selectionChanged', function () {
            _this.onEntitySelected();
        });
        window[APP_SYMBOL].events.on('@selection-manager.select-entity-scene', function () {
            _this.onEntitySelected();
        });
        window[APP_SYMBOL].events.on('@selection-manager.unselect-entity-scene', function () {
            _this.onEntitySelected();
        });
    },
    methods: {
        onEntitySelected: function () {
            var selectionManager = window[APP_SYMBOL].modules.selectionManager;
            this.hasSelection = selectionManager.hasSelection();
        },
        openCoversMenu: function () {
            if (!this.hasSelection) {
                return;
            }
            this.coversMenuOpened = true;
        },
        closeCoversMenu: function () {
            this.coversMenuOpened = false;
        },
        requestQuote: function () {
            window[APP_SYMBOL].events.emit('lockUnload');
            window[APP_SYMBOL].events.emit('checkVersion', 'quotation-request');
        },
        pdfMode: function () {
            var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
            buildingPlanController.togglePdfMode();
            // Avoid UI shift if we go in pdf mode while catalog is open
            EventBus.$emit('hide-products');
            var selectionManager = window[APP_SYMBOL].modules.selectionManager;
            selectionManager.unselectAll();
        },
        toggleSnappingMode: function () {
            this.setSnappingMode(!this.snappingActivated);
        },
        setSnappingMode: function (isActivated) {
            this.snappingActivated = isActivated;
            var snappingManager = window[APP_SYMBOL].modules.snappingManager;
            snappingManager.setSnappingActivated(this.snappingActivated);
        },
        toggleNormalsMode: function () {
            this.setNormalsMode(!this.normalsModeActivated);
        },
        setNormalsMode: function (isActivated) {
            this.normalsModeActivated = isActivated;
            var snappingManager = window[APP_SYMBOL].modules.snappingManager;
            snappingManager.setNormalsMode(this.normalsModeActivated);
        },
        calculateConnectors: function () {
            var projectController = window[APP_SYMBOL].modules.projectManager.controller;
            projectController.requestConnectorGeneration();
        },
        generatePanelPlan: function () {
            window[APP_SYMBOL].events.emit('checkVersion', 'generate-panelplan');
        },
        generateCovers: function (coverType) {
            if (coverType === void 0) { coverType = 0; }
            var projectController = window[APP_SYMBOL].modules.projectManager.controller;
            projectController.requestCoverGeneration(coverType);
            this.closeCoversMenu();
        },
        print: function () {
            window.print();
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
