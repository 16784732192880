import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const CornerStructure = EntityStructure.$extend({
    __name__: 'corner',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(CornerStructure);
export default CornerStructure;
