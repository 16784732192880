import MaterialManager from './src/controllers/material-manager';

const MODULE_NAME = 'material-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['request-manager', 'init-utility'],
    load(app) {
        window[APP_SYMBOL] = app;
        return new MaterialManager(app);
    },
    unload() {},
};
