import { APP_SYMBOL } from '../../..';
export default {
    name: 'BuildingPlanTable',
    data: function () {
        return {
            catalog: window[APP_SYMBOL].modules.catalogManager.products,
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
        };
    },
    computed: {
        getPageProductsQuantities: function () {
            return this.bpController.getPage().table.productsQuantities;
        },
    },
};
