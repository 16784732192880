import AppVue from './app.vue';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import '../../../style/bematrix.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on';

export default class uiManager {
    constructor(context) {
        this.context = context;
        Vue.config.productionTip = false;
        Vue.config.warnHandler = (msg, vm, trace) => {
            // `trace` is the component hierarchy trace
            if (!msg.includes(ignoreWarnMessage)) {
                // We avoid to display as an error some warnings
                this.context.log.warn('[Vue warn ] :', msg, trace);
            }
        };
        Vue.use(BootstrapVue);
        Vue.directive('click-outside', {
            bind(el, binding, vnode) {
                el.clickOutsideEvent = (event) => {
                    // here I check that click was outside the el and his children
                    if (!(el === event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent);
            },
            unbind(el) {
                document.body.removeEventListener('click', el.clickOutsideEvent);
            },
        });

        this.context.events.on('@obsidian.ready', () => {
            this.createMainVue();
        });
    }

    createMainVue() {
        this.appVue = new Vue({
            el: '#app',
            components: { AppVue },
            template: '<appVue></appVue>',
        });
    }
}
