import EventBus from '../../eventBus';
import { TEXT_SIZES } from '../../../../building-plan-manager/src/controller/building-plan-controller';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import { IMAGE_MODES } from '../../../../building-plan-manager/src/controller/building-plan-image-controller';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'PdfBottomMenu',
    props: {
        buildingPlanData: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            isExpanded: false,
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
            bpImageController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanImageController,
            selectionController: window[APP_SYMBOL].modules.selectionManager,
            textSizePanelOpen: false,
            TEXT_SIZES: TEXT_SIZES,
        };
    },
    computed: {
        hasPage: function () {
            return Boolean(this.bpController.getPage());
        },
        isScenePage: function () {
            return this.bpController.getPage().type === PAGE_TYPES.SCENE;
        },
        is2DPage: function () {
            return this.bpController.getPage().type === PAGE_TYPES.HTML;
        },
        canEditScenePage: function () {
            var page = this.bpController.getPage();
            return this.buildingPlanData.isCameraFixed && page.editing;
        },
        canAddAnnotations: function () {
            var page = this.bpController.getPage();
            return page.table.exists && this.selectionController.hasSelection();
        },
        canRemoveAnnotations: function () {
            return this.selectionController.hasSelection();
        },
        mayAddAnnotations: function () {
            var bpHelper = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanHelper;
            if (this.selectionController.hasSelection()) {
                var page = this.bpController.getPage();
                var id = bpHelper.selectionAnnotationId();
                return !page.annotations[id];
            }
            return false;
        },
        canEdit2DPage: function () {
            var page = this.bpController.getPage();
            return page.editing;
        },
        isTextFocused: function () {
            var page = this.bpController.getPage();
            return Boolean(Object.values(page.texts).find(function (textObject) { return textObject.isFocused; }));
        },
        isImageFocused: function () {
            var page = this.bpController.getPage();
            return Boolean(Object.values(page.userImages).find(function (userImage) { return userImage.isFocused; }));
        },
        canEditAnyPage: function () {
            if (this.isScenePage) {
                return this.canEditScenePage;
            }
            if (this.is2DPage) {
                return this.canEdit2DPage;
            }
            return false;
        },
        isMeasureModeActivated: function () {
            var measurementController = window[APP_SYMBOL].modules.measurementManager.controller;
            return measurementController.measureModeActivated;
        },
    },
    mounted: function () {
        var _this = this;
        EventBus.$on('toggle-groups', function (open) {
            _this.isExpanded = !open;
        });
    },
    methods: {
        addSceneImage: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to add an image on is immutable');
                return;
            }
            this.bpImageController.captureImage(IMAGE_MODES.SCENE);
        },
        redoScreenshotSceneImage: function () {
            this.bpController.startPageEditing();
            this.selectionController.unselectAll();
            this.bpController.clearPage();
        },
        addTable: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to add a table on is immutable');
                return;
            }
            this.bpController.addTable();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        removeTable: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to remove the table from is immutable');
                return;
            }
            this.bpController.removeTable();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        addIndexedAnnotations: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to add indexed annotations on is immutable');
                return;
            }
            this.bpController.addIndexedAnnotations();
        },
        removeIndexedAnnotations: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to remove indexed annotations from is immutable');
                return;
            }
            this.bpController.removeIndexedAnnotations();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        addText: function (textSize) {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to add text on is immutable');
                return;
            }
            this.bpController.addText(textSize);
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        removeText: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to remove text from is immutable');
                return;
            }
            this.bpController.removeText();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        addImage: function (event) {
            var _this = this;
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to add an image on is immutable');
                return;
            }
            var file = event.target.files[0];
            var fileReader = new FileReader();
            fileReader.addEventListener('loadend', function (progressEvent) {
                if (progressEvent.returnValue) {
                    _this.bpController.addImage(fileReader.result);
                    _this.bpImageController.captureImage(IMAGE_MODES.HTML);
                }
                else {
                    window[APP_SYMBOL].log.error('The image cannot be laoded');
                }
            });
            fileReader.readAsDataURL(file);
        },
        removeImage: function () {
            if (!this.bpController.isPageEditable()) {
                window[APP_SYMBOL].log.error('The page you are trying to remove an image from is immutable');
                return;
            }
            this.bpController.removeImage();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        openFileManager: function () {
            this.$refs.imageInput.click();
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
