let _context = null;
const SelectionHelper = {
    setContext(context) {
        _context = context;
    },
    get GroupActionEnum() {
        if (!this._GroupActionEnumComputed) {
            this._GroupActionEnumComputed = {
                GROUP: {
                    id: 0,
                    label: _context.modules.stonejs.gettext('Group'),
                    icon: 'group',
                },
                UNGROUP: {
                    id: 1,
                    label: _context.modules.stonejs.gettext('Ungroup'),
                    icon: 'ungroup',
                },
                ADD: {
                    id: 2,
                    label: _context.modules.stonejs.gettext('Add to group'),
                    icon: 'forward',
                },
                LEAVE: {
                    id: 3,
                    label: _context.modules.stonejs.gettext('Leave group'),
                    icon: 'backward',
                },
                FUSION: {
                    id: 4,
                    label: _context.modules.stonejs.gettext('Fusion'),
                    icon: 'fusion',
                },
                NONE: {
                    id: 5,
                    label: _context.modules.stonejs.gettext('No action available'),
                    icon: '',
                },
            };
        }
        return this._GroupActionEnumComputed;
    },
};

export default SelectionHelper;
