import { APP_SYMBOL } from '../..';
export default {
    name: 'Camera',
    props: {
        cameraData: {
            type: Object,
            default: function () { return ({
                orthographic: false,
            }); },
        },
    },
    data: function () {
        return {
            isOpen: false,
            cameraName: 'Free camera',
            debugCamera: false,
        };
    },
    methods: {
        toggleDropdown: function () {
            this.isOpen = !this.isOpen;
        },
        // Functions to change the camera view
        emitDefaultView: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.changeView(window[APP_SYMBOL].modules.cameraManager.CameraViews.DEFAULT_VIEW);
        },
        emitTopView: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.changeView(window[APP_SYMBOL].modules.cameraManager.CameraViews.TOP_VIEW);
        },
        emitFrontView: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.changeView(window[APP_SYMBOL].modules.cameraManager.CameraViews.FRONT_VIEW);
        },
        emitSideView: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.changeView(window[APP_SYMBOL].modules.cameraManager.CameraViews.SIDE_VIEW);
        },
        /**
         * Makes the camera orbiting
         */
        orbit: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.updateOrbiting();
        },
        changeMode: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.changeMode();
        },
        changeCam: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.changeCamera();
            this.cameraName = this._(window[APP_SYMBOL].modules.cameraManager.controller.getCameraName());
        },
        // DEBUG FUNCTIONS
        debugCurrentCamera: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.debugCurrentCamera();
        },
        setDebugView: function () {
            window[APP_SYMBOL].modules.cameraManager.controller.setDebugView();
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
