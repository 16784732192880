import { APP_SYMBOL } from '../../..';
import { IMAGE_MODES } from '../../../../building-plan-manager/src/controller/building-plan-image-controller';
import { Vector2 } from '@babylonjs/core';
export default {
    props: {
        textObject: {
            type: Object,
            default: null,
        },
        sizeInfos: {
            type: Object,
            default: function () { return ({
                x: 0,
                y: 0,
            }); },
        },
        arrowObject: {
            type: Object,
            default: null,
        },
    },
    data: function () {
        return {
            canvas: null,
            canvasContext: null,
            canvasScaleRatio: 4,
            context: null,
            textDataMutable: this.textObject,
            arrowObjectMutable: this.arrowObject,
            isSelected: false,
        };
    },
    watch: {
        'textDataMutable.position.x': function textXWatcher() {
            this.updateTextPosition();
        },
        'textDataMutable.position.y': function textYWatcher() {
            this.updateTextPosition();
        },
        'textDataMutable.text': function textContentWatcher() {
            this.updateTextPosition();
        },
    },
    mounted: function () {
        this.canvas = this.$refs.textArrowCanvas;
        // Set canvas size in CSS
        this.canvas.style.width = this.canvas.parentElement.offsetWidth;
        this.canvas.style.height = this.canvas.parentElement.offsetHeight;
        this.canvas.width = this.canvas.parentElement.offsetWidth * this.canvasScaleRatio;
        this.canvas.height = this.canvas.parentElement.offsetHeight * this.canvasScaleRatio;
        this.context2d = this.canvas.getContext('2d');
        this.context2d.scale(this.canvasScaleRatio, this.canvasScaleRatio);
        this.container = document.getElementById('canvas-container');
        if (this.textObject.settingArrow) {
            // Restore annotation position
            var positionX = this.textObject.position.x;
            var positionY = this.textObject.position.y;
            this.drawLine(positionX, positionY);
            this.addListeners();
        }
        else {
            this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y, true);
        }
        this.addPermanentListener();
    },
    beforeUnmount: function () {
        this.isSelected = false;
        this.removePermanentListeners();
    },
    methods: {
        computeStartingPosition: function (endPositionX, endPositionY) {
            // Line
            var center = {
                x: this.textDataMutable.position.x + this.sizeInfos.x,
                y: this.textDataMutable.position.y + this.sizeInfos.y,
            };
            var textBoxLimit = {
                maxX: center.x + this.sizeInfos.x,
                minX: center.x - this.sizeInfos.x,
                maxY: center.y + this.sizeInfos.y,
                minY: center.y - this.sizeInfos.y,
            };
            var startingPos = {
                x: 0,
                y: 0,
            };
            if (endPositionY > textBoxLimit.maxY) {
                if (endPositionX > textBoxLimit.maxX) {
                    startingPos.x = textBoxLimit.maxX;
                    startingPos.y = center.y;
                }
                else if (endPositionX < textBoxLimit.minX) {
                    startingPos.x = textBoxLimit.minX;
                    startingPos.y = center.y;
                }
                else {
                    startingPos.x = center.x;
                    startingPos.y = textBoxLimit.maxY;
                }
            }
            else if (endPositionY < textBoxLimit.minY) {
                if (endPositionX > textBoxLimit.maxX) {
                    startingPos.x = textBoxLimit.maxX;
                    startingPos.y = center.y;
                }
                else if (endPositionX < textBoxLimit.minX) {
                    startingPos.x = textBoxLimit.minX;
                    startingPos.y = center.y;
                }
                else {
                    startingPos.x = center.x;
                    startingPos.y = textBoxLimit.minY;
                }
            }
            else {
                startingPos.y = center.y;
                if (endPositionX > textBoxLimit.maxX) {
                    startingPos.x = textBoxLimit.maxX;
                }
                else if (endPositionX < textBoxLimit.minX) {
                    startingPos.x = textBoxLimit.minX;
                }
            }
            return startingPos;
        },
        drawLine: function (endPositionX, endPositionY, savedArrow, thickened) {
            if (savedArrow === void 0) { savedArrow = false; }
            if (thickened === void 0) { thickened = false; }
            this.context2d.clearRect(0, 0, this.canvas.width, this.canvas.height);
            if (thickened) {
                this.context2d.lineWidth = 2.5;
            }
            this.context2d.beginPath();
            var startingPos = this.computeStartingPosition(endPositionX, endPositionY);
            if (!savedArrow) {
                this.arrowObjectMutable.start.x = startingPos.x;
                this.arrowObjectMutable.start.y = startingPos.y;
            }
            else {
                startingPos.x = this.arrowObjectMutable.start.x;
                startingPos.y = this.arrowObjectMutable.start.y;
            }
            if (startingPos.x === 0 || startingPos.y === 0) {
                this.context2d.closePath();
                return;
            }
            this.context2d.moveTo(startingPos.x, startingPos.y);
            this.context2d.lineTo(endPositionX, endPositionY);
            this.context2d.stroke();
            this.context2d.closePath();
            // Tip point
            this.context2d.beginPath();
            this.context2d.arc(endPositionX, endPositionY, 2.5, 0, 2 * Math.PI);
            this.context2d.fill();
            if (thickened) {
                this.context2d.lineWidth = 1;
            }
            this.context2d.closePath();
        },
        updateTextPosition: function () {
            this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y);
        },
        onMoveLocal: function (event) {
            var positionX = event.target.offsetLeft + event.offsetX;
            var positionY = event.target.offsetTop + event.offsetY;
            this.drawLine(positionX, positionY);
        },
        onClickLocal: function (event) {
            if (this.textDataMutable.settingArrow) {
                var positionX = event.target.offsetLeft + event.offsetX;
                var positionY = event.target.offsetTop + event.offsetY;
                this.arrowObjectMutable.end.x = positionX;
                this.arrowObjectMutable.end.y = positionY;
                this.textDataMutable.settingArrow = false;
                this.removeListeners();
            }
        },
        checkIfIsOnArrow: function (event) {
            var positionX = event.target.offsetLeft + event.offsetX;
            var positionY = event.target.offsetTop + event.offsetY;
            var epsilon = 1500; // pixel coordinates are big
            var pointToCheck = new Vector2(positionX, positionY);
            var AB = this.arrowObjectMutable.end.subtract(this.arrowObjectMutable.start);
            var AC = pointToCheck.subtract(this.arrowObjectMutable.start);
            // Check if are aligned
            var crossA = (this.arrowObjectMutable.end.x - this.arrowObjectMutable.start.x) * (pointToCheck.y - this.arrowObjectMutable.start.y);
            var crossB = (pointToCheck.x - this.arrowObjectMutable.start.x) * (this.arrowObjectMutable.end.y - this.arrowObjectMutable.start.y);
            var cross = crossA - crossB;
            if (cross < -epsilon || cross > epsilon) {
                return false;
            }
            var dotAC = Vector2.Dot(AB, AC);
            var dotAB = Vector2.Dot(AB, AB);
            return dotAB > dotAC && dotAC > 0;
        },
        cursorCheck: function (event) {
            if (this.textDataMutable.settingArrow) {
                return;
            }
            if (this.checkIfIsOnArrow(event)) {
                this.container.style.cursor = 'pointer';
                this.arrowObjectMutable.hovered = true;
            }
            else {
                this.arrowObjectMutable.hovered = false;
                if (this.textDataMutable.arrows.every(function (arrow) { return !arrow.hovered; })) {
                    this.container.style.cursor = 'default';
                }
            }
        },
        checkArrowClick: function (event) {
            if (this.textDataMutable.settingArrow) {
                return;
            }
            if (this.checkIfIsOnArrow(event)) {
                this.isSelected = true;
                this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y, false, true);
            }
            else if (this.isSelected) {
                this.isSelected = false;
                this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y);
            }
        },
        deleteArrow: function () {
            var _this = this;
            if (this.isSelected) {
                var index = this.textObject.arrows.findIndex(function (arrow) { return arrow.id === _this.arrowObjectMutable.id; });
                this.textObject.arrows.splice(index, 1);
                var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
                buildingPlanController.imageController.captureImage(IMAGE_MODES.HTML);
            }
        },
        addPermanentListener: function () {
            this.container.addEventListener('pointermove', this.cursorCheck);
            this.container.addEventListener('pointerdown', this.checkArrowClick);
            window[APP_SYMBOL].events.on('@obsidian-engine.building-plan-delete', this.deleteArrow);
        },
        addListeners: function () {
            this.container.addEventListener('pointermove', this.onMoveLocal);
            this.container.addEventListener('pointerdown', this.onClickLocal);
        },
        removeListeners: function () {
            this.container.removeEventListener('pointermove', this.onMoveLocal);
            this.container.removeEventListener('pointerdown', this.onClickLocal);
        },
        removePermanentListeners: function () {
            this.container.removeEventListener('pointermove', this.cursorCheck);
            this.container.removeEventListener('pointerdown', this.checkArrowClick);
            window[APP_SYMBOL].events.removeListener('@obsidian-engine.building-plan-delete', this.deleteArrow);
        },
    },
};
