import { APP_SYMBOL } from '../..';
import RightsHelper from '../../../../helpers/rights-helper';
export default {
    name: 'SceneInteractions',
    props: {
        optionsData: {
            type: Object,
            default: function () { return ({
                optionsFamilies: {},
                showInfills: true,
                infillsVisibilityLocked: false,
            }); },
        },
        materialData: {
            type: Object,
            default: function () { return ({
                areFramesTransparent: false,
            }); },
        },
    },
    data: function () {
        return {
            isOpen: false,
            localOptionsData: this.optionsData,
        };
    },
    computed: {
        canDisplayRemoveConnectorsButton: function () {
            return !RightsHelper.isModeDemo() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
    },
    methods: {
        toggleDropdown: function () {
            this.isOpen = !this.isOpen;
        },
        setInfillsVisibility: function (areInfillsVisible) {
            var optionController = window[APP_SYMBOL].modules.optionManager.optionController;
            optionController.setAllOptionsVisibility(areInfillsVisible, optionController.optionsFamilies.INFILL);
        },
        removeConnectors: function () {
            window[APP_SYMBOL].modules.projectManager.controller.clearPath(['/connectors/default']);
            window[APP_SYMBOL].modules.history.snapshot();
        },
        setFramesTransparency: function (areFramesTransparent) {
            var materialManager = window[APP_SYMBOL].modules.materialManager;
            materialManager.setFramesTransparency(areFramesTransparent);
            this.setInfillsVisibility(!areFramesTransparent);
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
