import GizmoManager, { GIZMO_ENUM } from './src/controllers/gizmo-manager';
import GizmoHelper from './src/helpers/gizmo-helper';

const MODULE_NAME = 'gizmo-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'collision-manager',
        'history',
        'highlight-manager',
        'grid-manager',
        'gui-manager',
        'obsidian-engine',
        'mesh-manager',
        'entity-manager',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        GizmoHelper.setContext(app);
        return {
            controller: new GizmoManager(app),
            gizmoStates: GIZMO_ENUM,
        };
    },
    unload() {},
};
