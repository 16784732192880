import EventBus from '../../eventBus';
import RightsHelper from '../../../../../helpers/rights-helper';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'ProductOption',
    props: {
        productSelected: {
            type: Boolean,
            default: function () { return true; },
        },
        optionKey: {
            type: String,
            default: function () { return ''; },
        },
        optionFamily: {
            type: Number,
            default: function () { return 0; },
        },
        product: {
            type: Object,
            default: function () { return ({}); },
        },
        optionsNumber: {
            type: Object,
            default: function () { return ({}); },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            optionSelected: false,
            optionController: null,
            optionsFamilies: null,
            optionsData: null,
        };
    },
    computed: {
        canRemoveOption: function () {
            return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayIcon: function () {
            return !RightsHelper.isModePublic();
        },
        hideInfill: function () {
            return this.family === this.optionsFamilies.INFILL && !this.optionsData.showInfills;
        },
        family: function () {
            return this.optionController.getOptionFamilyFromKey(this.optionKey);
        },
        isVisible: function () {
            return this.optionFamily && this.currentOptionNumber;
        },
        currentOptionNumber: function () {
            switch (this.optionKey) {
                case 'baseplateOption':
                    return 1;
                case 'groundplateOption':
                    return 1;
                case 'lightOption':
                    return this.optionsNumber.lightNumber;
                case 'shelfOption':
                    return this.optionsNumber.shelfNumber;
                case 'screenOption':
                    return this.optionsNumber.screenNumber;
                case 'infillOption':
                    return 1;
                default:
                    return 0;
            }
        },
        optionText: function () {
            var ref;
            var number;
            var family = null;
            switch (this.optionKey) {
                case 'baseplateOption':
                    ref = this.optionController.getOptionReference(this.optionsFamilies.BASEPLATE, this.product);
                    return "".concat(this._('Baseplate'), " : ").concat(ref);
                case 'groundplateOption':
                    ref = this.optionController.getOptionReference(this.optionsFamilies.GROUNDPLATE, this.product);
                    return "".concat(this._('Groundplate'), " : ").concat(ref);
                case 'lightOption':
                    ref = this.optionController.getOptionReference(this.optionsFamilies.LIGHT, this.product);
                    family =
                        this.optionFamily === window[APP_SYMBOL].modules.optionManager.OptionableMixin.LIGHT_SINGULARITY_OPTION.SIMPLE
                            ? 'SIMPLE'
                            : 'DOUBLE';
                    number = this.optionsNumber.lightNumber;
                    return "".concat(this._('Lights'), " : ").concat(ref, " - ").concat(family, " * ").concat(number);
                case 'shelfOption':
                    ref = this.optionController.getOptionReference(this.optionsFamilies.SHELF, this.product);
                    number = this.optionsNumber.shelfNumber;
                    return "".concat(this._('Shelves'), " : ").concat(ref, " * ").concat(number);
                case 'screenOption':
                    ref = this.optionController.getOptionReference(this.optionsFamilies.SCREEN, this.product);
                    number = this.optionsNumber.screenNumber;
                    return "".concat(this._('Screen brackets'), " : ").concat(ref, " * ").concat(number);
                case 'infillOption':
                    ref = this.optionController.getOptionReference(this.optionsFamilies.INFILL, this.product);
                    family =
                        this.optionFamily === window[APP_SYMBOL].modules.optionManager.OptionableMixin.INFILL_OPTION.ONE_FACE
                            ? 'ONE FACE'
                            : 'BOTH FACES';
                    return "".concat(this._('Infill'), " : ").concat(ref, " : ").concat(family);
                default:
                    return '';
            }
        },
    },
    watch: {
        productSelected: function (value) {
            this.optionSelected = value && this.optionSelected;
        },
    },
    created: function () {
        this.optionController = window[APP_SYMBOL].modules.optionManager.optionController;
        this.optionsFamilies = this.optionController.optionsFamilies;
        this.optionsData = this.optionController.vueData;
    },
    mounted: function () {
        var _this = this;
        EventBus.$on('option-menu-open', function (optionFamily) {
            if (_this.productSelected && _this.family === optionFamily) {
                _this.optionSelected = _this.productSelected;
            }
            else {
                _this.optionSelected = false;
            }
        });
        EventBus.$on('option-menu-close', function () {
            _this.optionSelected = false;
        });
    },
    methods: {
        /**
         * Open the option menu to edit the select option
         */
        editOption: function () {
            var measurementController = window[APP_SYMBOL].modules.measurementManager.controller;
            if (measurementController.measureModeActivated) {
                return;
            }
            var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
            if (!buildingPlanController.isCameraFixed && buildingPlanController.currentPage.type !== PAGE_TYPES.HTML) {
                var family_1 = this.optionController.getOptionFamilyFromKey(this.optionKey);
                if (!this.productSelected) {
                    window[APP_SYMBOL].modules.groupManager.GroupSelection.selectProduct(this.product);
                    // Currententity is not set when we call the event
                    setTimeout(function () {
                        window[APP_SYMBOL].events.emit('selectOption', family_1);
                    }, 100);
                }
                else {
                    window[APP_SYMBOL].events.emit('selectOption', family_1);
                }
                // Force the optionSelection before selectProduct does
                this.optionSelected = true;
            }
        },
        removeOption: function () {
            var measurementController = window[APP_SYMBOL].modules.measurementManager.controller;
            if (measurementController.measureModeActivated) {
                return;
            }
            var family = this.optionController.getOptionFamilyFromKey(this.optionKey);
            this.optionController.removeOption(family, this.product, true);
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
