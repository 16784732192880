import { APP_SYMBOL } from '../../..';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import draggable from 'vuedraggable';
export default {
    components: {
        draggable: draggable,
    },
    props: {
        buildingPlanData: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            PAGE_TYPES: null,
            measurementController: window[APP_SYMBOL].modules.measurementManager.controller,
            isAddPageOpen: false,
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
        };
    },
    computed: {
        isMeasureModeActivated: function () {
            return this.measurementController.measureModeActivated;
        },
    },
    created: function () {
        this.PAGE_TYPES = PAGE_TYPES;
    },
    methods: {
        selectPage: function (pageNumber) {
            if (this.measurementController.measureModeActivated) {
                this.measurementController.toggleMeasureMode();
            }
            this.bpController.setActivePage(pageNumber);
        },
        addScenePage: function () {
            this.bpController.addPage(this.PAGE_TYPES.SCENE);
            this.isAddPageOpen = false;
        },
        addHtmlPage: function () {
            this.bpController.addPage(this.PAGE_TYPES.HTML);
            this.isAddPageOpen = false;
        },
        removePage: function () {
            this.bpController.removePage();
        },
        swapPages: function () {
            this.bpController.swapPage();
            this.$forceUpdate();
        },
        exitPdfMode: function () {
            var _this = this;
            var onValidate = function () {
                _this.bpController.togglePdfMode();
            };
            var popinDescription1 = this._('You are about to quit pdf mode');
            var popinDescription2 = this._('Warning : By quitting the pdf mode you will lose all the pages informations you have setted!');
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Exit pdf mode'),
                description: "".concat(popinDescription1, "\n                    <br />\n                    <br />\n                    <span class=\"warning-info\">\n                        ").concat(popinDescription2, "\n                    </span>"),
                isRequired: false,
                onValidate: onValidate,
            });
        },
        useHoverAddIcon: function () {
            this.$refs['add-page-icon'].src = 'assets/icons/plus-hover.svg';
        },
        useNormalAddIcon: function () {
            this.$refs['add-page-icon'].src = 'assets/icons/plus.svg';
        },
        useClickScenePageIcon: function (e) {
            e.target.src = 'assets/icons/3d-page-click.svg';
        },
        useHoverScenePageIcon: function (e) {
            e.target.src = 'assets/icons/3d-page-hover.svg';
        },
        useNormalScenePageIcon: function (e) {
            e.target.src = 'assets/icons/3d-page.svg';
        },
        useClickHtmlPageIcon: function (e) {
            e.target.src = 'assets/icons/2d-page-click.svg';
        },
        useHoverHtmlPageIcon: function (e) {
            e.target.src = 'assets/icons/2d-page-hover.svg';
        },
        useNormalHtmlPageIcon: function (e) {
            e.target.src = 'assets/icons/2d-page.svg';
        },
        isPageSelected: function (testedPageNumber) {
            return this.buildingPlanData.selectedPageNumber === testedPageNumber;
        },
        isEditingPage: function (testedPageNumber) {
            return (this.bpController.isPageEditable(testedPageNumber) && this.bpController.imageController.pageHasImages(testedPageNumber));
        },
        onDragEnd: function (_a) {
            var newIndex = _a.newIndex, oldIndex = _a.oldIndex;
            this.bpController.updateMovedPage(newIndex, oldIndex);
            return true;
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
