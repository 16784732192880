import { APP_SYMBOL } from '../..';
import RightsHelper from '../../../../helpers/rights-helper';
export default {
    name: 'Actions',
    props: {
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    computed: {
        canImportExport: function () {
            return RightsHelper.isRoleAdminBematrix() && RightsHelper.isMode3dAuthenticated();
        },
        canSave: function () {
            return RightsHelper.isRoleDesigner() && RightsHelper.isMode3dAuthenticated();
        },
        canExportForge: function () {
            return (RightsHelper.hasForgeRole() || RightsHelper.isRoleAdminBematrix()) && RightsHelper.isMode3dAuthenticated();
        },
    },
    methods: {
        newVersion: function () {
            this.openVersionPopin('new-version');
        },
        /**
         * Shows a popin where the user give its project a version name
         */
        openVersionPopin: function (onValidateEvent, isRequired) {
            var _this = this;
            if (onValidateEvent === void 0) { onValidateEvent = 'save'; }
            if (isRequired === void 0) { isRequired = false; }
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('New version'),
                description: "".concat(this._('Please insert the version name'), "\n                    : <br />\n                        <input type=\"text\" id=\"version-name\" required pattern= \"^([a-zA-Z0-9.]|\\s)+$\">\n                    <br />\n                        <span class=\"warning-info\">\n                            ").concat(this._('Warning : Please give a valid version name' +
                    ', if you write any special characters like (!,_-^%)' +
                    ' or give an empty version name,the submission will be blocked.'), "\n                        </span>"),
                isRequired: isRequired,
                onValidate: function () {
                    window[APP_SYMBOL].events.emit('lockUnload');
                    _this.config.versionName = document.getElementById('version-name').value;
                    if (onValidateEvent === 'save') {
                        _this.$emit('onSaveProject');
                    }
                    window[APP_SYMBOL].modules.iframeApi.sendEvent(onValidateEvent);
                },
            });
        },
        /**
         * Saves the current project as a template
         */
        saveTemplate: function () {
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Save a template'),
                description: "".concat(this._('Please insert a name for your template:'), "<br /><input type=\"text\" id=\"template-name\" required pattern= \"^([a-zA-Z0-9.]|\\s)+$\">\n                <br/>\n                        <span class=\"warning-info\">\n                            ").concat(this._('Warning : Please give a valid template name' +
                    ', if you write any special characters like (!,_-^%)' +
                    ' or give an empty version name,the submission will be blocked.'), "\n                        </span>"),
                onValidate: function () {
                    var templateName = document.getElementById('template-name').value;
                    window[APP_SYMBOL].modules.iframeApi.sendEvent('save-template', templateName);
                },
            });
        },
        /**
         * Create a new project
         */
        newProject: function () {
            if (window.confirm(this._('Are you sure ? You will lose all unsaved progress.'))) {
                window[APP_SYMBOL].events.emit('lockUnload');
                window[APP_SYMBOL].modules.iframeApi.sendEvent('new-project');
            }
        },
        /**
         * Open the dialog for importing an xml
         */
        submitInputFile: function () {
            this.$refs.fileInput.click();
        },
        /**
         * Store the imported xml and send an event to the iframeApi
         */
        importXml: function (event) {
            var file = event.target.files[0];
            window[APP_SYMBOL].modules.projectManager.controller.importedXML = new Blob([file], {
                type: file.type,
            });
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Import an XML file'),
                description: this._('Warning, if you import you import this file you will lose all unsaved progress.'),
                isRequired: false,
                onValidate: function () {
                    window[APP_SYMBOL].modules.iframeApi.sendEvent('import-becad-xml');
                },
            });
        },
        exportXml: function () {
            var blob = window[APP_SYMBOL].modules.projectManager.controller.exportBecadXml(true, true);
            var projectName = this.config.projectName;
            window[APP_SYMBOL].modules.projectManager.controller.saveData(blob, "".concat(projectName, ".xml"));
        },
        openSettings: function () {
            var _this = this;
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Change settings'),
                description: "".concat(this._('Choose unit system'), " :<br /><select id=\"unit-name\"><option value=\"eu\" ").concat(this.config.unitMode === 'eu' ? 'selected="selected"' : '', ">").concat(this._('Metric'), "</option><option value=\"us\" ").concat(this.config.unitMode === 'us' ? 'selected="selected"' : '', ">").concat(this._('Imperial'), "</option></select>"),
                isRequired: false,
                onValidate: function () {
                    if (_this.config.unitMode !== document.getElementById('unit-name').value) {
                        _this.config.unitMode = document.getElementById('unit-name').value;
                        window[APP_SYMBOL].events.emit('modify-unit');
                    }
                },
            });
        },
        callForgeExport: function () {
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Export 3D file'),
                description: "".concat(this._('Choose file format to export'), " :<br />\n                <span>\n                    <input type='checkbox' id=\"stp-option\" name=\"stp-option\" class=\"file-checkbox\"/><label for=\"stp-option\">.stp</label>\n                </span>\n                <span>\n                    <input type='checkbox' id=\"dwg-option\" name=\"dwg-option\" class=\"file-checkbox\" checked/><label for=\"dwg-option\">.dwg</label>\n                </span>\n                <br />\n                Export type: <br />\n                <div>\n                    <input type='radio' id=\"export-type-simplified\" name=\"export-type\" class=\"file-checkbox mb-0\"/>\n                    <label for=\"export-type-simplified\">Structure (Simplified, small file)</label>\n                </div>\n                <div>\n                    <input type='radio' id=\"export-type-render\" name=\"export-type\" class=\"file-checkbox\" checked/>\n                    <label for=\"export-type-render\">Structure + panels(for render, small file)</label>\n                </div>\n                <span>\n                    <input type='checkbox' id=\"include-calculates-parts\" name=\"include-calculates-parts\" class=\"file-checkbox\"/>\n                    <label for=\"include-calculates-parts\">Include calculated parts</label>\n                </span>\n                "),
                isRequired: false,
                onValidate: function () {
                    var exportStp = document.getElementById('stp-option').checked;
                    var exportDwg = document.getElementById('dwg-option').checked;
                    var exportType = document.getElementById('export-type-simplified').checked ? 'SIMPLE' : 'SIMPLE_INFILLS';
                    if (document.getElementById('include-calculates-parts').checked) {
                        exportType += '_INCLUDE_AUTOMATION';
                    }
                    if (!exportStp && !exportDwg) {
                        return;
                    }
                    window[APP_SYMBOL].modules.iframeApi.sendEvent('calling-forge-export', {
                        exportStp: exportStp,
                        exportDwg: exportDwg,
                        exportType: exportType,
                    });
                },
            });
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
