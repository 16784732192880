import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const StructuralStructure = EntityStructure.$extend({
    __name__: 'structural',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(StructuralStructure);
export default StructuralStructure;
