import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';
import OptionableMixin from '../../../option-manager/model/optionable-mixin';
import cu from 'vendors/class-utils';

const MotionskinStructure = EntityStructure.$extend({
    __name__: 'motionskin',

    __init__(params) {
        this.$super(params);
        this.initOptions();
        this.$data.isMotionskin = true;
        this.$data.materialId = 'motionskin-material';
    },

    __include__: [OptionableMixin.prototype],

    getIsMotionskin: cu.getter,
});
Entity.$register(MotionskinStructure);
export default MotionskinStructure;
