import templates from './catalog/templates.vue';
import catalog from './catalog/catalog.vue';
import tools from './tools.vue';
import RightsHelper from '../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../..';
export default {
    name: 'LeftMenu',
    components: { templates: templates, catalog: catalog, tools: tools },
    props: {
        catalog: {
            type: Array,
            default: function () { return []; },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
        templates: {
            type: Array,
            default: function () { return []; },
        },
        groupedObjects: {
            type: Array,
            default: function () { return []; },
        },
    },
    data: function () {
        return {
            currentTab: 'catalog',
            isPublic: RightsHelper.isModePublic(),
        };
    },
    methods: {
        changeTab: function (tab) {
            this.currentTab = tab;
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
