import CollisionHelper from '../../collision-manager/src/helpers/collision-helper';
import { TransformNode, Vector3, CreateCylinder, CreateSphere } from '@babylonjs/core';

let _context = null;
export default class SnappingHelper {
    static setContext(context) {
        _context = context;
    }

    static checkNodeGridRepulsion(nodeMesh) {
        const meshesToCheck = nodeMesh.getChildMeshes(true).filter((mesh) => mesh.entity);
        const checkMeshBelowGrid = meshesToCheck.some((mesh) => CollisionHelper.isUnderGrid(mesh.entity, { isEntity: true }));
        if (checkMeshBelowGrid) {
            let repulsionY = 0;
            meshesToCheck.forEach((mesh) => {
                mesh.computeWorldMatrix(true);
                const bbs = CollisionHelper.getObjectBestBBs(mesh)[0].map((bb) => bb.y);
                repulsionY = Math.min(
                    bbs.reduce((a, b) => Math.min(a, b)),
                    repulsionY,
                );
            });
            nodeMesh.position.y -= repulsionY;
        }
    }

    static checkMeshGridRepulsion(mesh) {
        mesh.computeWorldMatrix(true);
        if (CollisionHelper.isUnderGrid(mesh.entity, { isEntity: true })) {
            const bbs = CollisionHelper.getObjectBestBBs(mesh)[0].map((bb) => bb.y);
            mesh.position.y -= bbs.reduce((a, b) => Math.min(a, b));
        }
    }

    /**
     * Creates a TransformNode used for multiselected object interaction
     */
    static createTransformGroup(activeMesh) {
        const { selectionManager } = _context.modules;
        const nodeMesh = new TransformNode('nodeMesh');
        nodeMesh.position = activeMesh.position.clone();
        nodeMesh.computeWorldMatrix(true);

        selectionManager.selectedMeshes.forEach((mesh) => {
            const originalPos = mesh.position.clone();
            mesh.parent = nodeMesh;
            mesh.computeWorldMatrix(true);
            mesh.setAbsolutePosition(originalPos);
        });
        return nodeMesh;
    }

    /**
     * Create an arrow gizmo
     * @param {scene} scene scene where the gizmo will be added
     * @param {material} material the arrow material
     * @param {number} length length of the arrow body
     * @param {number} tipLength length of the arrow tip
     * @param {number} width whith of the arrow
     * @returns the arrow gizmo
     */
    static createArrowGizmo(scene, material, length = 0.1, tipLength = 0.04, width = 0.01) {
        // Origin
        const newGizmo = new TransformNode('normal-gizmo-root', scene);
        newGizmo.isPickable = false;

        // Cylinder
        const cylinder = CreateCylinder(
            'normal-gizmo-cylinder',
            {
                height: length,
                diameter: width,
            },
            scene,
        );
        cylinder.parent = newGizmo;
        cylinder.material = material;
        cylinder.translate(Vector3.Up(), length / 2);
        cylinder.isPickable = false;

        // Arrow
        const arrow = CreateCylinder(
            'normal-gizmo-arrow-tip',
            {
                height: tipLength,
                diameterTop: 0,
                diameterBottom: width * 2,
            },
            scene,
        );
        arrow.parent = newGizmo;
        arrow.material = cylinder.material;
        arrow.translate(Vector3.Up(), length + tipLength / 2);
        arrow.isPickable = false;

        return newGizmo;
    }

    /**
     * Create a point gizmo
     * @param {scene} scene scene where the gizmo will be added
     * @param {material} material the point material
     * @param {number} diameter the point diameter
     * @returns the point gizmo
     */
    static createPointGizmo(scene, material, diameter = 0.05) {
        const newGizmo = CreateSphere('pointGizmo', { diameter, segments: 6 }, scene);
        newGizmo.material = material;
        return newGizmo;
    }

    /**
     * Destroys the TransformNode used for multiselected object interaction
     */
    static destroyTransformGroup(transformGroup) {
        const { selectionManager } = _context.modules;
        selectionManager.selectedMeshes.forEach((mesh) => {
            mesh.computeWorldMatrix(true);
            mesh.setParent(null);
            mesh.computeWorldMatrix(true);
            mesh.entity.updateDynamicParameters();
        });
        transformGroup.dispose();
    }
}
