import GridController from './src/grid-controller';

const MODULE_NAME = 'grid-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['data-store', 'scene-manager', 'history'],
    load(app) {
        return new GridController(app);
    },
    unload() {},
};
