import UserImage from './user-image.vue';
import { APP_SYMBOL } from '../../..';
export default {
    components: {
        UserImage: UserImage,
    },
    computed: {
        getPageUserImages: function () {
            var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
            var page = buildingPlanController.getPage();
            return page.userImages;
        },
    },
};
