/* eslint-disable camelcase */
import Patch_1_31_0 from '../patches/1-31-0-patch';
import Patch_1_32_0 from '../patches/1-32-0-patch';
import Patch_1_36_0 from '../patches/1-36-0-patch';
import Patch_1_46_7 from '../patches/1-46-7-patch';
import semver from 'semver';

let _context = null;
/**
 * Patches must be applied in such a way that we come from a version and update the data structure
 * to the version right after.
 * This is important otherwise we would have to thinking about all the possible versions
 * the data structure could be in and how to mutate it into the current one.
 */
export default {
    setContext(context) {
        _context = context;
    },
    /**
     * Keys must be ordered from older to newer releases, otherwise the patches will broke
     */
    Patches: {
        '1.31.0': Patch_1_31_0,
        '1.32.0': Patch_1_32_0,
        '1.36.0': Patch_1_36_0,
        '1.46.7': Patch_1_46_7,
    },

    /**
     * Returns all the patch version needed for the project to be up to date
     * If the version is not valid it returns all pathes
     * @param {ObsidianFile} project
     * @returns {Array[string]}
     */
    NeededPatches(project) {
        const becadVersion = semver.valid(semver.coerce(project.metadata.becadVersion));
        _context.log.info('Project app version :', becadVersion);

        if (!becadVersion) {
            return Object.keys(this.Patches);
        }

        // Returns only the patch needed
        return Object.keys(this.Patches).filter((patchVersion) => semver.gt(patchVersion, becadVersion));
    },

    /**
     * Looks for all the patch needed from the project to work on the running version of the app
     * @param {ObsidianFile} project
     */
    PatchProject(project) {
        const neededPatches = this.NeededPatches(project);
        let projectCopy = project;

        neededPatches.forEach((patchVersion) => {
            //eslint-disable-line
            _context.log.info('patch project with version :', patchVersion);
            projectCopy = this.Patches[patchVersion].patch(projectCopy);
        });

        return projectCopy;
    },

    /**
     * Not meant to be used outside of patches and patch helper
     *
     * Throw an error when a patch didn't applied correctly
     *
     * @param {ObsidianFile} project
     * @param {String} errorMessage
     */
    patchFailed(project, errorMessage) {
        throw new Error(
            `$Patch failed with error : ${errorMessage}.
            Data must be corrupted.
            Project version is ${project.metadata.becadVersion}.`,
        );
    },
};
