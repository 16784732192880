import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';
import OptionableMixin from '../../../option-manager/model/optionable-mixin';
import MeasurableMixin from '../../../measurement-manager/src/models/measurable-mixin';

const SideledLightboxStructure = EntityStructure.$extend({
    __name__: 'sideled-lightbox',

    __init__(params) {
        this.$super(params);
        this.initOptions();
        this.initMeasurement();
    },

    __include__: [OptionableMixin.prototype, MeasurableMixin.prototype],
});
Entity.$register(SideledLightboxStructure);
export default SideledLightboxStructure;
