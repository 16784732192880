import loader from './loader.vue';
import dialogPopin from './dialog-popin.vue';
import RightsHelper from '../../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'Overlay',
    components: {
        loader: loader,
        dialogPopin: dialogPopin,
    },
    data: function () {
        return {
            isOverlayVisible: false,
            isPopinVisible: false,
            nbLoaderCalling: 0,
            configuration: {
                title: null,
                description: null,
                isRequired: null,
                onValidate: null,
                onCancel: null,
            },
        };
    },
    mounted: function () {
        var _this = this;
        window[APP_SYMBOL].modules.iframeApi.addApiMethod('runLoader', function () {
            _this.showLoader();
        });
        window[APP_SYMBOL].modules.iframeApi.addApiMethod('stopLoader', function () {
            _this.hideLoader();
        });
        // Init loader
        this.showLoader();
        window[APP_SYMBOL].events.on('showLoader', function () {
            _this.showLoader();
        });
        window[APP_SYMBOL].events.on('hideLoader', function () {
            _this.hideLoader();
        });
        window[APP_SYMBOL].events.on('open-popin', function (configuration) {
            _this.loadPopin(configuration);
        });
        this.initLoaderEvents();
        this.initDeleteEvents();
    },
    methods: {
        /**
         * Initialize all the events that shows or hide the loader
         */
        initLoaderEvents: function () {
            var _this = this;
            // HIDE LOADER EVENTS
            [
                'hideLoader',
                '@catalog-manager.catalog-initialized',
                '@project-manager.project-loaded',
                '@swap-manager.end-swap-model',
                '@project-manager.connectors-generated',
                '@option-manager.screen-bracket-loaded',
                '@project-manager.xml-imported',
                '@project-manager.xml-exported',
                '@project-manager.panelplan-generated',
                '@option-manager.infill-image-loaded',
                '@project-manager.end-init-group-object',
                '@project-manager.project-fully-loaded',
                '@building-plan-manager.preview-created',
                '@building-plan-manager.page-content-written',
                '@option-manager.end-update-option',
                '@camera-manager.end-screenshot-behavior',
                '@measurement-manager.measurement-mode-loaded',
                '@option-manager.image-to-spread-loaded',
            ].forEach(function (currentEventName) {
                window[APP_SYMBOL].events.on(currentEventName, function () {
                    _this.hideLoader();
                });
            });
            // SHOW LOADER EVENTS
            [
                'showLoader',
                '@project-manager.loading-project',
                '@project-manager.generating-connectors',
                '@swap-manager.loading-swap-model',
                '@option-manager.loading-screen-bracket',
                '@project-manager.import-xml',
                '@project-manager.export-xml',
                '@project-manager.generate-panelplan',
                '@option-manager.load-infill-image',
                '@project-manager.init-group-object',
                '@project-manager.loading-remote-project',
                '@building-plan-manager.create-preview',
                '@building-plan-manager.write-page-content',
                '@option-manager.update-option',
                '@camera-manager.start-screenshot-behavior',
                '@measurement-manager.loading-measurement-mode',
                '@selection-manager.displaying-image-on-many-infills',
            ].forEach(function (currentEventName) {
                window[APP_SYMBOL].events.on(currentEventName, function () {
                    _this.showLoader();
                });
            });
        },
        /**
         * Event when the user delete entities
         */
        initDeleteEvents: function () {
            window[APP_SYMBOL].events.on('@obsidian-engine.delete', function () {
                if (RightsHelper.isModeBuildingPlan()) {
                    return;
                }
                window[APP_SYMBOL].modules.selectionManager.deleteAllSelected();
            });
        },
        showLoader: function () {
            this.isOverlayVisible = true;
            this.nbLoaderCalling += 1;
        },
        hideLoader: function () {
            this.nbLoaderCalling -= 1;
            if (this.nbLoaderCalling === 0) {
                this.isOverlayVisible = false;
            }
            if (this.nbLoaderCalling < 0) {
                window[APP_SYMBOL].log.error("HideLoader is called to much times, it's current value goes under 0:", this.nbLoaderCalling);
            }
        },
        showPopin: function () {
            this.isOverlayVisible = true;
            this.isPopinVisible = true;
            window[APP_SYMBOL].events.emit('popin-opened');
        },
        hidePopin: function () {
            this.isOverlayVisible = false;
            this.isPopinVisible = false;
        },
        loadPopin: function (configuration) {
            this.configuration = configuration;
            this.showPopin();
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
