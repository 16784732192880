import { Rectangle, TextBlock } from '@babylonjs/gui';

export default class SwapGui {
    constructor(context) {
        this.context = context;
        this.swapDimensionLabel = new Rectangle('swap-dimension-label');
        this.swapDimensionLabel.adaptWidthToChildren = true;
        this.swapDimensionLabel.adaptHeightToChildren = true;
        this.swapDimensionLabel.thickness = 0;
        this.swapDimensionLabel.isVisible = false;

        const {
            guiManager: { GuiController },
        } = this.context.modules;
        GuiController.addControl(this.swapDimensionLabel);

        this.swapRefText = new TextBlock();
        this.swapRefText.isVisible = false;
        this.swapDimensionLabel.addControl(this.swapRefText);
    }

    /**
     * Show the label the displaying the current reference of the swapped mesh
     * @param {Mesh} mesh mesh to put the label on
     */
    showLabel(mesh) {
        this.swapDimensionLabel.isVisible = true;
        this.swapRefText.isVisible = true;
        this.swapDimensionLabel.linkWithMesh(mesh);
    }

    /**
     * Hide the label the displaying the current reference of the swapped mesh
     */
    hideLabel() {
        this.swapDimensionLabel.isVisible = false;
        this.swapRefText.isVisible = false;
        this.swapDimensionLabel.linkWithMesh(null);
    }

    /**
     * Update the reference displayed
     * @param {string} newRef
     */
    changeDisplayedReference(newRef) {
        this.swapRefText.text = newRef;
    }
}
