import entityManager from './modules/entity-manager';
import bematrixUi from './modules/ui';
import engine from './modules/engine';
import groupManager from './modules/group-manager';
import projectManager from './modules/project-manager';
import assetsManager from './modules/assets-manager';
import cameraModule from './modules/camera-manager';
import sceneModule from './modules/scene-manager';
import lightModule from './modules/light-manager';
import gridModule from './modules/grid-manager';
import catalogModule from './modules/catalog-manager';
import materialManager from './modules/material-manager';
import meshManager from './modules/mesh-manager';
import guizmoManager from './modules/gizmo-manager';
import highlightManager from './modules/highlight-manager';
import collisionManager from './modules/collision-manager';
import optionManager from './modules/option-manager';
import geometryUtility from './modules/geometry-utility';
import initUtility from './modules/init-utility';
import swapManager from './modules/swap-manager';
import selectionManager from './modules/selection-manager';
import measurementManager from './modules/measurement-manager';
import guiManager from './modules/gui-manager';
import buildingPlanManager from './modules/building-plan-manager';
import snappingManager from './modules/snapping-manager';
import requestManager from './modules/request-manager';
import dataStore from './modules/data-store';
import iframeApi from './modules/iframe-api';
import history from '@obsidianjs/history';
import sentry from '@obsidianjs/sentry';
import stonejs from '@obsidianjs/stonejs';
import dataExporter from '@obsidianjs/data-exporter';
import obsidian from '@obsidianjs/obsidian';

import 'pepjs';

const app = obsidian('bematrix-3D');

app.use(entityManager);
app.use(history);
app.use(highlightManager);
app.use(bematrixUi);
app.use(dataStore, {
    config: {
        ignoreToSerializePaths: [],
    },
});
app.use(engine);
app.use(cameraModule);
app.use(sceneModule);
app.use(lightModule);
app.use(gridModule);
app.use(catalogModule);
app.use(iframeApi);
app.use(dataExporter);
app.use(materialManager);
app.use(meshManager);
app.use(guizmoManager);
app.use(groupManager);
app.use(collisionManager);
app.use(projectManager);
app.use(assetsManager);
app.use(optionManager);
app.use(geometryUtility);
app.use(initUtility);
app.use(swapManager);
app.use(selectionManager);
app.use(measurementManager);
app.use(stonejs);
app.use(buildingPlanManager);
app.use(snappingManager);
app.use(requestManager);
app.use(sentry, {
    config: {
        dsnKey: 'https://db406d84e82c46bfa57ddc274823ed9b@o509192.ingest.sentry.io/5615211',
        capturedLevels: ['error', 'warning'],
    },
});
app.use(entityManager);
app.use(guiManager);
app.start({
    obsidian: {
        debug: process.env.NODE_ENV === 'development', // This avoid to use sentry while dev ON
    },
});
