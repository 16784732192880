import GridStructure from './model/grid-structure';
import VueHelper from 'helpers/vue-helper';
import config from 'defaultConfig';
import { Mesh, MeshBuilder, Plane, Color3, StandardMaterial, Vector3 } from '@babylonjs/core';
import { GridMaterial } from '@babylonjs/materials';

/**
 * Used to create and manage a grid which will be applied to a Babylon ground.
 * This file uses the Tinu library to work with different units (ft, cm ...)
 */
export default class GridManager {
    /**
     * Class is created by defining its default unit to cm
     */
    constructor(context) {
        this.context = context;
        this.gridMaterial = null;
        this.offsetPlane = null;
        this.vueData = {
            width: -1,
            height: -1,
        };
        VueHelper.AddVueProperty(this, 'toInchesConstant', 39.3701);

        this.computeVueData(this.context.modules.sceneManager.groundWidth, this.context.modules.sceneManager.groundHeight);

        this.context.events.on('@scene-manager.ground-ready', () => this.initGrid());

        this.context.events.on('@project-manager.loading-project', () => {
            this.loadGridFromDataStore();
        });
        this.context.events.on('@this.context.modules.history.this.context.modules.history-go', () => {
            this.checkGridDiffs();
        });
        this.context.events.on('@camera-manager.show-ground', () => {
            this.showGround();
        });
        this.context.events.on('@camera-manager.hide-ground', () => {
            this.hideGround();
        });
    }

    /**
     * Compute vue data considering the unitMode
     * @param {Number} width - grid width (m)
     * @param {Number} height - grid height (m)
     */
    computeVueData(width, height) {
        if (config.unitMode === 'us') {
            this.vueData.width = width * this.toInchesConstant;
            this.vueData.height = height * this.toInchesConstant;
        } else {
            this.vueData.width = width;
            this.vueData.height = height;
        }
    }

    /**
     * Update the vue data  after a unit switch
     */
    switchUnit() {
        if (config.unitMode === 'us') {
            this.vueData.width *= this.toInchesConstant;
            this.vueData.height *= this.toInchesConstant;
        } else {
            this.vueData.height /= this.toInchesConstant;
            this.vueData.width /= this.toInchesConstant;
        }
    }

    /**
     * Handle the data from vue considering the unitMode
     * and update the grid accordingly */
    computeDataFromVue(width, height) {
        let convertedData = {};
        if (config.unitMode === 'us') {
            convertedData = {
                width: width / this.toInchesConstant,
                height: height / this.toInchesConstant,
            };
        } else {
            convertedData = {
                width,
                height,
            };
        }
        this.updateGrid(convertedData);
    }

    /**
     * Add a grid material to a babylon ground
     */
    initGrid() {
        this.gridMaterial = new GridMaterial('groundMaterial', this.context.modules.sceneManager.scene);
        this.gridMaterial.gridRatio = config.gridResolution; // Default frame size = 496mm
        this.gridMaterial.majorUnitFrequency = 0;
        this.gridMaterial.minorUnitVisibility = 0.33;
        this.gridMaterial.mainColor = new Color3(0.7, 0.7, 0.7);
        this.gridMaterial.lineColor = new Color3(1, 1, 1);
        this.gridMaterial.gridOffset = new Vector3(0, 0, 0);

        this.offsetPlaneMaterial = new StandardMaterial('offset-plane-material', this.context.modules.sceneManager.scene);
        this.offsetPlaneMaterial.diffuseColor = new Color3(0.9, 0.9, 0.9);
        this.offsetPlaneMaterial.specularColor = new Color3(0, 0, 0);

        this.dataStructure = new GridStructure();
        this.computeDataFromVue(this.vueData.width, this.vueData.height);
        this.context.modules.dataStore.addEntity(this.dataStructure, '/config/grid');
        this.context.modules.sceneManager.ground.material = this.gridMaterial;
        this.addOffsetPlane(this.context.modules.sceneManager.groundWidth, this.context.modules.sceneManager.groundHeight);
        this.context.modules.history.snapshot();
    }

    loadGridFromDataStore() {
        this.dataStructure = this.context.modules.dataStore.listEntities('/config/grid')[0];
        if (this.dataStructure) {
            this.updateGrid({
                width: this.dataStructure.gridWidth,
                height: this.dataStructure.gridHeight,
            });
            this.computeVueData(this.dataStructure.gridWidth, this.dataStructure.gridHeight);
        } else {
            this.context.log.error("Found a project with no ground structure, reinit it's ground structure");
            this.dataStructure = new GridStructure();
            this.computeDataFromVue(this.vueData.width, this.vueData.height);
            this.context.modules.dataStore.addEntity(this.dataStructure, '/config/grid');
            this.addOffsetPlane(this.context.modules.sceneManager.groundWidth, this.context.modules.sceneManager.groundHeight);
        }
    }

    /**
     * Called when the user changes the grid size by croping it
     * @param {width, height} data the new values of the grid
     */
    updateGrid(data, snapshot = true) {
        this.context.modules.sceneManager.updateGround(data);
        this.context.modules.sceneManager.ground.material = this.gridMaterial;
        this.dataStructure.gridHeight = data.height;
        this.dataStructure.gridWidth = data.width;
        this.addOffsetPlane(data.width, data.height);
        this.context.events.emit('grid-cropped');
        if (snapshot) {
            this.context.modules.history.snapshot();
        }
    }

    /**
     * Create a plane mesh below the grid which has the grid dimension plus an offset on its width
     * and height
     * @param {*} gridWidth - the current grid width
     * @param {*} gridHeight - the current grid height
     */
    addOffsetPlane(gridWidth = 20, gridHeight = 20) {
        if (this.offsetPlane) {
            this.offsetPlane.dispose();
        }

        const offset = 2;
        const offsetPlane = new Plane(0, 180, 0, 0);
        offsetPlane.normalize();

        this.offsetPlane = MeshBuilder.CreatePlane(
            'offset-plane',
            {
                width: gridHeight + offset, // Inverted because of babylon
                height: gridWidth + offset,
                sourcePlane: offsetPlane,
                sideOrientation: Mesh.DOUBLESIDE,
            },
            this.context.modules.sceneManager.scene,
        );

        this.offsetPlane.material = this.offsetPlaneMaterial;
        this.offsetPlane.position.y = -0.001;
    }

    /**
     * Check if the grid infos in the dataStructure is different than the infos inside vueData
     * If it's the case, we update the vueData infos
     */
    checkGridDiffs() {
        if (this.dataStructure.gridHeight !== this.vueData.height || this.dataStructure.gridWidth !== this.vueData.width) {
            const data = {
                width: this.dataStructure.gridWidth,
                height: this.dataStructure.gridHeight,
            };
            this.context.modules.sceneManager.updateGround(data);
            this.context.modules.sceneManager.ground.material = this.gridMaterial;
            this.addOffsetPlane(data.width, data.height);
            this.updateHeight(this.dataStructure.gridHeight);
            this.updateWidth(this.dataStructure.gridWidth);
        }
    }

    /**
     * Simple method to check if a bounding box is inside the grid
     * @param {BoundingBox} boundingBox
     */
    isBBInsideGrid(boundingBox) {
        this.offsetPlane.computeWorldMatrix(true);
        this.offsetPlane.refreshBoundingInfo();
        const bbMax = this.offsetPlane.getBoundingInfo().boundingBox.maximumWorld;
        const bbMin = this.offsetPlane.getBoundingInfo().boundingBox.minimumWorld;
        if (
            boundingBox.maximumWorld.x > bbMax.x ||
            boundingBox.maximumWorld.z > bbMax.z ||
            boundingBox.minimumWorld.x < bbMin.x ||
            boundingBox.minimumWorld.z < bbMin.z
        ) {
            return false;
        }
        return true;
    }

    hideGround() {
        this.offsetPlane.isVisible = false;
    }

    showGround() {
        this.context.modules.sceneManager.ground.isVisible = true;
        this.offsetPlane.isVisible = true;
    }

    updateWidth(width) {
        this.vueData.width = width;
    }

    updateHeight(height) {
        this.vueData.height = height;
    }

    getGridSize() {
        return {
            width: this.dataStructure.getGridWidth(),
            height: this.dataStructure.getGridHeight(),
        };
    }
}
