import product from './catalog-product.vue';
import RightsHelper from '../../../../../helpers/rights-helper';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'Templates',
    components: {
        product: product,
    },
    props: {
        templates: {
            type: Array,
            default: function () { return []; },
        },
        groupedObjects: {
            type: Array,
            default: function () { return []; },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            TEMPLATE_CATEGORIES: {
                BEMATRIX: 0,
                SELF: 1,
                NONE: -1,
            },
            GROUPED_OBJECT_CATEGORIES: {
                BEMATRIX: 0,
                SELF: 1,
                NONE: -1,
            },
            currentTemplateCategory: -1,
            currentGroupObjectsCategory: -1,
            showTemplatesPanel: false,
            currentTemplates: null,
            currentGroupedObjects: null,
            bmTemplatesUrl: "".concat(this.config.amazonS3Url, "/"),
        };
    },
    mounted: function () {
        var _this = this;
        window[APP_SYMBOL].events.on('@selection-manager.select-entity', function () {
            _this.closeTemplateTabs();
        });
    },
    methods: {
        /**
         * Show the templates of the given template category
         */
        toggleTemplates: function (templateCategory) {
            // overlapping on bottom menu
            if (templateCategory === this.currentTemplateCategory) {
                this.currentTemplateCategory = this.TEMPLATE_CATEGORIES.NONE;
                return;
            }
            if (this.currentGroupObjectsCategory !== this.TEMPLATE_CATEGORIES.NONE) {
                this.currentGroupObjectsCategory = this.TEMPLATE_CATEGORIES.NONE;
            }
            window[APP_SYMBOL].modules.selectionManager.unselectAll(); // TODO : temporary to avoid left menu
            this.currentTemplateCategory = templateCategory;
            this.currentTemplates = this.templates[templateCategory].templates;
        },
        /**
         * Show the templates of the given template category
         */
        toggleGroupedObjects: function (groupedObjectCategory) {
            // overlapping on bottom menu
            if (groupedObjectCategory === this.currentGroupObjectsCategory) {
                this.currentGroupObjectsCategory = this.GROUPED_OBJECT_CATEGORIES.NONE;
                return;
            }
            if (this.currentTemplateCategory !== this.GROUPED_OBJECT_CATEGORIES.NONE) {
                this.currentTemplateCategory = this.GROUPED_OBJECT_CATEGORIES.NONE;
            }
            window[APP_SYMBOL].modules.selectionManager.unselectAll(); // TODO : temporary to avoid left menu
            this.currentGroupObjectsCategory = groupedObjectCategory;
            this.currentGroupedObjects = this.groupedObjects[groupedObjectCategory].groupObjects;
        },
        /**
         * Close all template and group object tabs
         */
        closeTemplateTabs: function () {
            if (this.currentGroupObjectsCategory !== this.GROUPED_OBJECT_CATEGORIES.NONE) {
                this.toggleGroupedObjects(this.currentGroupObjectsCategory);
            }
            else if (this.currentTemplateCategory !== this.TEMPLATE_CATEGORIES.NONE) {
                this.toggleTemplates(this.currentTemplateCategory);
            }
        },
        /**
         * Load the given template at the given url
         */
        loadTemplate: function (url, infillsImg) {
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('Load a template'),
                description: this._('All unsaved progress will be lost. Do you want to proceed and load the selected template ?'),
                onValidate: function () {
                    window[APP_SYMBOL].modules.projectManager.controller.openTemplate(url, infillsImg);
                },
            });
        },
        loadGroupProduct: function (url, name) {
            window[APP_SYMBOL].modules.projectManager.controller.openStoredProductFromUrl(url, name);
        },
        computeTemplateCategoryName: function (type) {
            if (type === this.TEMPLATE_CATEGORIES.BEMATRIX) {
                return this._('BeMatrix templates');
            }
            return this._('My templates');
        },
        computeGroupedObjectCategoryName: function (type) {
            if (type === this.GROUPED_OBJECT_CATEGORIES.BEMATRIX) {
                return this._('BeMatrix Group of Objects');
            }
            return this._('My Group of Objects');
        },
        canDisplayTemplateCategory: function (templateCategory) {
            return !RightsHelper.isModeDemo() || (RightsHelper.isModeDemo() && templateCategory.category === 0);
        },
        canDisplayGroupedObjectsCategory: function (groupedObjectsCategory) {
            return !RightsHelper.isModeDemo() || (RightsHelper.isModeDemo() && groupedObjectsCategory.category === 0);
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
