import OptionBaseplate from './option-baseplate.vue';
import EventBus from '../../eventBus';
import RemoveButton from '../buttons/remove-button.vue';
import SwapButton from '../buttons/swap-button.vue';
import { debounce } from '../../../../../helpers/js-helper';
import OptionableMixin from '../../../../option-manager/model/optionable-mixin';
import { APP_SYMBOL } from '../../..';
import config from 'defaultConfig';
var debounceSnapshot = debounce(function () {
    window[APP_SYMBOL].modules.history.snapshot();
}, 1000);
export default {
    name: 'EntityToolboxView',
    components: {
        RemoveButton: RemoveButton,
        SwapButton: SwapButton,
        OptionBaseplate: OptionBaseplate,
    },
    props: {
        selectedEntity: {
            type: Object,
            default: function () { return ({}); },
        },
        optionFamily: {
            type: Number,
            default: function () { return 0; },
        },
        currentBaseplateType: {
            type: Object,
            default: function () { return ({}); },
        },
        currentGroundplateType: {
            type: Object,
            default: function () { return ({}); },
        },
        currentLightType: {
            type: Object,
            default: function () { return ({}); },
        },
        optionsFamilies: {
            type: Object,
            default: function () { return ({}); },
        },
        selectedMeshes: {
            type: Array,
            default: function () { return []; },
        },
    },
    data: function () {
        return {
            baseplateOptions: null,
            groundplateOptions: null,
            shelvesOptions: OptionableMixin.SHELF_OPTION,
            screenOptions: OptionableMixin.SCREEN_OPTION,
            lightSingularityOptions: OptionableMixin.LIGHT_SINGULARITY_OPTION,
            lightOptions: OptionableMixin.LIGHT_OPTION,
            infillsOptions: OptionableMixin.INFILL_OPTION,
            infillTypeImage: 0,
            isExpanded: false,
            infillImageMenuVisible: false,
            infillColorMenuVisible: false,
            spreadImageMenuVisible: false,
            currentColor: {
                value: '#ffffff',
            },
            config: config,
            optionController: null,
        };
    },
    computed: {
        shelfNumberValues: function () {
            this.optionController.shelfController.updateMaxShelfNumber();
            var values = [];
            for (var i = 1; i <= this.selectedEntity.maxShelfNumber; i += 1) {
                values.push(i);
            }
            return values;
        },
        screenNumberValues: function () {
            this.optionController.screenBracketController.updateMaxScreenNumber();
            var values = [];
            for (var i = 1; i <= this.selectedEntity.maxScreenNumber; i += 1) {
                values.push(i);
            }
            return values;
        },
        lightNumberValues: function () {
            var _this = this;
            var maxLight = 0;
            if (this.selectedMeshes.length > 0) {
                var maxLightArray = this.selectedMeshes.map(function (mesh) {
                    _this.optionController.lightController.updateMaxLightNumber(mesh.entity);
                    return mesh.entity.maxLightNumber;
                });
                maxLight = Math.min.apply(Math, maxLightArray);
            }
            else {
                this.optionController.lightController.updateMaxLightNumber();
                maxLight = this.selectedEntity.maxLightNumber;
            }
            var values = [];
            for (var i = 1; i <= maxLight; i += 1) {
                values.push(i);
            }
            return values;
        },
        lights: function () {
            return Object.keys(this.optionController.lightController.lightsReferences);
        },
        isStandardWidth: function () {
            return this.selectedEntity.orientedWidth === 0.992;
        },
        allStandardWidth: function () {
            return this.selectedMeshes.every(function (mesh) { return mesh.entity.orientedWidth === 0.992; });
        },
    },
    watch: {
        'currentColor.value': function () {
            this.onColorPickerChange();
        },
    },
    created: function () {
        this.optionController = window[APP_SYMBOL].modules.optionManager.optionController;
    },
    mounted: function () {
        var _this = this;
        EventBus.$on('enabledBaseplatesOptions', function () {
            _this.computeBaseplatesRules();
        });
        window[APP_SYMBOL].events.on('@selection-manager.select-entity-scene', function () {
            _this.computeBaseplatesRules();
        });
        window[APP_SYMBOL].events.on('@selection-manager.unselect-entity-scene', function () {
            _this.computeBaseplatesRules();
        });
        EventBus.$on('enabledGroundplatesOptions', function () {
            _this.computeGroundplatesRules();
        });
        window[APP_SYMBOL].events.on('@option-manager.infill-image-loaded', function () {
            _this.$forceUpdate();
        });
        window[APP_SYMBOL].events.on('@option-manager.infill-image-removed', function () {
            _this.$forceUpdate();
        });
        EventBus.$on('toggle-groups', function (open) {
            _this.isExpanded = !open;
        });
    },
    methods: {
        /**
         * Set the given option type of the current option family to the current entity
         */
        addOption: function (type) {
            if (this.selectedEntity) {
                this.optionController.addOption(this.optionFamily, type, null, true, true);
            }
            else if (this.selectedMeshes) {
                var entitiesList = this.selectedMeshes.map(function (mesh) { return mesh.entity; });
                this.optionController.multiEntityAddOption(this.optionFamily, type, entitiesList);
                // Force the update otherwise we can't disable the button of the applied option
                this.$forceUpdate();
            }
        },
        /**
         * Remove the current option family of the current entity
         */
        removeOption: function () {
            if (this.selectedEntity) {
                this.optionController.removeOption(this.optionFamily, null, true);
            }
            else if (this.selectedMeshes) {
                var entitiesList = this.selectedMeshes.map(function (mesh) { return mesh.entity; });
                this.optionController.multiEntityRemoveOption(this.optionFamily, entitiesList);
                this.$forceUpdate();
            }
        },
        /**
         * Swap the current optionfamily side
         */
        swapOption: function () {
            if (this.selectedEntity) {
                this.optionController.swapOptionsSide(this.optionFamily);
            }
            else if (this.selectedMeshes) {
                var entitiesList = this.selectedMeshes.map(function (mesh) { return mesh.entity; });
                this.optionController.multiEntitySwapOptionsSide(this.optionFamily, entitiesList);
                this.$forceUpdate();
            }
        },
        /**
         * Check the current option number to display according to current option family
         */
        checkOptionNumber: function () {
            /**
             * Carefull, this don't work for infill/multiselection because options with number
             * are not meant to be used for multiselection
             */
            if (this.selectedEntity && this.optionFamily) {
                if (this.optionFamily === this.optionsFamilies.SHELF) {
                    return this.selectedEntity.shelfNumber;
                }
                if (this.optionFamily === this.optionsFamilies.LIGHT) {
                    return this.selectedEntity.lightNumber;
                }
                if (this.optionFamily === this.optionsFamilies.SCREEN) {
                    return this.selectedEntity.screenNumber;
                }
                return 1;
            }
            return 0;
        },
        /**
         * Check baseplaterules
         */
        computeBaseplatesRules: function () {
            if (this.selectedEntity) {
                this.baseplateOptions = this.optionController.baseplateController.availableBaseplates();
            }
            else if (this.selectedMeshes && this.selectedMeshes.length > 0) {
                // Keep only common values of all meshes
                var commonOptions = this.optionController.baseplateController.availableBaseplates(this.selectedMeshes[0].entity);
                var _loop_1 = function (i) {
                    var opts = this_1.optionController.baseplateController.availableBaseplates(this_1.selectedMeshes[i].entity);
                    if (!commonOptions || !opts) {
                        return "break";
                    }
                    commonOptions = commonOptions.filter(function (v) { return opts.findIndex(function (elt) { return elt.type === v.type; }) !== -1; });
                };
                var this_1 = this;
                for (var i = 1; i < this.selectedMeshes.length; i += 1) {
                    var state_1 = _loop_1(i);
                    if (state_1 === "break")
                        break;
                }
                this.baseplateOptions = commonOptions;
            }
        },
        computeGroundplatesRules: function () {
            if (this.selectedEntity) {
                this.groundplateOptions = this.optionController.groundplateController.availableGroundplates();
            }
            else if (this.selectedMeshes && this.selectedMeshes.length > 0) {
                // Keep only common values of all meshes
                var commonOptions = this.optionController.groundplateController.availableGroundplates(this.selectedMeshes[0].entity);
                var _loop_2 = function (i) {
                    var opts = this_2.optionController.groundplateController.availableGroundplates(this_2.selectedMeshes[i].entity);
                    commonOptions = commonOptions.filter(function (v) { return opts.findIndex(function (elt) { return elt.type === v.type; }) !== -1; });
                };
                var this_2 = this;
                for (var i = 1; i < this.selectedMeshes.length; i += 1) {
                    _loop_2(i);
                }
                this.groundplateOptions = commonOptions;
            }
        },
        onUpdateOptionNumber: function () {
            this.optionController.onUpdateOptionNumberFromUi(this.optionFamily);
        },
        /**
         * Check if selected meshes are the same infill type as the one given
         * @param {Number} type - the infill type to check
         * @param {Boolean} every - (Optional, true by default) true if the condition must be valid
         * for every selected meshes, false if the condition need to be valid only once
         */
        sameInfillType: function (type, every) {
            if (every === void 0) { every = true; }
            if (this.selectedMeshes) {
                if (every) {
                    return this.selectedMeshes.every(function (mesh) { return mesh.entity.infillOption === type; });
                }
                return this.selectedMeshes.some(function (mesh) { return mesh.entity.infillOption === type; });
            }
            return null;
        },
        areStraightAndHaveInfills: function () {
            var _this = this;
            if (this.selectedMeshes) {
                return this.selectedMeshes.every(function (mesh) {
                    return mesh.entity.subCategory === 'STRAIGHT FRAMES' && mesh.entity.infillOption === _this.infillsOptions.ONE_FACE;
                });
            }
            return false;
        },
        /**
         * Open the import image dialog
         */
        loadImage: function (event, type, spread) {
            if (type === void 0) { type = 0; }
            if (spread === void 0) { spread = false; }
            if (!spread) {
                this.$refs.fileInput.click();
            }
            else {
                this.$refs.fileInputForMultiInfill.click();
            }
            this.infillTypeImage = type;
        },
        /**
     * Add the imported image to the selected entites
     * enumTypeInfillImages = {
            allFaces: 0,
            firstFace: 1,
            secondFace: 2,
        };
     */
        importInfillImage: function (event) {
            var urlObjet = window[APP_SYMBOL].modules.assetsManager.controller.addNewImportedImage(event.target.files[0]);
            event.target.value = '';
            window[APP_SYMBOL].modules.selectionManager.addInfillImageToSelectedEntities(urlObjet, this.infillTypeImage);
        },
        openColorPicker: function (infillType) {
            var _this = this;
            if (infillType === void 0) { infillType = 0; }
            this.infillTypeImage = infillType;
            this.updateCurrentColorFromSelection();
            this.closeInfillColorMenu();
            setTimeout(function () {
                EventBus.$emit('open-color-picker', _this.currentColor);
            }, 0);
        },
        onColorPickerChange: function () {
            this.importInfillColor(this.currentColor.value, this.infillTypeImage);
            debounceSnapshot();
        },
        /**
         * Add the imported color to the selected entities
         * @param {Color} color The color to set
         * @param {int} type The type of infill (0, 1, 2)
         */
        importInfillColor: function (color, type) {
            if (type === void 0) { type = 0; }
            this.infillTypeImage = type;
            window[APP_SYMBOL].modules.selectionManager.addInfillColorToSelectedEntities(color.toString(), this.infillTypeImage);
        },
        spreadInfillImage: function (event) {
            var imageFile = event.target.files[0];
            event.target.value = '';
            window[APP_SYMBOL].modules.selectionManager.displayImageOnEverySelectedEntities(imageFile);
        },
        onOptionNumberChange: function (event) {
            if (parseInt(event.target.value, 10) === 0) {
                this.removeOption();
            }
            window[APP_SYMBOL].modules.history.snapshot();
        },
        onOptionLightChange: function (event) {
            var _this = this;
            if (this.selectedEntity) {
                this.selectedEntity.lightOption = parseInt(event.target.value, 10);
                this.optionController.lightController.addLights(this.selectedEntity);
            }
            else if (this.selectedMeshes.length) {
                this.selectedMeshes.forEach(function (mesh) {
                    mesh.entity.lightOption = parseInt(event.target.value, 10);
                    _this.optionController.lightController.addLights(mesh.entity);
                });
            }
            window[APP_SYMBOL].modules.history.snapshot();
        },
        updateLightSingularity: function (lightSingularityOption) {
            var _this = this;
            if (this.selectedEntity) {
                this.selectedEntity.lightSingularity = lightSingularityOption;
                this.optionController.lightController.updateLightMeshes(this.selectedEntity);
            }
            else if (this.selectedMeshes) {
                this.selectedMeshes.forEach(function (mesh) {
                    mesh.entity.lightSingularity = lightSingularityOption;
                    _this.optionController.lightController.updateLightMeshes(mesh.entity);
                });
            }
            window[APP_SYMBOL].modules.history.snapshot();
        },
        onLightNumberChange: function (event) {
            var _this = this;
            if (this.selectedEntity) {
                this.selectedEntity.lightNumber = parseInt(event.target.value, 10);
                this.optionController.lightController.updateLightMeshes(this.selectedEntity);
            }
            else if (this.selectedMeshes.length) {
                this.selectedMeshes.forEach(function (mesh) {
                    mesh.entity.lightNumber = parseInt(event.target.value, 10);
                    _this.optionController.lightController.updateLightMeshes(mesh.entity);
                });
            }
            window[APP_SYMBOL].modules.history.snapshot();
        },
        getLightName: function (lightOption) {
            var lightNameEntry = Object.entries(this.lightOptions).find(function (entry) { return entry[1] === lightOption; });
            if (!lightNameEntry) {
                return '';
            }
            return lightNameEntry[0].replace(/_/g, ' ', ' ');
        },
        /**
         * Remove the selected infill images
         */
        removeInfillImage: function () {
            if (this.selectedEntity) {
                this.optionController.infillController.removeInfillImage(this.selectedEntity, true, true);
            }
            else if (this.selectedMeshes) {
                var selectedEntities = this.selectedMeshes.map(function (mesh) { return mesh.entity; });
                this.optionController.infillController.removeEntitiesListInfillsImages(selectedEntities);
            }
        },
        /**
         * Check if all selected meshes has infill
         */
        meshesHaveInfills: function () {
            var _this = this;
            return this.selectedMeshes.some(function (mesh) { return mesh.entity.infillOption !== _this.infillsOptions.NONE; });
        },
        /**
         * Check if any selected meshes has lights
         */
        meshesHaveLights: function () {
            return this.selectedMeshes.some(function (mesh) { return mesh.entity.optionsMeshes.lights.length !== 0; });
        },
        /**
         * Check if all selected meshes can swap lights
         */
        canAllMeshSwapLights: function () {
            return this.selectedMeshes.find(function (mesh) { return mesh.entity.optionsMeshes.lights.length > 0; });
        },
        /**
         * Check if any selected meshes has shelves
         */
        meshesHaveShelves: function () {
            return this.selectedMeshes.some(function (mesh) { return mesh.entity.optionsMeshes.shelves.length !== 0; });
        },
        /**
         * Check if any selected meshes has screens
         */
        meshesHaveScreens: function () {
            return this.selectedMeshes.some(function (mesh) { return mesh.entity.optionsMeshes.screens.length !== 0; });
        },
        /**
         * Check if any selected meshes has baseplates
         */
        meshesHaveBaseplates: function () {
            return this.selectedMeshes.some(function (mesh) { return mesh.entity.optionsMeshes.baseplates.length !== 0; });
        },
        /**
         * Check if any selected meshes has groundplates
         */
        meshesHaveGroundplates: function () {
            return this.selectedMeshes.some(function (mesh) { return mesh.entity.optionsMeshes.groundplates.length !== 0; });
        },
        /**
         * Check if all selected meshes can swap baseplates
         */
        canAllMeshSwapBaseplates: function () {
            return this.selectedMeshes.every(function (mesh) {
                return mesh.entity.optionsMeshes.baseplates.length === 0 ||
                    mesh.entity.getBaseplateOption() === OptionableMixin.BASEPLATE_OPTION.ONE_SIDE;
            });
        },
        /**
         * Check if all selected meshes has infill and images
         * Check if all selected meshes has infill images
         */
        infillsHasImage: function () {
            if (this.selectedEntity) {
                return Boolean(this.selectedEntity.firstInfillImageId || this.selectedEntity.secondInfillImageId);
            }
            if (this.selectedMeshes) {
                return this.selectedMeshes.some(function (mesh) { return mesh.entity.firstInfillImageId || mesh.entity.secondInfillImageId; });
            }
            return false;
        },
        /**
         * Check if all selected meshes has infill colors
         */
        infillsHasColor: function () {
            if (this.selectedEntity) {
                return Boolean(this.selectedEntity.firstInfillColor || this.selectedEntity.secondInfillColor);
            }
            if (this.selectedMeshes) {
                return this.selectedMeshes.some(function (mesh) { return mesh.entity.firstInfillColor || mesh.entity.secondInfillColor; });
            }
            return false;
        },
        updateCurrentColorFromSelection: function () {
            var foundColor;
            if (this.selectedEntity) {
                foundColor =
                    null ||
                        // eslint-disable-next-line max-len
                        (this.infillTypeImage === 0
                            ? this.selectedEntity.firstInfillColor || this.selectedEntity.secondInfillColor
                            : null) ||
                        (this.infillTypeImage === 1 ? this.selectedEntity.firstInfillColor : null) ||
                        (this.infillTypeImage === 2 ? this.selectedEntity.secondInfillColor : null);
            }
            else if (this.selectedMeshes) {
                for (var i = 0; i < this.selectedMeshes.length; i++) {
                    var mesh = this.selectedMeshes[i];
                    foundColor =
                        null ||
                            (this.infillTypeImage === 0 ? mesh.entity.firstInfillColor || mesh.entity.secondInfillColor : null) ||
                            (this.infillTypeImage === 1 ? mesh.entity.firstInfillColor : null) ||
                            (this.infillTypeImage === 2 ? mesh.entity.secondInfillColor : null);
                    if (foundColor) {
                        break;
                    }
                }
            }
            if (foundColor) {
                this.currentColor.value = foundColor;
            }
        },
        toggleInfillImageMenu: function () {
            this.infillImageMenuVisible = !this.infillImageMenuVisible;
            this.closeInfillColorMenu();
        },
        toggleInfillColorMenu: function () {
            this.infillColorMenuVisible = !this.infillColorMenuVisible;
            this.infillImageMenuVisible = false;
            if (this.infillColorMenuVisible) {
                return;
            }
            this.closeInfillColorMenu();
        },
        showInfillImageMenu: function () {
            this.infillImageMenuVisible = true;
        },
        closeInfillImageMenu: function () {
            this.infillImageMenuVisible = false;
        },
        showInfillColorMenu: function () {
            this.infillColorMenuVisible = true;
        },
        closeInfillColorMenu: function () {
            this.infillColorMenuVisible = false;
        },
        openSpreadImageMenu: function () {
            this.spreadImageMenuVisible = true;
        },
        closeSpreadImageMenu: function () {
            this.spreadImageMenuVisible = false;
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
