import GuiController from './src/controller/gui-controller';

const MODULE_NAME = 'gui-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['obsidian-engine', 'mesh-manager'],
    load(app) {
        window[APP_SYMBOL] = app;
        return {
            GuiController: new GuiController(app),
        };
    },
    unload() {},
};
