import { Rectangle, TextBlock } from '@babylonjs/gui';

export default class RotationGizmoGui {
    constructor(context) {
        this.context = context;
        this.angleLabel = new Rectangle('swap-dimension-label');
        this.angleLabel.adaptWidthToChildren = true;
        this.angleLabel.adaptHeightToChildren = true;
        this.angleLabel.thickness = 0;
        this.angleLabel.isVisible = false;
        this.guiManager = this.context.modules.guiManager;
        this.guiManager.GuiController.addControl(this.angleLabel);

        this.angleText = new TextBlock();
        this.angleText.isVisible = false;
        this.angleLabel.addControl(this.angleText);
    }

    toggleGuiVisibility(visibility) {
        this.angleLabel.isVisible = visibility;
        this.angleText.isVisible = visibility;
    }

    setAngleText(angle) {
        this.angleText.text = `${angle} °`;
    }

    linkGuiWithMesh(mesh) {
        this.angleLabel.linkWithMesh(mesh);
    }
}
