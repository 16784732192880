import BuildingPlanImageController from './building-plan-image-controller';
import BuildingPlanHelper from '../helper/building-plan-helper';
import Page, { PAGE_TYPES } from '../model/page-structure';
import VueHelper from 'helpers/vue-helper';
import RightsHelper from 'helpers/rights-helper';
import JsPDF from 'jspdf';
import { v4 as uuid } from 'uuid';
import { Vector2, Vector3, Matrix, Ray, Camera } from '@babylonjs/core';

export const TEXT_SIZES = {
    LARGE: 'large-text',
    MEDIUM: 'medium-text',
    SMALL: 'small-text',
};

/**
 * Main controller for the building plans implementation
 *
 * Inside many functions of the building plan plugin we handle both 'indices'
 * (usually a number used to find a an element inside an array, starting from) and
 * 'numbers' (a number used by the jspdf API, usually to manage pages and starting from 1).
 * Those two notions are not commutable, both have a distinct use explained above.
 *
 * All the checks that must be done on a page (e.g is the page editable ?),
 * must be done from the ui
 *
 */
export default class BuildingPlanController {
    constructor(context) {
        this.context = context;
        /**
         * This is the instance of the PDF that will be exported
         */
        this.pdf = new JsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
        });

        this.A4Size = { width: 297, height: 210 };

        this.initVue();
        this.initEvent();
        this.context.modules.iframeApi.addApiMethod('downloadBuildingPlan', () => this.downloadBuildingPlan());

        this.scene = this.context.modules.obsidianEngine.controller.scene;

        this.imageController = new BuildingPlanImageController(this, this.context);
    }

    initVue() {
        // The PDF structure that gets serialized
        // TODO: Create a real serialized class

        const firstPage = new Page(PAGE_TYPES.SCENE, this.context);

        VueHelper.AddVueProperty(this, 'pdfPages', [firstPage]);

        VueHelper.AddVueProperty(this, 'currentPage', firstPage);
        VueHelper.AddVueProperty(this, 'selectedPageNumber', 1);
        VueHelper.AddVueProperty(this, 'isCameraFixed', false);
    }

    initEvent() {
        this.context.events.on('@obsidian-engine.engine-ready', (scene) => {
            this.scene = scene;
        });

        this.context.events.on('@selection-manager.select-entity-scene', (product) => {
            const page = this.getPage();
            // when picking a part (connectors), all the connectors nearby are selected too
            // it's used to create an annotation whith screenshots of a group of parts
            if (product.isConnector) {
                if (product.ref !== 'ErrorBlock') {
                    this.context.modules.selectionManager.selectNearbyConnectors();
                }
                this.lastSelectionPointerPosition = new Vector2(this.scene.pointerX, this.scene.pointerY);
            }
            page.scenePageMetadata.selectedProductsPointerPosition[product.id] = new Vector2(this.scene.pointerX, this.scene.pointerY);
        });
        this.context.events.on('@selection-manager.unselect-entity-scene', (product) => {
            const page = this.getPage();

            if (page && page.type === PAGE_TYPES.SCENE) {
                delete page.scenePageMetadata.selectedProductsPointerPosition[product.id];
            }
        });
        this.context.events.on('@selection-manager.unselect-all', () => {
            const page = this.getPage();
            if (page && page.type === PAGE_TYPES.SCENE) {
                page.scenePageMetadata.selectedProductsPointerPosition = {};
            }
        });
        this.context.events.on('@measurement-manager.measurement-created', (measurementEntity) => {
            if (!this.isCameraFixed && this.currentPage && RightsHelper.isModeBuildingPlan()) {
                this.imageController.temporaryMeasurements.push(measurementEntity);
            }
        });
        this.context.events.on('@measurement-manager.measurement-destroyed', (measurementEntity) => {
            const index = this.imageController.temporaryMeasurements.indexOf(measurementEntity);
            if (index >= 0) {
                this.imageController.temporaryMeasurements.splice(index, 1);
            }
        });
        this.context.events.on('@obsidian-engine.delete', () => {
            if (!this.context.modules.measurementManager.controller.selectedMeasurementEntity) {
                return;
            }
            if (!this.isCameraFixed && this.currentPage) {
                const index = this.imageController.temporaryMeasurements.indexOf(
                    this.context.modules.measurementManager.controller.selectedMeasurementEntity,
                );
                if (index >= 0) {
                    this.context.events.emit('measurement-delete');
                }
            }
        });
    }

    /**
     * Update page content and replicate the change into vue data
     * @param {*} page updated page
     */
    updatePage(page) {
        this.pdfPages.splice(this.selectedPageNumber - 1, 1, page);

        this.currentPage = this.pdfPages[this.selectedPageNumber - 1];
    }

    /**
     * Will later depend on the page type
     *
     * This function clear the page data structure and unselect every produtcs
     */
    clearPage() {
        BuildingPlanHelper.removePageMeasurements(this.currentPage);
        this.updatePage(new Page(this.currentPage.type, this.context));

        const page = this.getPage();

        if (page.type === PAGE_TYPES.SCENE) {
            BuildingPlanHelper.setConnectorSelectionMode(page, page.scenePageMetadata.connectorSelectionModeEnabled);
            BuildingPlanHelper.restoreProductsVisibility(page);
        }
    }

    /**
     * Generates the pdf blob and emit an api event after generation
     */
    generatesTemporaryPdf() {
        this.addPaging();

        this.temporaryGeneratedPdf = this.pdf.output('blob');
        this.context.modules.iframeApi.sendEvent('download-building-plan');
    }

    /**
     * Returns the generated pdf once
     * @returns {Blob}
     */
    downloadBuildingPlan() {
        if (!this.temporaryGeneratedPdf) {
            const err = new Error("Can't save building plan pdf because it hasn't been generated yet");
            this.context.log.error(err);
            throw err;
        }
        const pdf = this.temporaryGeneratedPdf;
        this.temporaryGeneratedPdf = null;
        return pdf;
    }

    /**
     * Save a pdf, like saveas package does
     * @param {String || null } filename
     */
    download(filename = null) {
        // Save current building plan to a data structure and send an event so the web package
        // embedding the iframe can get it
        const pdfFilename = BuildingPlanHelper.getPdfFilename(filename);

        this.pdf.save(`${pdfFilename}.pdf`);
    }

    /**
     * Activate/deactivate the pdfMode
     * Clean the pdf if we deactivate the pdf mdoe
     */
    togglePdfMode() {
        if (RightsHelper.isModeBuildingPlan()) {
            while (this.pdf.getNumberOfPages() > 0) {
                this.removePage();
            }
            // Create a blank page before quitting mode
            this.addPage(PAGE_TYPES.SCENE);
        }

        RightsHelper.toggleBuildingPlanMode();
    }

    /**
     * Returns true if all the pages are populated
     * @returns { Boolean }
     */
    isPdfComplete() {
        return this.pdfPages.every((page) => !page.editing);
    }

    /**
     *
     * Interactions with the JSPDF instance
     *
     */

    /**
     * Add a new empty page that will be displayed inside the building plan preview menu
     * @param {PAGE_TYPES} pageType define the behaviors Not yet implemented
     * @param {Number} pageNumber
     */
    addPage(pageType, pageNumber) {
        this.pdf.addPage();

        this.pdfPages.push(new Page(pageType, this.context));

        this.setActivePage(pageNumber || this.pdf.getCurrentPageNumber());
    }

    /**
     * Remove the page that is selected using the building plan preview menu
     */
    removePage() {
        const pageToRemove = this.selectedPageNumber;
        if (this.currentPage.type === PAGE_TYPES.SCENE) {
            BuildingPlanHelper.removePageMeasurements(this.currentPage);
            this.currentPage.scenePageMetadata.measurements.length = 0;
            BuildingPlanHelper.removeMeasurementList(this.imageController.temporaryMeasurements);
            this.imageController.temporaryMeasurements = [];
        }
        this.pdf.deletePage(pageToRemove);
        this.pdfPages.splice(pageToRemove - 1, 1);

        this.selectedPageNumber = null;

        if (this.pdf.getNumberOfPages() > 0) {
            this.setActivePage(this.pdf.getCurrentPageNumber());
        } else {
            // Create a fake page with all products visible
            const fakePage = new Page(PAGE_TYPES.SCENE, this.context);
            fakePage.scenePageMetadata.activeProducts = this.context.modules.dataStore.listEntities('/products/*');

            BuildingPlanHelper.setConnectorSelectionMode(fakePage, false);
            BuildingPlanHelper.restoreProductsVisibility(fakePage);
            this.context.modules.cameraManager.controller.activateCamera(true);
            this.isCameraFixed = false;
        }
    }

    /**
     * Removes then add a page into the JSPDF instance
     * This replace the previous page with a new blank page
     * This function doesn't alter the corresponding page in the pdfPages array
     */
    recreatePage() {
        const pageNumberToReset = this.pdf.getCurrentPageNumber();
        this.pdf.deletePage(pageNumberToReset);
        this.pdf.insertPage(pageNumberToReset);
    }

    /**
     * Replicate the current state of the page into the JSPDF instance
     * (e.i add images, annotations,...)
     */
    writePageToPdf() {
        this.context.events.emit('write-page-content');

        // Delay function execution to allow vue update
        setTimeout(() => {
            this.addImagesToPdf();
            this.addBematrixLogo();
            this.context.events.emit('page-content-written');
        });
    }

    /**
     * Change the focused page
     * @param {Number} pageNumber
     */
    setActivePage(pageNumber) {
        this.selectedPageNumber = pageNumber;
        this.pdf.setPage(this.selectedPageNumber);

        const page = this.pdfPages[pageNumber - 1];
        BuildingPlanHelper.removePageMeasurements(this.currentPage);
        BuildingPlanHelper.removeMeasurementList(this.imageController.temporaryMeasurements);
        this.imageController.temporaryMeasurements = [];
        this.currentPage = page;

        if (page.type === PAGE_TYPES.SCENE) {
            if (page.images.SCENE) {
                this.context.modules.cameraManager.controller.changeView(page.camera);
                this.context.modules.cameraManager.controller.activateCamera(false);
                this.context.modules.cameraManager.controller.disableOrthoZoom();
                this.isCameraFixed = true;
            } else {
                this.context.modules.cameraManager.controller.activateCamera(true);

                if (page.scenePageMetadata.mode === Camera.PERSPECTIVE_CAMERA) {
                    if (!this.context.modules.cameraManager.controller.currentCamera.inputs.attached.mousewheel) {
                        this.context.modules.cameraManager.controller.currentCamera.inputs.addMouseWheel();
                    }
                } else {
                    this.context.modules.cameraManager.controller.enableOrthoZoom();
                }
                this.isCameraFixed = false;
            }

            // Restore camera mode and zoom
            this.context.modules.cameraManager.controller.currentCamera.mode = page.scenePageMetadata.cameraMode;
            if (page.scenePageMetadata.orthoZoomStart) {
                this.context.modules.cameraManager.controller.orthoZoomStart = page.scenePageMetadata.orthoZoomStart;
                this.context.modules.cameraManager.controller.adaptOrthoSizeToCanvas();
            }

            BuildingPlanHelper.setConnectorSelectionMode(page, page.scenePageMetadata.connectorSelectionModeEnabled);
            BuildingPlanHelper.restoreMeasurements(page);
            BuildingPlanHelper.restoreProductsVisibility(page);
            this.context.modules.selectionManager.unselectAll();

            this.context.events.emit('change-product-visibility');
        }
    }

    /**
     * Returns the current pdfPages used
     * @param { Number } pageNumber
     * @returns { images: (HTML, SCENE), editing }
     */
    getPage(pageNumber) {
        const pageIndex = (pageNumber || this.selectedPageNumber) - 1;
        return this.pdfPages[pageIndex];
    }

    /**
     * Pages are supposed to be immutable from the ui point of view
     * They have to be marked as editing to allow the ui to mute them
     *
     * Make the current page mutable
     * @param { Number } pageNumber
     */
    startPageEditing(pageNumber) {
        const page = this.getPage(pageNumber);
        page.editing = true;

        if (page.type === PAGE_TYPES.SCENE) {
            if (page.scenePageMetadata.mode === Camera.PERSPECTIVE_CAMERA) {
                if (!this.context.modules.cameraManager.controller.currentCamera.inputs.attached.mousewheel) {
                    this.context.modules.cameraManager.controller.currentCamera.inputs.addMouseWheel();
                }
            } else {
                this.context.modules.cameraManager.controller.enableOrthoZoom();
            }
            this.context.modules.cameraManager.controller.activateCamera(true);
            this.isCameraFixed = false;
        }

        this.context.events.emit('start-page-editing');
    }

    /**
     * update pdf after moving a page
     * from the old index to the new index
     * @param {Number} newIndex
     * @param {Number} oldIndex
     */
    updateMovedPage(newIndex, oldIndex) {
        // update the pdf for the old page if needed
        this.selectedPageNumber = oldIndex + 1;
        const oldPage = this.getPage();
        if (!oldPage.editing) {
            this.pdf.setPage(this.selectedPageNumber);
            this.recreatePage();
            this.addImagesToPdf();
        }

        // update the pdf for the dragged page if needed
        this.selectedPageNumber = newIndex + 1;
        const newPage = this.getPage();
        if (!newPage.editing) {
            this.pdf.setPage(this.selectedPageNumber);
            this.recreatePage();
            this.addImagesToPdf();
        }

        this.setActivePage(newIndex + 1);
    }

    /**
     * Make the current page immutable
     * @param { Number } pageNumber
     */
    endPageEditing(pageNumber) {
        const page = this.getPage(pageNumber);
        page.editing = false;

        if (page.type === PAGE_TYPES.SCENE) {
            this.isCameraFixed = true;
            this.context.modules.cameraManager.controller.activateCamera(false);
        }

        this.context.events.emit('end-page-editing');
    }

    /**
     * Return the current page edition state
     * @param { Number } pageNumber
     * @returns {boolean}
     */
    isPageEditable(pageNumber) {
        const page = this.getPage(pageNumber);
        return page ? page.editing : false;
    }

    /**
     * Wrapper for JsPDF add image
     * Use to add the cached images definetely to the PDF
     * @param { height: Number, width: Number } size
     */
    addImagesToPdf(size = this.A4Size) {
        const images = this.imageController.getPageImages();

        images.forEach((image) => {
            this.pdf.addImage(image.base64, 'PNG', 0, 0, size.width, size.width * image.ratio, '', 'FAST');
        });
    }

    /**
     * Add the bematrix logo on the top left corner of each pages
     */
    addBematrixLogo() {
        const width = 60;
        const height = width * 0.193;
        const raw = this.pdf.loadFile('../../../../assets/images/bematrix-logo.png', true, null);
        this.pdf.addImage(raw, 'PNG', 5, this.A4Size.height - height - 5, width, height, null, 'FAST', 0);
    }

    /**
     * Add the current page number on the bottom right
     */
    addPageNumber() {
        this.pdf.setFontSize(10);
        this.pdf.text(
            `${this.pdf.getCurrentPageNumber()}/${this.pdf.getNumberOfPages()}`,
            this.A4Size.width - 5,
            this.A4Size.height - 5,
            { align: 'center' },
            null,
        );
    }

    /**
     * Add the page number to every pages of the PDF on the bottom rights corner
     * and manage all the edge cases by recreating the page
     */
    addPaging() {
        const cachedPageNumber = this.selectedPageNumber;
        const pagesCount = this.pdf.getNumberOfPages();

        for (let i = 1; i < pagesCount + 1; i += 1) {
            this.setActivePage(i);

            if (!this.isPageEditable(i)) {
                this.addImagesToPdf();
                this.addBematrixLogo();
            }

            this.addPageNumber();
        }

        this.setActivePage(cachedPageNumber);
    }

    /**
     * Show the table corresponding to that page
     */
    addTable() {
        const page = this.getPage();

        page.table.productsQuantities = BuildingPlanHelper.getProductsQuantities(page.scenePageMetadata.activeProducts);
        page.table.exists = true;

        this.updatePage(page);
    }

    /**
     * Hide the table corresponding to that page
     */
    removeTable() {
        const page = this.getPage();

        page.table.productsQuantities = {};
        page.table.exists = false;

        this.updatePage(page);
    }

    /**
     * Require a current entity
     * Add annotations with a number inside of it, to the product selected in the scene
     */
    async addIndexedAnnotations() {
        const page = this.getPage();
        let selectedProduct = null;
        let addImage = false;
        const id = BuildingPlanHelper.selectionAnnotationId();
        if (this.context.modules.selectionManager.selectedMeshes.length === 1) {
            selectedProduct =
                this.context.modules.selectionManager.selectedMeshes[0].entity ||
                this.context.modules.selectionManager.selectedMeshes[0].originalMesh;
        } else {
            // multiple meshes selected => the clicked mesh is the first mesh of the multi selection
            selectedProduct = this.context.modules.selectionManager.selectedMeshes[0].entity;
            addImage = true; // we're gona add an image annotation
        }
        const selectedRef = selectedProduct.ref || selectedProduct.metadata.ref;
        const number = Object.keys(page.table.productsQuantities).indexOf(selectedRef) + 1;
        const position = page.scenePageMetadata.selectedProductsPointerPosition[selectedProduct.id];
        const annotation = {
            id,
            uuid: uuid(),
            number, // Number displayed inside the annotation
            annotationPosition: position.clone(),
            tipPosition: position.clone(),
            editing: true,
            childrenOffset: new Vector2(0, 0),
        };

        if (addImage) {
            await this.handleImageAnnotation(annotation);
            BuildingPlanHelper.restoreProductsVisibility(page);
        }
        page.annotations[id] = annotation;

        this.updatePage(page);
    }

    /**
     * Add a screenshot of the selected meshes to the annotation
     * Also child annotations are created for each different entity
     * in the picture
     * @param {Object} annotation
     */
    async handleImageAnnotation(annotation) {
        const page = this.getPage();
        const position = annotation.tipPosition.clone();
        const screenshotSize = 400;
        const actualImageSize = 200;
        const imageAnnotationsPositions = [];
        const cam = this.context.modules.cameraManager.controller.currentCamera;
        const references = [];
        // callback executed when the camera is at the right place to shoot all the meshes:
        // => get the best 2d positions to create the child annotations
        const callback = (meshList) => {
            meshList.forEach((mesh) => {
                // only one annotation per ref
                if (references.includes(mesh.entity.ref)) {
                    return;
                }
                references.push(mesh.entity.ref);
                let annotationPoint = null;
                const bb = mesh.getBoundingInfo().boundingBox;
                // fetch several points in the bounding box
                // to find one visible by the camera
                const potentialPoints = BuildingPlanController.subdivideBB(bb);
                const dist = 1.5 * Vector3.Distance(mesh.absolutePosition, cam.globalPosition);
                potentialPoints.some((point) => {
                    // cast a ray from the  camera to the point
                    // to check if it goes to the annoted mesh without hitting other meshes
                    const ray = new Ray(cam.globalPosition, point.subtract(cam.globalPosition).normalize(), dist);
                    const { pickedMesh, pickedPoint } = this.scene.pickWithRay(ray);
                    if (pickedMesh === mesh) {
                        annotationPoint = pickedPoint;
                        return true;
                    }
                    return false;
                });
                // if no point found, we use the center of the BoundingBox
                if (!annotationPoint) {
                    annotationPoint = bb.centerWorld;
                }
                imageAnnotationsPositions.push({
                    entity: mesh.entity,
                    position: Vector3.Project(
                        // project to 2d space
                        annotationPoint,
                        Matrix.Identity(),
                        this.scene.getTransformMatrix(),
                        cam.viewport,
                    ),
                });
            });
        };

        annotation.image = URL.createObjectURL(
            await this.context.modules.selectionManager.getCurrentSelectionScreenshot(
                false,
                { width: screenshotSize, height: screenshotSize },
                callback,
            ),
        );

        // compute their positions and create child annotations
        imageAnnotationsPositions.forEach(({ entity, position: imgPos }) => {
            const num = Object.keys(page.table.productsQuantities).indexOf(entity.ref) + 1;
            // tip position
            const posX = (imgPos.x - 0.5) * actualImageSize + position.x;
            const posY = (imgPos.y - 0.5) * actualImageSize + position.y;
            // annotation position : we follow the direction going from the center of the img
            // to the tip, until we reach the radius of the img
            // (the image is presented as a cicle thanks to css)
            const borderPos = new Vector2(posX, posY);
            const dir = borderPos.subtract(position).normalize().scale(5);
            while (Vector2.Distance(borderPos, position) < actualImageSize / 2) {
                borderPos.addInPlace(dir);
            }
            page.annotations[entity.id] = {
                id: entity.id,
                // Used for vue bind:key otherwise annotations have same id
                // and are not recreated when changing page
                uuid: uuid(),
                number: num,
                tipPosition: new Vector2(posX, posY),
                annotationPosition: borderPos,
                editing: false,
                parent: annotation,
            };
        });
    }

    /**
     * Require a current entity
     * Remove indexed annnotations on all the selected products
     */
    removeIndexedAnnotations() {
        const page = this.getPage();
        const id = BuildingPlanHelper.selectionAnnotationId();
        const childAnnotations = Object.values(page.annotations).filter(
            (annotation) => annotation.parent && annotation.parent === page.annotations[id],
        );

        childAnnotations.forEach((annotation) => {
            delete page.annotations[annotation.id];
        });

        delete page.annotations[id];

        this.updatePage(page);
    }

    /**
     * Add a text box on the page
     * @param {TEXT_SIZES} textSize
     */
    addText(textSize) {
        const page = this.getPage();

        page.texts[uuid()] = {
            text: this.context.modules.stonejs.gettext('Your text'),
            isFocused: false,
            settingArrow: false,
            sizeClass: textSize,
            position: Vector2.Zero(),
            arrows: [],
        };

        this.updatePage(page);
    }

    /**
     * Remove the focused text box from the page
     */
    removeText() {
        const page = this.getPage();

        const index = Object.keys(page.texts).find((key) => page.texts[key].isFocused);
        delete page.texts[index];

        this.updatePage(page);
    }

    addArrowToText() {
        const page = this.getPage();
        const index = Object.keys(page.texts).find((key) => page.texts[key].isFocused);
        const currentText = page.texts[index];
        currentText.arrows.push({
            id: uuid(),
            start: Vector2.Zero(),
            end: Vector2.Zero(),
            hovered: false,
        });
        currentText.settingArrow = true;
        this.updatePage(page);
    }

    /**
     * Add an image on the page
     * @param {string} binaryImageContent
     */
    addImage(binaryImageContent) {
        const page = this.getPage();

        page.userImages[uuid()] = {
            binaryImageContent,
            isFocused: false,
            width: 300, // Width is enough as the dom try to keep the image ratio
            position: Vector2.Zero(),
        };

        this.updatePage(page);
    }

    /**
     * Remove the focused image from the page
     */
    removeImage() {
        const page = this.getPage();

        const index = Object.keys(page.userImages).find((key) => page.userImages[key].isFocused);
        delete page.userImages[index];

        this.updatePage(page);
    }

    /**
     * @param {BoundingBox} bb
     * @returns {Vector3[]}
     */
    static subdivideBB(bb, nbSub = 4) {
        const points = [];
        const start = bb.minimumWorld;
        const end = bb.maximumWorld;
        const subX = (end.x - start.x) / nbSub;
        const subY = (end.y - start.y) / nbSub;
        const subZ = (end.z - start.z) / nbSub;
        for (let x = start.x + subX; x < end.x; x += subX) {
            for (let y = start.y + subY; y < end.y; y += subY) {
                for (let z = start.z + subZ; z < end.z; z += subZ) {
                    points.push(new Vector3(x, y, z));
                }
            }
        }
        points.sort((p1, p2) => Vector3.DistanceSquared(p1, bb.centerWorld) - Vector3.DistanceSquared(p2, bb.centerWorld));
        return points;
    }
}
