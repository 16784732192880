import GeometryUtility from './src/geometry-utility';

const MODULE_NAME = 'geometry-utility';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [],
    load(app) {
        window[APP_SYMBOL] = app;
        return GeometryUtility;
    },
    unload() {},
};
