import entityOption from './entity-option.vue';
import EntitiesData from '../../../../../entities-data';
import colorPicker from '../color-picker.vue';
import EventBus from '../../eventBus';
import RalColors from '../../../../option-manager/utility/ral-colors';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'BottomMenu',
    components: {
        entityOption: entityOption,
        colorPicker: colorPicker,
    },
    props: {
        selectionData: {
            type: Object,
            default: function () { return ({}); },
        },
        optionsData: {
            type: Object,
            default: function () { return ({}); },
        },
        gizmoData: {
            type: Object,
            default: function () { return ({}); },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            optionFamily: null,
            showColorPicker: false,
            baseplateController: null,
            screenController: null,
            shelfController: null,
            currentEntity: null,
            multiSelectedMesh: this.selectionData.multiSelectedMesh,
            coverPalette: RalColors.getColorCodes(),
            isExpanded: false,
            gizmoStates: null,
            selectionManager: null,
        };
    },
    computed: {
        isStraightFrame: function () {
            return this.currentEntity && this.currentEntity.subCategory.toUpperCase() === 'STRAIGHT FRAMES';
        },
        areStraightFrame: function () {
            return (this.multiSelectedMesh.length > 0 &&
                this.multiSelectedMesh.every(function (elt) { return elt.entity.subCategory.toUpperCase() === 'STRAIGHT FRAMES'; }));
        },
        isOptionable: function () {
            return (this.currentEntity &&
                this.currentEntity.isOptionable &&
                this.currentEntity.ref !== '618 0992 0992 MDF' &&
                this.currentEntity.subCategory !== 'GLASS FRAMES');
        },
        isInfillable: function () {
            return this.isOptionable && this.currentEntity.subCategory !== 'BETRUSS SQUARE'; // Temporary disabled
        },
        isBeTrussSquare: function () {
            return this.currentEntity && this.currentEntity.isOptionable && this.currentEntity.subCategory.includes('BETRUSS SQUARE');
        },
        areBeTrussSquare: function () {
            return (this.multiSelectedMesh.length > 0 &&
                this.multiSelectedMesh.every(function (elt) { return elt.entity.isOptionable && elt.entity.subCategory.includes('BETRUSS SQUARE'); }));
        },
        areOptionable: function () {
            return (this.multiSelectedMesh.length > 0 &&
                this.multiSelectedMesh.every(function (element) {
                    var subCategory = element.entity.subCategory;
                    var result = element.entity.isOptionable && element.entity.ref !== '618 0992 0992 MDF' && subCategory !== 'GLASS FRAMES';
                    return result;
                }));
        },
        areColorable: function () {
            return this.multiSelectedMesh.every(function (mesh) { return mesh.entity.isColorable; });
        },
        areInfillable: function () {
            return (this.multiSelectedMesh.length > 0 &&
                this.multiSelectedMesh.every(function (element) {
                    return element.entity.isOptionable &&
                        element.entity.ref !== '618 0992 0992 MDF' &&
                        !element.entity.subCategory.includes('BETRUSS SQUARE');
                }));
        },
        isNotConnector: function () {
            return this.currentEntity && this.currentEntity.__name__ !== 'connector';
        },
        noMultiSelectedConnectors: function () {
            return (this.multiSelectedMesh.length > 0 && !this.multiSelectedMesh.some(function (element) { return element.entity.__name__ === 'connector'; }));
        },
        showContainer: function () {
            return this.noMultiSelectedConnectors;
        },
        canHaveOptions: function () {
            return this.currentEntity.canHaveOptions;
        },
        canAllHaveLightsOption: function () {
            if (this.currentEntity) {
                return this.currentEntity.canHaveLightsOption;
            }
            return this.multiSelectedMesh.length > 0 && this.multiSelectedMesh.every(function (elt) { return elt.entity.canHaveLightsOption; });
        },
        canAllHaveShelfOption: function () {
            if (this.currentEntity) {
                return this.currentEntity.canHaveShelfOption;
            }
            return this.multiSelectedMesh.length > 0 && this.multiSelectedMesh.every(function (elt) { return elt.entity.canHaveShelfOption; });
        },
        canAllHaveScreenOption: function () {
            if (this.currentEntity) {
                return this.currentEntity.canHaveScreenOption;
            }
            return this.multiSelectedMesh.length > 0 && this.multiSelectedMesh.every(function (elt) { return elt.entity.canHaveScreenOption; });
        },
        canAllHaveBaseplatesOption: function () {
            if (this.currentEntity) {
                return this.currentEntity.canHaveBaseplatesOption;
            }
            return this.multiSelectedMesh.length > 0 && this.multiSelectedMesh.every(function (elt) { return elt.entity.canHaveBaseplatesOption; });
        },
        canAllHaveGroundplatesOption: function () {
            if (this.currentEntity) {
                return this.currentEntity.canHaveGroundplatesOption;
            }
            return this.multiSelectedMesh.length > 0 && this.multiSelectedMesh.every(function (elt) { return elt.entity.canHaveGroundplatesOption; });
        },
        canHaveScreens: function () {
            return this.currentEntity.canHaveScreenOption;
        },
        validSwapObject: function () {
            return EntitiesData.entityTypeFromSubCategory(this.currentEntity.subCategory, this.currentEntity.ref);
        },
        validMovableObject: function () {
            return this.isNotConnector || this.noMultiSelectedConnectors;
        },
        groundplateTooltipContent: function () {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.groundplates.length === 0) {
                return this._('Add groundplate');
            }
            return this._('Edit groundplate');
        },
        baseplateTooltipContent: function () {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.baseplates.length === 0) {
                return this._('Add baseplate');
            }
            return this._('Edit baseplate');
        },
        ligthTooltipContent: function () {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.lights.length === 0) {
                return this._('Add light');
            }
            return this._('Edit light');
        },
        shelfTooltipContent: function () {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.shelves.length === 0) {
                return this._('Add shelf');
            }
            return this._('Edit shelf');
        },
        screenTooltipContent: function () {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.screens.length === 0) {
                return this._('Add TV brackets');
            }
            return this._('Edit TV brackets');
        },
        infillTooltipContent: function () {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.infills.length === 0) {
                return this._('Add infill');
            }
            return this._('Edit infill');
        },
    },
    watch: {
        optionFamily: function (val) {
            var nbSelectObject = this.selectionManager.getSelectedObjectsLength();
            if (val) {
                if (val !== this.optionsData.optionsFamilies.INFILL && nbSelectObject > 1) {
                    return;
                }
                EventBus.$emit('option-menu-open', val);
            }
            else {
                EventBus.$emit('option-menu-close');
            }
        },
    },
    created: function () {
        var optionController = window[APP_SYMBOL].modules.optionManager.optionController;
        this.baseplateController = optionController.baseplateController;
        this.screenController = optionController.screenBracketController;
        this.shelfController = optionController.shelfController;
        this.selectionManager = window[APP_SYMBOL].modules.selectionManager;
        this.gizmoStates = window[APP_SYMBOL].modules.gizmoManager.gizmoStates;
    },
    mounted: function () {
        var _this = this;
        window[APP_SYMBOL].events.on('selectOption', function (type) {
            _this.addOption(type);
        });
        EventBus.$on('toggle-groups', function (open) {
            _this.isExpanded = !open;
        });
        window[APP_SYMBOL].events.on('@group-manager.selectionChanged', function () {
            _this.onEntitySelected();
        });
        window[APP_SYMBOL].events.on('@selection-manager.select-entity-scene', function () {
            _this.onEntitySelected();
        });
        window[APP_SYMBOL].events.on('@selection-manager.unselect-entity-scene', function () {
            _this.onEntitySelected();
        });
    },
    methods: {
        /**
         * Initialize entity data on entity selected
         */
        onEntitySelected: function () {
            this.optionFamily = null;
            this.showColorPicker = false;
            this.baseplateController.availableBaseplates();
            this.screenController.updateMaxScreenNumber();
            this.shelfController.updateMaxShelfNumber();
            if (this.multiSelectedMesh.length === 1 && this.multiSelectedMesh[0].entity.__name__ !== 'connector') {
                this.currentEntity = this.multiSelectedMesh[0].entity;
            }
            else {
                this.currentEntity = null;
            }
        },
        /**
         * Shows the position gizmo
         */
        toggleMoveGizmo: function () {
            this.selectionManager.showSelectedMeshPositionGizmo();
        },
        /**
         * Shows the rotation gizmo
         */
        toggleRotateGizmo: function () {
            this.selectionManager.showSelectedMeshRotationGizmo();
        },
        /**
         * Shows the duplication sprite
         */
        toggleDuplicateGizmo: function () {
            this.selectionManager.toggleDuplication();
        },
        /**
         * Shows the swapping gizmo
         */
        toggleReplaceGizmo: function () {
            this.selectionManager.deactivateGizmo();
            this.selectionManager.removeControlToCurrentEntity();
            if (this.currentGizmo !== this.gizmoStates.SCALE) {
                this.selectionManager.showSelectedMeshScaleGizmo();
            }
        },
        /**
         * Show the option menu according to the given option to the current entity
         * @param {Number} type - The type of option
         */
        addOption: function (type) {
            switch (type) {
                case this.optionsData.optionsFamilies.LIGHT:
                    this.addLight();
                    break;
                case this.optionsData.optionsFamilies.BASEPLATE:
                    this.addBaseplate();
                    break;
                case this.optionsData.optionsFamilies.GROUNDPLATE:
                    this.addGroundplate();
                    break;
                case this.optionsData.optionsFamilies.SHELF:
                    this.addShelf();
                    break;
                case this.optionsData.optionsFamilies.SCREEN:
                    this.addScreen();
                    break;
                case this.optionsData.optionsFamilies.INFILL:
                    this.addInfill();
                    break;
                default:
            }
        },
        /**
         * Set the current optionFamily as infill
         */
        addInfill: function () {
            this.showColorPicker = false;
            if (!this.optionFamily || this.optionFamily !== this.optionsData.optionsFamilies.INFILL) {
                this.optionFamily = this.optionsData.optionsFamilies.INFILL;
            }
            else {
                this.optionFamily = null;
            }
        },
        /**
         * Set the current optionFamily as light
         */
        addLight: function () {
            this.showColorPicker = false;
            if (!this.optionFamily || this.optionFamily !== this.optionsData.optionsFamilies.LIGHT) {
                this.optionFamily = this.optionsData.optionsFamilies.LIGHT;
            }
            else {
                this.optionFamily = null;
            }
        },
        /**
         * Set the current optionFamily as shelf
         */
        addShelf: function () {
            this.showColorPicker = false;
            if (!this.optionFamily || this.optionFamily !== this.optionsData.optionsFamilies.SHELF) {
                this.optionFamily = this.optionsData.optionsFamilies.SHELF;
            }
            else {
                this.optionFamily = null;
            }
        },
        /**
         * Set the current optionFamily as baseplate
         */
        addBaseplate: function () {
            this.showColorPicker = false;
            if (!this.optionFamily || this.optionFamily !== this.optionsData.optionsFamilies.BASEPLATE) {
                this.optionFamily = this.optionsData.optionsFamilies.BASEPLATE;
                EventBus.$emit('enabledBaseplatesOptions');
            }
            else {
                this.optionFamily = null;
            }
        },
        addGroundplate: function () {
            this.showColorPicker = false;
            if (!this.optionFamily || this.optionFamily !== this.optionsData.optionsFamilies.GROUNDPLATE) {
                this.optionFamily = this.optionsData.optionsFamilies.GROUNDPLATE;
                EventBus.$emit('enabledGroundplatesOptions');
            }
            else {
                this.optionFamily = null;
            }
        },
        /**
         * Set the current optionFamily as screen
         */
        addScreen: function () {
            this.showColorPicker = false;
            if (!this.optionFamily || this.optionFamily !== this.optionsData.optionsFamilies.SCREEN) {
                this.optionFamily = this.optionsData.optionsFamilies.SCREEN;
            }
            else {
                this.optionFamily = null;
            }
        },
        /**
         * Show the colorPicker
         */
        toggleColorPicker: function () {
            this.showColorPicker = !this.showColorPicker;
            this.optionFamily = null;
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
