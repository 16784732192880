import { APP_SYMBOL } from '../../..';
import { IMAGE_MODES } from '../../../../building-plan-manager/src/controller/building-plan-image-controller';
import EventBus from '../../eventBus';
export default {
    props: {
        userImageData: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
            localUserImageData: this.userImageData,
            dragging: false,
            resizing: false,
            canvasContainer: null,
            startOffsetX: 0,
            startOffsetY: 0,
            draggingVectorX: 0,
            draggingVectorY: 0,
            imageElement: null,
            imageContainerElement: null,
        };
    },
    mounted: function () {
        var _this = this;
        this.imageContainerElement = this.$refs['image-container'];
        this.imageContainerElement.style.left = this.userImageData.position.x;
        this.imageContainerElement.style.top = this.userImageData.position.y;
        this.imageElement = this.$refs.image;
        this.imageElement.width = this.userImageData.width;
        this.canvasContainer = document.getElementById('canvas-container');
        EventBus.$on('user-image-focus', this.onOtherUserImageFocus);
        // Wait the image to be rendered
        this.$nextTick(function () {
            _this.imageRatio = _this.imageElement.naturalWidth / _this.imageElement.naturalHeight;
        });
    },
    beforeUnmount: function () {
        this.blur();
        EventBus.$off('user-image-focus', this.onOtherUserImageFocus);
    },
    methods: {
        onOtherUserImageFocus: function (targetElement) {
            if (targetElement !== this.$el) {
                this.onClickOutside();
            }
        },
        onClickOutside: function () {
            this.blur();
            this.bpController.imageController.captureImage(IMAGE_MODES.HTML);
        },
        addClickOutsideListeners: function () {
            this.canvasContainer.addEventListener('click', this.onClickOutside);
            window[APP_SYMBOL].events.on('@obsidian-engine.unselect', this.onClickOutside);
        },
        removeClickOutsideListeners: function () {
            this.canvasContainer.removeEventListener('click', this.onClickOutside);
            window[APP_SYMBOL].events.removeListener('@obsidian-engine.unselect', this.onClickOutside);
        },
        focus: function () {
            if (!this.userImageData.isFocused) {
                this.localUserImageData.isFocused = true;
                EventBus.$emit('user-image-focus', this.$el);
                // Not done on template side because mousedown shadows click event
                this.imageElement.addEventListener('mousedown', this.dragStart);
                this.imageElement.addEventListener('mouseup', this.dragEnd);
                this.addClickOutsideListeners();
            }
        },
        blur: function () {
            if (this.localUserImageData.isFocused) {
                this.localUserImageData.isFocused = false;
                this.imageElement.removeEventListener('mousedown', this.dragStart);
                this.imageElement.removeEventListener('mouseup', this.dragEnd);
                this.removeClickOutsideListeners();
            }
            if (this.dragging) {
                this.dragEnd();
            }
            if (this.resizing) {
                this.resizeEnd();
            }
        },
        // Dragging
        dragStart: function (event) {
            this.dragging = true;
            this.userImageData.isFocused = true;
            // Initial offset on the drag handle
            this.startOffsetX = event.offsetX;
            this.startOffsetY = event.offsetY;
            document.body.addEventListener('mousemove', this.drag);
        },
        drag: function (event) {
            var offsetX = this.canvasContainer.offsetLeft + this.startOffsetX;
            var offsetY = this.canvasContainer.offsetTop + this.startOffsetY;
            // Subtract the canvas offset in the window + the drag handle initial offset from
            // the mouse position in the window
            var positionX = event.clientX - offsetX;
            var positionY = event.clientY - offsetY;
            this.imageContainerElement.style.left = positionX;
            this.imageContainerElement.style.top = positionY;
            this.localUserImageData.position.x = positionX;
            this.localUserImageData.position.y = positionY;
        },
        dragEnd: function () {
            document.body.removeEventListener('mousemove', this.drag);
            this.dragging = false;
            this.startOffsetX = 0;
            this.startOffsetY = 0;
        },
        // Resizing
        resizeStart: function (_a) {
            var directionX = _a.directionX, directionY = _a.directionY;
            document.body.addEventListener('mousemove', this.resize);
            document.body.addEventListener('mouseup', this.resizeEnd);
            this.removeClickOutsideListeners();
            this.resizing = true;
            this.draggingVectorX = directionX;
            this.draggingVectorY = directionY;
        },
        // The element resized is the
        resize: function (event) {
            var heightBeforeResize = this.imageElement.height;
            var xSign = Math.sign(this.draggingVectorX);
            // Resizing on the X axis
            if (this.draggingVectorX < 0) {
                // Image grow to the left
                var newLeft = Number.parseInt(this.imageContainerElement.style.left, 10) + event.movementX;
                this.imageContainerElement.style.left = "".concat(newLeft, "px");
            }
            this.imageElement.width += xSign * event.movementX;
            // Resizing on the Y axis
            if (this.draggingVectorY < 0) {
                // Image grow to the top
                var heightDiff = heightBeforeResize - this.imageElement.height;
                var newTop = Number.parseInt(this.imageContainerElement.style.top, 10) + heightDiff;
                this.imageContainerElement.style.top = "".concat(newTop, "px");
            }
            this.localUserImageData.width = this.imageElement.width;
        },
        resizeEnd: function () {
            document.body.removeEventListener('mousemove', this.resize);
            document.body.removeEventListener('mouseup', this.resizeEnd);
            setTimeout(this.addClickOutsideListeners, 0);
            this.resizing = false;
        },
    },
};
