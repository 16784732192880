import EntityStructure from './entity-structure';
import OptionableMixin from '../../../option-manager/model/optionable-mixin';
import Entity from '../../../data-store/src/entity';

const BeTrussSquareStructure = EntityStructure.$extend({
    __name__: 'betruss-square',

    __init__(params) {
        this.$super(params);
        this.initOptions();
    },

    __include__: [OptionableMixin.prototype],
});
Entity.$register(BeTrussSquareStructure);
export default BeTrussSquareStructure;
