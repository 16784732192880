import self from '../..';
import LambdaConverter from '../converter/lambda-converter';
import LambdaParamsFactory from '../factory/lambda-params-factory';
import AWSLambda from 'aws-sdk/clients/lambda';

export default class LambdaClient {
    constructor() {
        this.lambdaClient = null;
        this.initializeLambdaClient();
    }

    async invokeConnectors(becadXMLBase64) {
        const lambdaParams = LambdaParamsFactory.buildLambdaParamsForConnectors(becadXMLBase64);

        const data = await this.lambdaClient.invoke(lambdaParams).promise();

        if (typeof JSON.parse(data.Payload) !== 'string') {
            self.app.log.error(`Can't generate connectors from lambda : ${data.Payload}`);
            return null;
        }
        const lambdaXML = LambdaConverter.decodeFromBase64(data.Payload);
        const blobXml = new Blob([lambdaXML], { type: 'text/xml' });

        return blobXml;
    }

    async invokeInfills(becadXMLBase64) {
        const lambdaParams = LambdaParamsFactory.buildLambdaParamsForInfills(becadXMLBase64);

        const data = await this.lambdaClient.invoke(lambdaParams).promise();

        if (typeof JSON.parse(data.Payload) !== 'string') {
            self.app.log.error(`Can't generate infills from lambda : ${data.Payload}`);
            return null;
        }
        const lambdaXML = LambdaConverter.decodeFromBase64(data.Payload);
        const blobXml = new Blob([lambdaXML], { type: 'text/xml' });

        return blobXml;
    }

    async invokeCovers(becadXMLBase64) {
        const lambdaParams = LambdaParamsFactory.buildLambdaParamsForCovers(becadXMLBase64);

        const data = await this.lambdaClient.invoke(lambdaParams).promise();

        if (typeof JSON.parse(data.Payload) !== 'string') {
            self.app.log.error(`Can't generate covers from lambda : ${data.Payload}`);
            return null;
        }
        const lambdaXML = LambdaConverter.decodeFromBase64(data.Payload);
        const blobXml = new Blob([lambdaXML], { type: 'text/xml' });

        return blobXml;
    }

    async invokePanelPlan(becadXMLBase64) {
        const lambdaParams = LambdaParamsFactory.buildLambdaParamsForPanelPlan(becadXMLBase64);

        const data = await this.lambdaClient.invoke(lambdaParams).promise();

        if (typeof JSON.parse(data.Payload) !== 'string') {
            self.app.log.error(`Can't generate panel plan from lambda : ${data.Payload}`);
            return null;
        }

        const lambdaDXF = LambdaConverter.decodeFromBase64(data.Payload);
        const blobDXF = new Blob([lambdaDXF], { type: 'image/vnd.dxf' });

        return blobDXF;
    }

    /**
     * Initiliaze the client connection to Amazon S3 service
     */
    initializeLambdaClient() {
        const lambdaSettings = {
            accessKeyId: 'AKIA2GRWVLLB2GSMNPN3',
            secretAccessKey: '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',
            region: 'eu-west-2',
            correctClockSkew: true, // linked to https://git.wanadev.org/be-matrix/3D/issues/595
        };

        if (!this.lambdaClient) {
            this.lambdaClient = new AWSLambda(lambdaSettings);
        }
    }
}
