/**
 * Patch to version 1.32.0
 *
 * The purpose of this patch is to fix data structures of version 1.31.x
 * Those data sturtures serialized, lightboxex and flooring connectors in the null data path.
 * This is due to the new serialized data names to not being part of the product entities array.
 * The fix just take the entities in the invalid path and put them in the correct one.
 */

import PatchHelper from '../helper/patch-helper';
import { APP_SYMBOL } from '../..';

export default {
    /**
     * Main patch function
     * Put the entities in the path into the correct path
     *
     * Broken data structure example
     * {
     *      "data-path1": [
     *          serialized-item1,
     *          serialized-item2
     *      ],
     *      "data-path2": [
     *          serialized-item3,
     *          serialized-item4
     *      ],
     *      "null" : [
     *          serialized-item5,
     *          serialized-item6
     *      ]
     *  }
     *
     * Post fix data structure example
     * {
     *      "data-path1": [
     *          serialized-item1,
     *          serialized-item2
     *          serialized-item5,
     *      ],
     *      "data-path2": [
     *          serialized-item3,
     *          serialized-item4
     *          serialized-item6
     *      ],
     *  }
     * @param {ObsidianFile} project
     * @returns {ObsidianFile}
     */
    patch(project) {
        const projectSerializedData = project.project;
        if (projectSerializedData.null) {
            projectSerializedData.null.forEach((entity) => {
                const EntityHelper = window[APP_SYMBOL].modules.entityManager.Helper;
                const newPath = EntityHelper.getEntityPath(entity);

                if (!newPath) {
                    PatchHelper.patchFailed(project, `Entity : ${entity.__name__} must not belong to path 'null'`);
                }

                if (!projectSerializedData[newPath]) {
                    projectSerializedData[newPath] = [];
                }

                projectSerializedData[newPath].push(entity);
            });

            delete projectSerializedData.null;
        }

        return project;
    },
};
