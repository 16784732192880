import InitUtility from './src/init-utility';

const MODULE_NAME = 'init-utility';
export const APP_SYMBOL = Symbol(MODULE_NAME);

/**
 * Setup stuff of the project that doesn't rely to a particular module
 */
export default {
    name: MODULE_NAME,
    requires: ['iframe-api', 'stonejs'],
    load(app) {
        window[APP_SYMBOL] = app;
        InitUtility.setContext(app);
        InitUtility.init();
        return InitUtility;
    },
    unload() {},
};
