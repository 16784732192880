import RightsHelper from 'helpers/rights-helper';
import KeyboardJs from 'keyboardjs';

const PERMISSIONS = {
    OTHER: 0,
    PUBLIC: 1,
};
/**
 * Used for binding functions or this.context.events to keyboard this.context.events
 */
export default class KeyboardEventHandler {
    constructor(context) {
        this.context = context;
        // For each combination of keys used in the app we set a callback when the combination is
        // pressed and a callback whent the combiantion is released

        this.lockKeyboardEvents = false;

        this.KeyboardEventsMap = [
            {
                keys: 'modifier',
                callbackPressed: this.onModifierPressed,
                callbackReleased: this.onModifierReleased,
            },
            {
                keys: 'delete',
                callbackPressed: this.onDeletePressed,
                buildingPlanCallbackPressed: this.onBuildingPlanDeletePressed,
            },
            {
                keys: 'esc',
                callbackPressed: this.onEscPressed,
                buildingPlan: true,
                permission: PERMISSIONS.PUBLIC,
            },
            {
                keys: 'modifier + z',
                callbackPressed: this.onUndoPressed,
            },
            {
                keys: 'modifier + y',
                callbackPressed: this.onRedoPressed,
            },
            {
                keys: process.env.NODE_ENV === 'development' ? 'modifier + d' : 'modifier + c',
                callbackPressed: this.onCopyPressed,
            },
            {
                keys: 'modifier + m',
                callbackPressed: this.onCopyAndMovePressed,
            },
            {
                keys: 'modifier + v',
                callbackPressed: this.onPastePressed,
            },
            {
                keys: 'modifier + r',
                callbackPressed: this.onCopyAndRotatePressed,
            },
            {
                keys: 'modifier + s',
                callbackPressed: this.onCopyAndScalePressed,
            },
            {
                keys: 'm',
                callbackPressed: this.onMovePressed,
            },
            {
                keys: 'n',
                callbackPressed: this.onSnappingModePressed,
            },
            {
                keys: 'r',
                callbackPressed: this.onRotatePressed,
            },
            {
                keys: 'q',
                callbackPressed: () => this.onRotate90Pressed(0),
            },
            {
                keys: 'modifier + q',
                callbackPressed: () => this.onRotate45Pressed(0),
            },
            {
                keys: 'shift + q',
                callbackPressed: () => this.onRotate90Pressed(1),
            },
            {
                keys: 'shift + modifier + q',
                callbackPressed: () => this.onRotate45Pressed(1),
            },
            {
                keys: 'alt + q',
                callbackPressed: () => this.onRotate90Pressed(2),
            },
            {
                keys: 'alt + modifier + q',
                callbackPressed: () => this.onRotate45Pressed(2),
            },
            {
                keys: 's',
                callbackPressed: this.onScalePressed,
            },
            {
                keys: 'd',
                callbackPressed: this.onDuplicatePressed,
            },
        ];

        this.context.events.on('@bematrix-ui.popin-opened', () => {
            this.lockKeyboardEvents = true;
        });

        this.context.events.on('@bematrix-ui.popin-closed', () => {
            this.lockKeyboardEvents = false;
        });

        this.initKeyboardEvent();
    }

    // Bind the keyboard map defined in the constructor with KeyboardJS
    initKeyboardEvent() {
        const conditionFun = (kbEvent) =>
            (kbEvent.permission === PERMISSIONS.PUBLIC || !RightsHelper.isModePublic()) &&
            (kbEvent.buildingPlan || !RightsHelper.isModeBuildingPlan());

        this.KeyboardEventsMap.forEach((keyboardEvent) => {
            KeyboardJs.bind(
                keyboardEvent.keys,
                // pressed function and conditions
                (e) => {
                    if (this.lockKeyboardEvents) {
                        // Useful when we don't want shortcuts usable
                        return;
                    }

                    // Avoid to lose the default functioning of single keys
                    if (keyboardEvent.keys.includes('modifier +')) {
                        e.preventDefault();
                    }
                    if (conditionFun(keyboardEvent)) {
                        keyboardEvent.callbackPressed();
                    } else if (RightsHelper.isModeBuildingPlan() && typeof keyboardEvent.buildingPlanCallbackPressed === 'function') {
                        keyboardEvent.buildingPlanCallbackPressed();
                    }
                },
                // release function and conditions
                (e) => {
                    if (this.lockKeyboardEvents) {
                        // Useful when we don't want shortcuts usable
                        return;
                    }

                    // Avoid to lose the default functioning of single keys
                    if (keyboardEvent.keys.includes('modifier +')) {
                        e.preventDefault();
                    }
                    if (conditionFun(keyboardEvent) && keyboardEvent.callbackReleased) {
                        keyboardEvent.callbackReleased();
                    }
                },
            );
        });
    }

    onModifierPressed = () => {
        this.context.events.emit('modifierPressed');
    };

    onModifierReleased = () => {
        this.context.events.emit('modifierReleased');
    };

    onDeletePressed = () => {
        this.context.events.emit('delete');
    };

    onBuildingPlanDeletePressed = () => {
        this.context.events.emit('building-plan-delete');
    };

    onEscPressed = () => {
        this.context.events.emit('cancel');
    };

    onSnappingModePressed = () => {
        this.context.events.emit('snapping-mode');
    };

    onUndoPressed = () => {
        this.context.events.emit('undo');
    };

    onRedoPressed = () => {
        this.context.events.emit('redo');
    };

    onCopyPressed = () => {
        this.context.events.emit('copy');
    };

    onCopyAndMovePressed = () => {
        this.context.events.emit('copyAndMove');
    };

    onCopyAndRotatePressed = () => {
        this.context.events.emit('copyAndRotate');
    };

    onCopyAndScalePressed = () => {
        this.context.events.emit('copyAndReplace');
    };

    onPastePressed = () => {
        this.context.events.emit('paste');
    };

    onMovePressed = () => {
        this.context.events.emit('move');
    };

    onRotatePressed = () => {
        this.context.events.emit('rotate');
    };

    onRotate90Pressed = (axis) => {
        this.context.events.emit('rotate90', axis);
    };

    onRotate45Pressed = (axis) => {
        this.context.events.emit('rotate45', axis);
    };

    onScalePressed = () => {
        this.context.events.emit('scale');
    };

    onDuplicatePressed = () => {
        this.context.events.emit('duplicate');
    };
}
