/* eslint no-bitwise: 0 */
import { SCREENSHOT_PIPELINES } from '../../../camera-manager/src/controller/screenshot-controller';
import BuildingPlanHelper from '../helper/building-plan-helper';

import { Camera } from '@babylonjs/core';

export const IMAGE_MODES = {
    SCENE: 1,
    HTML: 2,
    ALL: 3,
};

export default class BuildingPlanImageController {
    constructor(parentController, context) {
        this.context = context;
        this.parentController = parentController;
        this.outputImagesScale = 3;
        this.maxWidth = 3840; // no more than 4K screenshots
        this.boundedOutputImageScale = this.outputImageScale;
        let firstTransparentScreenshot = true;

        this.imageModeCaptureFunction = {
            SCENE: () => {
                const engineWidth = this.context.modules.cameraManager.screenshotController.engine.getRenderWidth();
                const engineHeight = this.context.modules.cameraManager.screenshotController.engine.getRenderHeight();
                const ratio = engineWidth / engineHeight;
                const width = Math.min(engineWidth * this.outputImagesScale, this.maxWidth);
                const height = width / ratio;
                this.boundedOutputImageScale = width / engineWidth;
                if (firstTransparentScreenshot && this.context.modules.materialManager.areFramesTransparent) {
                    // For the first render with transparent materials, prevents first buggy screenshot
                    firstTransparentScreenshot = false;
                    return this.context.modules.cameraManager.screenshotController
                        .screenshot(SCREENSHOT_PIPELINES.DEFAULT, null, { width: 1, height: 1 }, 'image/jpeg')
                        .then(() =>
                            this.context.modules.cameraManager.screenshotController.screenshot(
                                SCREENSHOT_PIPELINES.DEFAULT,
                                null,
                                {
                                    width,
                                    height,
                                },
                                'image/jpeg',
                            ),
                        );
                }
                return this.context.modules.cameraManager.screenshotController.screenshot(
                    SCREENSHOT_PIPELINES.DEFAULT,
                    null,
                    {
                        width,
                        height,
                    },
                    'image/jpeg',
                );
            },
            HTML: () =>
                this.context.modules.cameraManager.ScreenshotHelper.getScreenshotFromHtml(
                    document.getElementById('pdf-components-container'),
                    this.boundedOutputImageScale,
                    true,
                ),
        };
        this.temporaryMeasurements = [];
    }

    /**
     * Capture the current state of the page and store it into the PDF page image array
     * Image mode allow some control over what the capture will contain
     *
     * /!\ This function doesn't add the image to the JSPDF instance
     *
     * @param { IMAGE_MODES = IMAGE_MODES.ALL } imageMode
     */
    captureImage(imageMode) {
        this.context.events.emit('create-preview', imageMode);

        // Delay function execution to allow vue update
        setTimeout(() => {
            if (imageMode === IMAGE_MODES.NONE) {
                return;
            }

            const page = this.parentController.getPage();
            const imagesPromises = [];
            const imageModesNames = Object.keys(IMAGE_MODES);
            const imageModesValues = Object.values(IMAGE_MODES);

            if (
                imageMode === IMAGE_MODES.SCENE &&
                this.context.modules.materialManager.areFramesTransparent &&
                this.context.modules.optionManager.optionController.showInfills &&
                !page.scenePageMetadata.connectorSelectionModeEnabled
            ) {
                // We set the frames transparency back
                // because infills shown but transparent are not an available
                // combination in building plan mode when editing page
                this.context.modules.materialManager.setFramesTransparency(false);
            }

            const engineWidth = this.context.modules.cameraManager.screenshotController.engine.getRenderWidth();
            const engineHeight = this.context.modules.cameraManager.screenshotController.engine.getRenderHeight();

            for (let i = 0; i < imageModesNames.length; i += 1) {
                const imageModeName = imageModesNames[i];
                const imageModeFlag = imageModesValues[i];

                if ((imageMode & imageModeFlag) === imageModeFlag) {
                    const imagePromise = this.imageModeCaptureFunction[imageModeName]().then((image) => {
                        page.images[imageModeName] = {
                            ratio: engineHeight / engineWidth,
                            base64: image,
                        };
                        page.camera = {
                            alpha: this.context.modules.cameraManager.controller.currentCamera.alpha,
                            beta: this.context.modules.cameraManager.controller.currentCamera.beta,
                            radius: this.context.modules.cameraManager.controller.currentCamera.radius,
                            target: {
                                x: this.context.modules.cameraManager.controller.currentCamera.target.x,
                                y: this.context.modules.cameraManager.controller.currentCamera.target.y,
                                z: this.context.modules.cameraManager.controller.currentCamera.target.z,
                            },
                        };
                    });
                    imagesPromises.push(imagePromise);
                }
            }

            Promise.all(imagesPromises)
                .then(() => {
                    if ((imageMode & IMAGE_MODES.SCENE) === IMAGE_MODES.SCENE) {
                        this.parentController.isCameraFixed = true;
                        this.context.modules.cameraManager.controller.activateCamera(false);
                        this.context.modules.cameraManager.controller.disableOrthoZoom();

                        page.scenePageMetadata.cameraMode = this.context.modules.cameraManager.controller.currentCamera.mode;
                        if (this.context.modules.cameraManager.controller.currentCamera.mode === Camera.ORTHOGRAPHIC_CAMERA) {
                            page.scenePageMetadata.orthoZoomStart = this.context.modules.cameraManager.controller.orthoZoomStart;
                        }
                        if (
                            this.context.modules.materialManager.areFramesTransparent &&
                            !this.context.modules.optionManager.optionController.showInfills
                        ) {
                            // Activate connector selection mode instead
                            // (infills are explicitly hidden before taking screenshot so
                            // they will be kept hidden after)
                            page.scenePageMetadata.connectorSelectionModeEnabled = true;
                            this.context.events.emit('toggle-connector-selection', true);
                        }
                        page.scenePageMetadata.activeProducts = BuildingPlanHelper.getActiveProductsReferences();
                        page.scenePageMetadata.productsVisibility = BuildingPlanHelper.getProductVisibility();
                        page.scenePageMetadata.measurements = this.temporaryMeasurements;

                        this.temporaryMeasurements = [];
                        this.context.modules.selectionManager.unselectAll();
                    }
                    this.parentController.updatePage(page);
                })
                .finally(() => {
                    this.context.events.emit('preview-created', imageMode);
                });
        });
    }

    /**
     * Remove a given image type from the current page
     * @param { IMAGE_MODES = IMAGE_MODES.ALL } imageMode
     */
    removeImage(imageMode) {
        const page = this.parentController.getPage();
        const imageModesNames = Object.keys(IMAGE_MODES);
        const imageModesValues = Object.values(IMAGE_MODES);

        for (let i = 0; i < imageModesNames.length; i += 1) {
            const imageModeName = imageModesNames[i];
            const imageModeFlag = imageModesValues[i];

            if ((imageMode & imageModeFlag) === imageModeFlag) {
                page.images[imageModeName] = null;
            }
        }

        if ((imageMode & IMAGE_MODES.SCENE) === IMAGE_MODES.SCENE) {
            page.scenePageMetadata.activeProducts = null;

            this.context.modules.selectionManager.unselectAll();
        }

        this.parentController.updatePage(page);
    }

    /**
     * Returns the current page images
     * @param {Number} pageNumber optional
     * @returns { images: (HTML, SCENE), editing }
     */
    getPageImages(pageNumber) {
        const page = this.parentController.getPage(pageNumber);

        return Object.values(page.images).filter((image) => Boolean(image));
    }

    /**
     * Does the page have atleast one image saved
     * @param {Number} pageNumber optional
     * @returns {Boolean}
     */
    pageHasImages(pageNumber) {
        return this.getPageImages(pageNumber).length > 0;
    }
}
