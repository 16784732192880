import CatalogController from './src/catalog-controller';

const MODULE_NAME = 'catalog-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['request-manager', 'mesh-manager', 'material-manager', 'iframe-api'],
    load(app) {
        window[APP_SYMBOL] = app;
        return new CatalogController(app);
    },
    unload() {},
};
