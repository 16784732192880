import { CollisionBoundingSphereDebug } from './collision-debug';
import defaultConfig from 'defaultConfig';
import { Vector3 } from '@babylonjs/core';

import gjk from 'collision-gjk-epa';

// How much the bounding boxes a down scaled when testing collisions
const BB_DOWN_SCALE_SIZE = defaultConfig.step - 0.001;
const DEBUG_COLLISIONS = false;

/**
 * Reference special collisions cases and store there index in the SPECAIL_REFERENCES_REGEX array
 */
const SPECIAL_COLLISIONS_CASES = {
    CURVED_1488: 0,
};

/**
 * Allow us to find which references must be treated as special cases
 */
const SPECIAL_REFERENCES_REGEX = [];
SPECIAL_REFERENCES_REGEX[SPECIAL_COLLISIONS_CASES.CURVED_1488] = /688 1488 \d* 30/;

/**
 * How we handle the special cases for the down scale step of collisions testing
 */
const SPECIAL_CASES_DOWN_SCALE_SIZE = [];
SPECIAL_CASES_DOWN_SCALE_SIZE[SPECIAL_COLLISIONS_CASES.CURVED_1488] = defaultConfig.step * 2 - 0.001;

let _context = null;
export default class CollisionHelper {
    static setContext(context) {
        _context = context;
    }

    /**
     * Check if the mesh is under the and return the reponse on the Y axis if asked for
     * @param {*} entity
     * @param {boolean} respond
     * @returns {boolean}
     */
    static isUnderGrid(object, options = { isEntity: false, respond: false, epsilonGrid: 0 }) {
        const worldSubColliders = CollisionHelper.getObjectBestBBs(object, options.isEntity);
        const epsilonGrid = options.epsilonGrid ? options.epsilonGrid : 0;
        if (worldSubColliders.some((bb) => bb.some((vec) => vec.y < -0.001 + epsilonGrid))) {
            if (options.respond) {
                const mesh = options.isEntity ? object.mesh : object;
                const repulsionY = CollisionHelper.computeGridRepulsion(worldSubColliders);
                mesh.position.y += -repulsionY;
            }
            return true;
        }

        return false;
    }

    /**
     * Call this function only if the mesh is atleast partially under the ground
     * @param {*[]} worldBBs mesh colliders in world space
     * @returns {number} the repulsion on the Y axis to put the mesh just above the grid
     */
    static computeGridRepulsion(worldBBs) {
        // Get the bb lowest Y value
        // As we use a reduce on BBs if there is only one we must not reduce
        if (worldBBs.length === 1) {
            return worldBBs[0].reduce((vec1, vec2) => Vector3.Minimize(vec1, vec2)).y;
        }
        // First get all the BBs lowest Y value
        return (
            worldBBs
                .map((bb) => bb.reduce((vec1, vec2) => Vector3.Minimize(vec1, vec2)).y)
                // Then reduce those lowest Y to find the lowest across all BBs
                .reduce((a, b) => Math.min(a, b))
        );
    }

    static computeGridRepulsionMeshList(meshes) {
        const arrayBBs = meshes.map((mesh) => CollisionHelper.getObjectBestBBs(mesh));
        return arrayBBs.map((bb) => CollisionHelper.computeGridRepulsion(bb)).reduce((a, b) => Math.min(a, b));
    }

    /**
     * Returns true if the mesh collide with another mesh
     * Check collisions between meshToTest and all the mesh registered in the scene
     * that got collisions activated
     * In case of intersection
     * We check if either colliding or collided mesh got subColliders
     * When there is a collision we check collisions again using these subColliders if there are any
     * @param {Mesh} meshToTest
     * @param {*[]} staticEntities
     * @return {boolean}
     */
    static checkStaticCollisionsWithMesh(meshToTest, meshRef, staticEntities) {
        // Duplicate moving mesh bounding box
        // and down scale it a bit to allow snap and duplication
        const movingMeshBB = CollisionHelper.downScaledBB(
            meshToTest.getBoundingInfo().boundingBox,
            meshToTest.getWorldMatrix(),
            CollisionHelper.getDownScaleSizeWithRef(meshRef),
        );

        for (let i = 0; i < staticEntities.length; i += 1) {
            const { mesh } = staticEntities[i];
            const staticMeshBB = CollisionHelper.downScaledBB(
                staticEntities[i].getBoundingBox(),
                mesh.getWorldMatrix(),
                CollisionHelper.getDownScaleSizeWithRef(staticEntities[i].ref),
            );

            // Only test for collisions
            if (mesh.checkCollisions && gjk.isIntersecting(movingMeshBB, staticMeshBB)) {
                // Check if any of the mesh got subColliders
                if (CollisionHelper.havePreciseBoundingBox(meshToTest) || CollisionHelper.havePreciseBoundingBox(mesh)) {
                    // Does the meshes collides with each other sub BBs
                    if (CollisionHelper.checkSubCollidersCollisions(meshToTest, mesh, false)) {
                        return CollisionHelper.onIntersection(meshToTest, mesh);
                    }
                } else {
                    return CollisionHelper.onIntersection(meshToTest, mesh);
                }
            }
        }
        return CollisionHelper.onIntersection(meshToTest, null);
    }

    /**
     * Returns true if the entity collide with another entity
     * Check collisions between meshToTest and all the mesh registered in the scene
     * that got collisions activated
     * In case of intersection
     * We check if either colliding or collided mesh got subColliders
     * When it gots we check collisions again using these subColliders
     * @param {Mesh} movingMesh
     * @param {Mesh[]} staticEntities
     * @return {boolean}
     */
    static checkStaticCollisionsWithEntity(entityToTest, staticEntities) {
        if (DEBUG_COLLISIONS) {
            CollisionBoundingSphereDebug.reset();
        }

        // Duplicate moving mesh bounding box
        // and down scale it a bit to allow snap and duplication
        const meshToTest = entityToTest.mesh;
        const movingMeshBB = CollisionHelper.downScaledBB(
            entityToTest.getBoundingBox(),
            meshToTest.getWorldMatrix(),
            CollisionHelper.getDownScaleSizeWithRef(entityToTest.ref),
        );

        // debug with adding sphere mesh to the scene
        if (DEBUG_COLLISIONS) {
            CollisionBoundingSphereDebug.addDebugSphere(meshToTest);
        }

        for (let i = 0; i < staticEntities.length; i += 1) {
            if (staticEntities[i].id !== entityToTest.id) {
                const staticMesh = staticEntities[i].mesh;
                const staticMeshBB = CollisionHelper.downScaledBB(
                    staticEntities[i].getBoundingBox(),
                    staticMesh.getWorldMatrix(),
                    CollisionHelper.getDownScaleSizeWithRef(staticEntities[i].ref),
                );

                if (DEBUG_COLLISIONS) {
                    CollisionBoundingSphereDebug.addDebugSphere(staticMesh);
                }

                const isBoundingSphereColliding = CollisionHelper.isBoundingSphereColliding(
                    meshToTest.boundingSphere,
                    staticMesh.boundingSphere,
                );
                if (isBoundingSphereColliding) {
                    if (DEBUG_COLLISIONS) {
                        CollisionBoundingSphereDebug.updateToCollideColor([meshToTest, staticMesh]);
                    }
                    // Only test for collisions
                    if (staticMesh.checkCollisions && gjk.isIntersecting(movingMeshBB, staticMeshBB)) {
                        // Check if any of the mesh got subColliders
                        if (CollisionHelper.havePreciseBoundingBox(meshToTest) || CollisionHelper.havePreciseBoundingBox(staticMesh)) {
                            // Does the meshes collides with each other sub BBs
                            if (CollisionHelper.checkSubCollidersCollisions(entityToTest, staticEntities[i], true)) {
                                return CollisionHelper.onIntersection(meshToTest, staticMesh);
                            }
                        } else {
                            return CollisionHelper.onIntersection(meshToTest, staticMesh);
                        }
                    }
                }
            }
        }
        return CollisionHelper.onIntersection(meshToTest, null);
    }

    static isBoundingSphereColliding(bs1, bs2) {
        const distance = bs1.centerWorld.subtract(bs2.centerWorld).length();
        return distance <= bs1.radius + bs2.radius;
    }

    /**
     * Check collisions between the two meshes using
     * the more accurate BBs of each mesh
     * @param {Mesh} meshColliding
     * @param {Mesh} meshCollided
     * @param {boolean} areEntities = false
     * @returns {boolean}
     */
    static checkSubCollidersCollisions(objectColliding, objectCollided, areEntities = false) {
        const objectCollidingBBs = CollisionHelper.getObjectBestBBs(objectColliding, areEntities);
        const objectCollidedBBs = CollisionHelper.getObjectBestBBs(objectCollided, areEntities);

        for (let i = 0; i < objectCollidingBBs.length; i += 1) {
            for (let j = 0; j < objectCollidedBBs.length; j += 1) {
                if (gjk.isIntersecting(objectCollidingBBs[i], objectCollidedBBs[j])) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Log collision informations on debug mode
     * Set the collidingMesh, collidedMesh property
     * @param {Mesh} collidingMesh
     * @param {Mesh} collidedMesh
     * @return {boolean}
     */
    static onIntersection(collidingMesh, collidedMesh) {
        if (DEBUG_COLLISIONS) {
            if (collidingMesh && collidedMesh) {
                _context.log.info('intersection between', collidingMesh.name, 'and', collidedMesh.name);
            }
        }
        collidingMesh.collidedMesh = collidedMesh;
        return Boolean(collidedMesh);
    }

    /**
     * Knowing that two meshes collide, find the vector to repulse
     * one of them from the other so they don't collide anymore
     * @param {Mesh} movingMesh
     * @param {Mesh} staticMesh
     */
    static findAndApplyResponse(movingMesh, staticMesh) {
        if (movingMesh && staticMesh) {
            const movingMeshBox = movingMesh.getBoundingInfo().boundingBox.vectorsWorld;
            const staticMeshBox = staticMesh.getBoundingInfo().boundingBox.vectorsWorld;
            const penetrationVector = gjk.intersect(staticMeshBox, movingMeshBox);
            if (penetrationVector) {
                movingMesh.position.addInPlace(penetrationVector);
                movingMesh.computeWorldMatrix(true);
            }
        }
    }

    /**
     * Toggle collisions for a meshes array
     * @param {Mesh[]} meshes meshes to toggle collisions on
     * @param {boolean} activateCollisions
     */
    static toggleCollisions(meshes, activateCollisions) {
        meshes.forEach((mesh) => {
            mesh.checkCollisions = activateCollisions;
        });
    }

    static havePreciseBoundingBox(mesh) {
        if (mesh.subCollidersBB && mesh.subCollidersBB.length) {
            return true;
        }
        return false;
    }

    /**
     * Return an object most accurate bounding boxes points
     * If the object have subColliders those will be returned
     * Otherwise we just return the mesh BB points
     * @param {*} object
     * @param {boolean} isEntity
     */
    static getObjectBestBBs(object, isEntity = false) {
        let objectBBs = [];
        const mesh = isEntity ? object.mesh : object;
        const worldMatrix = mesh.getWorldMatrix();

        if (CollisionHelper.havePreciseBoundingBox(mesh)) {
            mesh.subCollidersBB.forEach((bb) => {
                const bbIndex = objectBBs.push([]) - 1;
                for (let i = 0; i < bb.length; i += 1) {
                    objectBBs[bbIndex].push(Vector3.TransformCoordinates(bb[i], worldMatrix));
                }
            });
        } else if (isEntity) {
            objectBBs = [object.getBoundingBox().vectorsWorld];
        } else {
            objectBBs = [mesh.getBoundingInfo().boundingBox.vectorsWorld];
        }
        return objectBBs;
    }

    /**
     * Down scale @bb vectors using @downScaleFactors
     * @param {BoundingBox} bb
     * @param {number} downScaleSize
     */
    static downScaledBB(bb, worldMatrix, downScaleSize) {
        const downScaleFactors = CollisionHelper._computeDownScaleFactors(bb, downScaleSize);

        return bb.vectors.map((point) =>
            Vector3.TransformCoordinates(point.subtract(bb.center).multiply(downScaleFactors).add(bb.center), worldMatrix),
        );
    }

    /**
     * Compute the factors corresponding to a down scale of @size on each the bounding box axis
     * @param {BoundingBox} bb the bb containing the object to down scale size on each axis
     * @param {number} size the size we want to remove on each dimensions
     */
    static _computeDownScaleFactors(bb, size) {
        const objectFullSize = bb.extendSize.scale(2);

        // Divide object downscaled dimensions with object current dimensions
        return objectFullSize.subtract(new Vector3(size, size, size)).divide(objectFullSize);
    }

    /**
     * Only use this function to know how much we must down scale the object BB
     * This function take special cases of references into account
     * @return {number}
     */
    static getDownScaleSizeWithRef(ref) {
        const idx = Object.values(SPECIAL_COLLISIONS_CASES).find((index) => ref.search(SPECIAL_REFERENCES_REGEX[index]) !== -1);
        if (idx >= 0) {
            return SPECIAL_CASES_DOWN_SCALE_SIZE[idx];
        }
        return BB_DOWN_SCALE_SIZE;
    }
}
