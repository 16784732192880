import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const PopinStructure = EntityStructure.$extend({
    __name__: 'popin',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(PopinStructure);
export default PopinStructure;
