import RemoveButton from '../buttons/remove-button.vue';
import SwapButton from '../buttons/swap-button.vue';
export default {
    name: 'OptionBaseplate',
    components: {
        RemoveButton: RemoveButton,
        SwapButton: SwapButton,
    },
    props: {
        config: {
            type: Object,
            default: function () { return ({}); },
        },
        currentBaseplateType: {
            type: Object,
            default: function () { return ({}); },
        },
        baseplateOptions: {
            type: Array,
            default: function () { return []; },
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
        showSwap: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        addOption: function (type) {
            this.$emit('addOption', type);
            this.$forceUpdate();
        },
        removeOption: function () {
            this.$emit('removeOption');
            this.$forceUpdate();
        },
        swapOption: function () {
            this.$emit('swapOption');
            this.$forceUpdate();
        },
    },
};
