import product from './catalog-product.vue';
import EventBus from '../../eventBus';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'Catalog',
    components: { product: product },
    props: {
        catalog: {
            type: Array,
            default: function () { return []; },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            currentProducts: [],
            currentCategory: null,
            currentSubcategory: null,
            currentlyHoveredSubcategory: null,
            initializing: false,
            subcategoryActiveIcon: '/assets/icons/select.svg',
            subcategoryInactiveIcon: '/assets/icons/un-select.svg',
            products: null,
        };
    },
    computed: {
        categoriesToDisplay: function () {
            return this.catalog.filter(function (categories) { return categories.showInCatalog; });
        },
        subcategoriesToDisplay: function () {
            return this.categoriesToDisplay[this.currentCategory].groups.filter(function (subcategories) { return subcategories.showInCatalog; });
        },
    },
    mounted: function () {
        var _this = this;
        this.products = window[APP_SYMBOL].modules.catalogManager.products;
        EventBus.$on('hide-products', function () {
            _this.hideProducts();
        });
        window[APP_SYMBOL].events.on('@selection-manager.end-initialization', function () {
            _this.onEndInitializing();
        });
        // TODO : temporary to avoid left menu overlapping on bottom menu
        window[APP_SYMBOL].events.on('@selection-manager.select-entity', function () {
            if (!_this.initializing) {
                _this.hideProducts();
            }
        });
        window[APP_SYMBOL].events.on('@project-manager.init-group-object', function () {
            _this.onInitializing();
            window[APP_SYMBOL].events.once('@project-manager.end-init-group-object', function () {
                _this.onEndInitializing();
            });
        });
    },
    methods: {
        /**
         * Show the subcategories of the given category
         */
        toggleSubcategories: function (category) {
            this.hideProducts();
            this.currentCategory = this.currentCategory === category ? null : category;
        },
        thumbnailPath: function (productRef) {
            return "".concat(this.config.thumbnailsUrl, "/").concat(this.products[productRef].thumbnailUrl);
        },
        /**
         * Show the products of the given category and subgategory
         */
        displayProducts: function (category, subcategory) {
            window[APP_SYMBOL].modules.selectionManager.unselectAll(); // TODO : temporary to avoid left menu
            // overlapping on bottom menu
            this.currentSubcategory = subcategory;
            this.currentProducts = this.showableProducts();
        },
        hideProducts: function () {
            this.currentProducts = [];
            this.currentSubcategory = null;
        },
        onInitializing: function () {
            this.initializing = true;
        },
        onEndInitializing: function () {
            this.initializing = false;
        },
        // Return the product that must be displayed
        showableProducts: function () {
            var products = this.subcategoriesToDisplay[this.currentSubcategory].products;
            return products.filter(function (currentProduct) { return currentProduct.showInCatalog; });
        },
        isCurrentCategory: function (categoryIndex) {
            return categoryIndex === this.currentCategory;
        },
        isCurrentSubcategory: function (subcategoryIndex) {
            return subcategoryIndex === this.currentSubcategory;
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
