import SnappingController from './controllers/snapping-controller';
import SnappingHelper from './helpers/snapping-helper';

const MODULE_NAME = 'snapping-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['obsidian-engine', 'material-manager', 'history', 'selection-manager', 'collision-manager'],
    load(app) {
        window[APP_SYMBOL] = app;
        SnappingHelper.setContext(app);
        return new SnappingController(app);
    },
    unload() {},
};
