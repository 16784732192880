import EngineController from './src/controllers/engine-controller';
import EngineHelper from './src/helpers/engine-helper';

const MODULE_NAME = 'obsidian-engine';
export const APP_SYMBOL = Symbol(MODULE_NAME);
export default {
    name: MODULE_NAME,
    requires: ['history'],
    load(app) {
        window[APP_SYMBOL] = app;
        return {
            controller: new EngineController(app),
            helper: EngineHelper,
        };
    },
    unload() {},
};
