import EventBus from '../eventBus';
import { debounce } from '../../../../helpers/js-helper';
import { APP_SYMBOL } from '../..';
import iro from '@jaames/iro';
export default {
    name: 'ColorPickerIro',
    data: function () {
        return {
            isVisible: false,
            currentColorObject: {
                value: '#000000',
            },
            picker: null,
        };
    },
    mounted: function () {
        this.picker = new iro.ColorPicker('#picker', {
            width: 128,
            color: '#f00',
            borderWidth: 1,
            borderColor: '#fff',
            layout: [
                {
                    component: iro.ui.Wheel,
                    options: {},
                },
                {
                    component: iro.ui.Slider,
                    options: {
                        sliderSize: 12,
                    },
                },
            ],
            layoutDirection: 'horizontal',
        });
        this.picker.on('color:change', debounce(this.changeColor, 1000));
        EventBus.$on('open-color-picker', this.show.bind(this));
        window[APP_SYMBOL].events.on('@selection-manager.unselect-all', this.hide.bind(this));
    },
    unmounted: function () {
        EventBus.$off('open-color-picker', this.show);
    },
    methods: {
        show: function (colorObject) {
            this.currentColorObject = colorObject;
            this.isVisible = true;
            this.picker.color.hexString = colorObject.value;
        },
        hide: function () {
            this.isVisible = false;
        },
        changeColor: function () {
            var isValidHexStr = /^#[0-9A-F]{6}$/i.test(this.picker.color.hexString);
            if (!isValidHexStr) {
                return;
            }
            this.currentColorObject.value = this.picker.color.hexString;
        },
    },
};
