import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const CoverStructure = EntityStructure.$extend({
    __name__: 'cover',

    __init__(params) {
        this.$super(params);
        this.coverThickness = 0.0085;
    },
});
Entity.$register(CoverStructure);
export default CoverStructure;
