import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const TrackLightStructure = EntityStructure.$extend({
    __name__: 'tracklight',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(TrackLightStructure);
export default TrackLightStructure;
