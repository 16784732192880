import BuildingPlanTable from './table.vue';
import Annotations from './annotations.vue';
import TextBoxes from './text-boxes.vue';
import UserImages from './user-images.vue';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'PdfHtmlContent',
    components: {
        BuildingPlanTable: BuildingPlanTable,
        Annotations: Annotations,
        TextBoxes: TextBoxes,
        UserImages: UserImages,
    },
    data: function () {
        return {
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
        };
    },
    computed: {
        is2DPage: function () {
            return this.bpController.getPage().type === PAGE_TYPES.HTML;
        },
        hasPage: function () {
            var _a;
            return Boolean((_a = this.bpController) === null || _a === void 0 ? void 0 : _a.getPage());
        },
        pageHasTable: function () {
            var page = this.bpController.getPage();
            return page.table && page.table.exists && Object.keys(page.table.productsQuantities).length;
        },
        pageHasAnnotations: function () {
            var page = this.bpController.getPage();
            return page && page.table && page.table.exists && Object.keys(page.annotations).length;
        },
        pageHasTextBoxes: function () {
            var page = this.bpController.getPage();
            return page && page.texts && Object.keys(page.texts).length;
        },
        pageHasUserImages: function () {
            var page = this.bpController.getPage();
            return page && page.userImages && Object.keys(page.userImages).length;
        },
    },
};
