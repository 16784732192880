import { Vector3 } from '@babylonjs/core';

import SerializableClass from 'abitbol-serializable/lib/SerializableClass';

SerializableClass.$addSerializer('babylon-vector3', {
    class: Vector3,

    serialize(vector3) {
        return {
            x: vector3.x,
            y: vector3.y,
            z: vector3.z,
        };
    },

    unserialize(data) {
        return new Vector3(data.x, data.y, data.z);
    },
});
