import AssetsController from './src/controllers/assets-controller';

const MODULE_NAME = 'assets-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [],
    load(app) {
        window[APP_SYMBOL] = app;
        return {
            controller: new AssetsController(app),
        };
    },
    unload() {},
};
