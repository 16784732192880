import DataStore from './src/data-store';

const MODULE_NAME = 'data-store';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [],

    config: {
        ignoreToSerializePaths: [],
        orderingUnserialByPath: ['/groups/default', '/groups'],
    },

    load(app) {
        window[APP_SYMBOL] = app;
        return new DataStore(app);
    },

    unload() {
        // pass
    },
};
