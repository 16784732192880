import config from 'defaultConfig';
import { Color3, Vector3, BoundingBox } from '@babylonjs/core';

let _context = null;
const SelectionHelper = {
    setContext(context) {
        _context = context;
    },

    checkRulesOfSelectedMesh(selectedMesh, checkRules = true) {
        const { collisionManager, highlightManager, gridManager } = _context.modules;
        let highlightColor = Color3.FromHexString(config.colors.default);
        if (checkRules) {
            highlightColor = Color3.FromHexString(config.colors.collisionOK);
            const isColliding = collisionManager.Controller.checkStaticCollisionsEntity(selectedMesh.entity);
            const isOutsideGrid = !gridManager.isBBInsideGrid(selectedMesh.getBoundingInfo().boundingBox);
            if (isColliding || isOutsideGrid) {
                highlightColor = Color3.FromHexString(config.colors.collisionKO);
            }
        }
        highlightManager.toggleHighlightMesh(selectedMesh, true, highlightColor);
    },

    /**
     * Add the behavior that makes the drag moving the product on the virtual grid
     * @param {PointerDragBehavior} dragBehavior
     */
    addVirtualGridBehavior(dragBehavior) {
        dragBehavior.onDragObservable.add((event) => {
            SelectionHelper.translateByStep(dragBehavior.attachedNode, event.delta);
        });
    },

    /**
     * Function that compute the translation of a mesh by taking account
     * of the step given in the default-config
     * @param {Mesh} mesh the mesh we are translating
     * @param {Vector3} delta the value of the current translation
     */
    translateByStep(mesh, delta) {
        const toAdd = new Vector3();
        if (Math.abs(delta.x) > config.step) {
            const dragSteps = Math.floor(Math.abs(delta.x) / config.step);
            const xAxisDelta = Vector3.Right().scale(Math.sign(delta.x));
            xAxisDelta.normalizeToRef(toAdd);
            toAdd.scaleInPlace(config.step * dragSteps);
            mesh.position.addInPlace(toAdd);
        }
        if (Math.abs(delta.z) > config.step) {
            const dragSteps = Math.floor(Math.abs(delta.z) / config.step);
            const zAxisDelta = Vector3.Forward().scale(Math.sign(delta.z));
            zAxisDelta.normalizeToRef(toAdd);
            toAdd.scaleInPlace(config.step * dragSteps);
            mesh.position.addInPlace(toAdd);
        }
        mesh.computeWorldMatrix(true);
    },

    /**
     * Return the bounds of selected meshes
     * @param {Array<Mesh>} selectedMeshes the selected meshes
     * @returns the global bounding box
     */
    getBoundsOfSelection(selectedMeshes) {
        const bb = selectedMeshes[0].entity.getBoundingBox();
        let min = bb.minimumWorld;
        let max = bb.maximumWorld;
        for (let i = 1; i < selectedMeshes.length; i++) {
            const bb2 = selectedMeshes[i].entity.getBoundingBox();
            min = Vector3.Minimize(min, bb2.minimumWorld);
            max = Vector3.Maximize(max, bb2.maximumWorld);
        }
        return new BoundingBox(min, max);
    },
};

export default SelectionHelper;
