import MeasurementStructure from '../models/measurement-structure';
import MeasurementWorldUi from '../world-ui/measurement-world-ui';
import MeasurementHelper from '../helpers/measurement-helper';
import VueHelper from 'helpers/vue-helper';
import RightsHelper from 'helpers/rights-helper';
import { Vector3, VertexBuffer } from '@babylonjs/core';

export default class MeasurementController {
    constructor(context) {
        this.context = context;
        VueHelper.AddVueProperty(this, 'measureModeActivated', false);

        this.context.events.on('@obsidian-engine.engine-ready', (scene) => {
            this.canvas = document.getElementById('main-canvas');
            this.scene = scene;
            this.onPointerDownEventHandler = this.onPointerDownCallback.bind(this);
            this.onPointerMoveEventHandler = this.onPointerMoveCallback.bind(this);
            this.canvas.addEventListener('pointerdown', this.onPointerDownEventHandler); // This can't be removed because used outside this mode
        });

        this.materialManager = this.context.modules.materialManager;

        this.selectedMeasurementEntity = null;
        this.selectableGizmos = [];
        this.selectedGizmo = null;
        this.hoveredGizmo = null;
        this.hoveredEntity = null;
        this.hoveredMesh = null;

        this.initEvents();
    }

    initEvents() {
        this.context.events.on('@data-store.entity-added', (entity) => {
            this.onEntityAdded(entity);
        });

        this.context.events.on('@data-store.entity-removed', (entity) => {
            this.onEntityRemoved(entity);
        });

        this.context.events.on('@obsidian-engine.delete', () => {
            if (RightsHelper.isModeBuildingPlan()) {
                return;
            }
            this.onDelete();
        });

        this.context.events.on('@building-plan-manager.measurement-delete', () => {
            this.onDelete();
        });

        // Events that unselect the current measurement world ui
        ['@obsidian-engine.unselect', '@obsidian-engine.undo', '@obsidian-engine.redo', '@obsidian-engine.drag-start'].forEach(
            (unselectEvent) => {
                this.context.events.on(unselectEvent, () => {
                    this.unselectCurrentMeasurementWorldUi();
                });
            },
        );

        // Events that deactivate the mode or cancel the current line
        this.context.events.on('@obsidian-engine.unselect', () => {
            if (this.measureModeActivated) {
                this.toggleMeasureMode();
            }
        });
    }

    dispose() {
        this.selectableGizmos.forEach((gizmo) => {
            gizmo.dispose();
        });
        this.selectableGizmos.length = 0;
        this.selectedGizmo?.dispose();
        this.hoveredGizmo?.dispose();
        this.selectedGizmo = null;
        this.hoveredGizmo = null;
    }

    onPointerDownCallback(event) {
        if (event.button !== 0) {
            // We handle only left click
            return;
        }
        const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        if (this.measureModeActivated) {
            if (this.hoveredGizmo) {
                if (this.hoveredGizmo === this.selectedGizmo) {
                    if (!this.selectableGizmos.includes(this.selectedGizmo)) {
                        // Remove previously selected gizmo if not in selectable gizmos
                        this.selectedGizmo.dispose();
                    }
                    // Unselect the hovered gizmo
                    this.selectedGizmo = null;
                } else if (this.selectedGizmo) {
                    // If there was a selected gizmo
                    this.createMeasurement(this.selectedGizmo.getAbsolutePosition(), this.hoveredGizmo.getAbsolutePosition());
                    this.selectedGizmo.dispose();
                    // Unselect the hovered gizmo
                    this.selectedGizmo = null;
                } else {
                    // Select the hovered gizmo
                    this.selectedGizmo = this.hoveredGizmo;
                }
            } else if (pickResult.pickedMesh?.id === 'main-ground') {
                const newPoint = pickResult.pickedPoint;
                if (this.selectedGizmo) {
                    this.createMeasurement(this.selectedGizmo.getAbsolutePosition(), newPoint);
                    this.selectedGizmo.dispose();
                    this.selectedGizmo = null;
                } else {
                    // Create a new measure point on ground
                    const newGizmo = MeasurementHelper.createMeasurePointGizmo(this.scene, this.measureDotMaterial, 0.06);
                    newGizmo.position = newPoint;
                    newGizmo.computeWorldMatrix(true);
                    this.selectedGizmo = newGizmo;
                    this.selectedGizmo.material = this.measureDotHoverMaterial;
                    this.selectableGizmos.push(newGizmo);
                }
            }
        } else {
            const spritePickResult = this.scene.pickSprite(this.scene.pointerX, this.scene.pointerY);
            const meshPredicate =
                pickResult.hit &&
                pickResult.pickedMesh &&
                (pickResult.pickedMesh.name.includes('cone') || pickResult.pickedMesh.name.includes('line'));
            const spritePredicate =
                spritePickResult.hit && spritePickResult.pickedSprite && spritePickResult.pickedSprite.name.includes('measurementSprite');
            if (meshPredicate) {
                this.onMeasurementObjetClicked(pickResult.pickedMesh.metadata.measurementObject);
            } else if (spritePredicate) {
                this.onMeasurementObjetClicked(spritePickResult.pickedSprite.metadata.measurementObject);
            }
        }
    }

    onPointerMoveCallback() {
        const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        let noneFound = true;
        if (pickResult.hit && pickResult.pickedMesh) {
            if (pickResult.pickedMesh.name === 'measurePointGizmo') {
                if (this.hoveredGizmo && this.hoveredGizmo !== this.selectedGizmo) {
                    this.hoveredGizmo.material = this.measureDotMaterial;
                }
                this.hoveredGizmo = pickResult.pickedMesh;
                this.hoveredGizmo.material = this.measureDotHoverMaterial;
                noneFound = false;
            } else if (this.selectedGizmo && pickResult.pickedMesh.id === 'main-ground' && this.hoveredMesh !== pickResult.pickedMesh) {
                // Show ground snap points
                this.hoveredMesh = pickResult.pickedMesh;
                this.selectableGizmos.push(...this.getMeasureGizmos(pickResult.pickedMesh));
            } else {
                const entity = pickResult.pickedMesh.entity ?? pickResult.pickedMesh.parent?.entity;

                if (entity && entity !== this.hoveredEntity) {
                    this.selectableGizmos.forEach((gizmo) => {
                        if (gizmo !== this.selectedGizmo) {
                            gizmo.dispose();
                        }
                    });
                    if (entity.meshInfos.hasEnvSnapping) {
                        this.selectableGizmos.push(...this.getMeasureGizmos(pickResult.pickedMesh));
                    } else {
                        this.selectableGizmos = this.getMeasureGizmos(entity.mesh);
                    }

                    this.hoveredEntity = entity;
                    this.hoveredMesh = pickResult.pickedMesh;
                    noneFound = false;
                }
            }
        }
        if (noneFound) {
            if (this.hoveredGizmo && this.hoveredGizmo !== this.selectedGizmo) {
                this.hoveredGizmo.material = this.measureDotMaterial;
            }
            this.hoveredGizmo = null;
            this.hoveredEntity = null;
        }
    }

    onDelete() {
        // Remove the selected measurement if exists
        if (this.selectedMeasurementEntity) {
            this.selectedMeasurementEntity.measurementObject.destroyMeasurementObject();
            this.context.modules.dataStore.removeEntity(this.selectedMeasurementEntity);
            if (RightsHelper.isModeBuildingPlan()) {
                this.context.modules.history.snapshot();
            }
            this.context.events.emit('measurement-destroyed', this.selectedMeasurementEntity);
            this.selectedMeasurementEntity = null;
        }
    }

    onMeasurementObjetClicked(measurableObject) {
        // Select the picked object (and unselect the last one if it exists)
        if (this.selectedMeasurementEntity) {
            this.selectedMeasurementEntity.measurementObject.getMeshes().forEach((mesh) => {
                this.context.modules.highlightManager.toggleHighlightMesh(mesh, false);
            });
        }
        this.selectedMeasurementEntity = measurableObject.entity;
        measurableObject.getMeshes().forEach((mesh) => {
            this.context.modules.highlightManager.toggleHighlightMesh(mesh, true);
        });
        this.context.events.emit('measurement-selected', this.selectedMeasurementEntity);
    }

    createMeasurement(startPoint, endPoint, entity = null) {
        const measurementObject = new MeasurementWorldUi(this.scene);
        measurementObject.startingPoint = startPoint;
        measurementObject.endingPoint = endPoint;
        measurementObject.drawLine();
        measurementObject.showMeasurement();
        let retEntity = entity;
        if (retEntity) {
            retEntity.measurementObject = measurementObject;
            measurementObject.entity = retEntity;
        } else {
            retEntity = new MeasurementStructure(startPoint, endPoint, measurementObject);
            measurementObject.entity = retEntity;
            this.context.modules.dataStore.addEntity(retEntity, '/measurements');
            if (!RightsHelper.isModeBuildingPlan()) {
                // In pdf mode measurements are temporary
                this.context.modules.history.snapshot();
            }
            this.context.events.emit('measurement-created', retEntity);
        }
        return retEntity;
    }

    unselectCurrentMeasurementWorldUi() {
        if (this.selectedMeasurementEntity) {
            this.selectedMeasurementEntity.measurementObject.getMeshes().forEach((mesh) => {
                this.context.modules.highlightManager.toggleHighlightMesh(mesh, false);
            });
            this.selectedMeasurementEntity = null;
        }
    }

    onEntityAdded(entity) {
        // If entity is a measurement entity
        if (entity.__name__.includes('measurement') && !entity.measurementObject) {
            this.createMeasurement(entity.startPoint, entity.endPoint, entity);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    onEntityRemoved(entity) {
        if (entity.__name__.includes('measurement') && entity.measurementObject) {
            entity.measurementObject.destroyMeasurementObject();
        }
    }

    toggleMeasureMode() {
        this.measureModeActivated = !this.measureModeActivated;
        if (!this.measureModeActivated) {
            this.context.modules.obsidianEngine.controller.eventHandler.mouse.enableMouseEvents(); // Enable scene interactions back
            this.canvas.removeEventListener('pointermove', this.onPointerMoveEventHandler);
            this.dispose();
            this.context.events.emit('measurement-mode-closed');
        } else {
            this.context.modules.obsidianEngine.controller.eventHandler.mouse.disableMouseEvents(); // Disable scene interactions (selection, drag...)
            this.canvas.addEventListener('pointermove', this.onPointerMoveEventHandler);
            this.context.events.emit('measurement-mode-opened');
        }
    }

    /**
     * Create measure gizmos from given mesh
     * @param {*} mesh
     */
    getMeasureGizmos(mesh) {
        const initGizmo = (posX, posY, posZ) => {
            if (this.selectedGizmo && this.selectedGizmo.parent === mesh && this.selectedGizmo.position.equalsToFloats(posX, posY, posZ)) {
                return this.selectedGizmo;
            }
            const newGizmo = MeasurementHelper.createMeasurePointGizmo(this.scene, this.measureDotMaterial, 0.06);
            newGizmo.parent = mesh;
            newGizmo.position = new Vector3(posX, posY, posZ);
            newGizmo.computeWorldMatrix(true);
            return newGizmo;
        };

        const gizmos = [];

        let magMeshes;
        if (mesh) {
            magMeshes = mesh.getChildMeshes(true, (magMesh) => magMesh.name.includes('MAGNETISM'));
            if (mesh.name.includes('MAGNETISM') || mesh.name.includes('ENV_SNAPPING')) {
                magMeshes.push(mesh);
            }
        } else {
            magMeshes = [mesh]; // Add ground snap points
        }

        const almostEqual = (a, b) => Math.abs(a - b) < 0.000001;
        const positions = [];
        magMeshes.forEach((magMesh) => {
            const pos = magMesh.getVerticesData(VertexBuffer.PositionKind);
            for (let i = 0; i < pos.length; i += 3) {
                if (
                    !positions.some(
                        (position) =>
                            almostEqual(position[0], pos[i]) &&
                            almostEqual(position[1], pos[i + 1]) &&
                            almostEqual(position[2], pos[i + 2]),
                    )
                ) {
                    positions.push([pos[i], pos[i + 1], pos[i + 2]]);
                    gizmos.push(initGizmo(pos[i], pos[i + 1], pos[i + 2]));
                }
            }
        });

        return gizmos;
    }

    get measureDotMaterial() {
        return this.materialManager.loadedMaterials['snapping-dot-material'];
    }

    get measureDotHoverMaterial() {
        return this.materialManager.loadedMaterials['hover-green-material'];
    }

    // /////// VISIBILITY PART ////////////////

    hideAll() {
        this.measurementEntities.forEach((entity) => {
            entity.measurementObject.hide();
        });
    }

    showAll() {
        this.measurementEntities.forEach((entity) => {
            entity.measurementObject.show();
        });
    }

    /*eslint-disable */
    get measurementEntities() {
        return this.context.modules.dataStore.listEntities('/measurements');
    }
    /* eslint-enable */
}
