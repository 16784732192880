import { Scene } from '@babylonjs/core';
import { AdvancedDynamicTexture } from '@babylonjs/gui';

export default class GuiController {
    constructor(context) {
        this.context = context;
        this.engineController = context.modules.obsidianEngine.controller;
        this.isReady = false;
        this.scene = null;

        if (this.engineController.scene && this.engineController.scene.activeCamera) {
            this.initGuiScene();
        } else {
            this.context.events.on('@camera-manager.camera-ready', () => {
                this.initGuiScene();
            });
        }
    }

    /**
     * Init a gui scene to render the gui in
     * We cannot render the gui into the main scene cause it gets occluded by gizmos
     * So we create this scene and render it last on top of everything
     */
    initGuiScene() {
        this.scene = new Scene(this.engineController.engine, { virtual: true });
        this.scene.detachControl();
        this.scene.activeCamera = this.engineController.scene.activeCamera;
        this.scene.autoClear = false;
        this.scene.autoClearDepthAndStencil = true;

        this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI('ui', true, this.scene);

        this.context.events.on('@bematrix-ui.canvas-resized', (size) => {
            this.guiTexture.scaleTo(size.width, size.height);
        });

        this.context.events.on('@obsidian-engine.main-scene-rendered', () => {
            this.render();
        });

        this.isReady = true;
        this.context.events.emit('gui-ready', this.scene);
    }

    addControl(control) {
        this.guiTexture.addControl(control);
    }

    render() {
        const activeCameraPreviousScene = this.scene.activeCamera.getScene();
        this.scene.activeCamera._scene = this.scene;

        this.scene.render(false);

        this.scene.activeCamera._scene = activeCameraPreviousScene;
    }
}
