import OptionableMixin from '../../../option-manager/model/optionable-mixin';
import { Quaternion, Vector3 } from '@babylonjs/core';

import { v4 as uuid } from 'uuid';

const scaleFactor = 0.001;

const refKeySeparator = '///';

const specialCoordinatesObjects = [];

let _context = null;
export default class BecadHelper {
    static setContext(context) {
        _context = context;
    }

    /**
     * Export all frames as XML structure inside tag @Parts
     * @param {*} framesEntities
     */
    static exportFrames(framesEntities, selectedFrames = []) {
        let parts = '<Parts>';
        parts += '<Part>';
        parts += BecadHelper.exportFloor();
        parts += '</Part>';
        framesEntities.forEach((entity) => {
            let entityIsSelected = false;
            if (selectedFrames.length) {
                entityIsSelected = framesEntities.find((frameEntity) => frameEntity.id === entity.id);
            }
            parts += BecadHelper.exportElementXML(entity, entityIsSelected);
        });

        parts += '</Parts>';
        return parts;
    }

    /**
     *  Export all connectors as XML structure inside tag @Connectors
     * @param {*} connectorsEntities
     */
    static exportConnectors(connectorsEntities) {
        if (connectorsEntities.length === 0) {
            return '<Connectors/>';
        }

        let connectors = '<Connectors>';
        connectorsEntities.forEach((connector) => {
            connectors += BecadHelper.exportElementXML(connector);
        });
        connectors += '</Connectors>';
        return connectors;
    }

    /**
     * Export the grid datas in XML format
     */
    static exportFloor() {
        const gridSize = _context.modules.gridManager.getGridSize();
        gridSize.width = Math.ceil(gridSize.width);
        gridSize.height = Math.ceil(gridSize.height);
        let floorXML = `<Name>Floor [${gridSize.width} X ${gridSize.height}M]:1</Name>`; // Supposed to be a unique name
        floorXML += `<PartNumber>Floor [${gridSize.width} X</PartNumber>`;
        floorXML += `<InventorPartNumber>Floor [${gridSize.width} X ${gridSize.height}M]</InventorPartNumber>`;
        floorXML += `<InventorPartLocation>99 Templates\\10_Floor\\Floor_V2\\Floor [${gridSize.width} X ${gridSize.height}M].ipt</InventorPartLocation>`;
        floorXML += '<IsSpecial>false</IsSpecial>';
        floorXML += '<Origin>';
        floorXML += '<X>0</X>';
        floorXML += '<Y>0</Y>';
        floorXML += '<Z>0</Z>';
        floorXML += '</Origin>';
        floorXML += '<XVec>';
        floorXML += '<X>1</X>';
        floorXML += '<Y>0</Y>';
        floorXML += '<Z>0</Z>';
        floorXML += '</XVec>';
        floorXML += '<YVec>';
        floorXML += '<X>0</X>';
        floorXML += '<Y>1</Y>';
        floorXML += '<Z>0</Z>';
        floorXML += '</YVec>';
        floorXML += '<ZVec>';
        floorXML += '<X>0</X>';
        floorXML += '<Y>0</Y>';
        floorXML += '<Z>1</Z>';
        floorXML += '</ZVec>';
        floorXML += '<Length>0</Length>';
        floorXML += '<ULWidthA>0</ULWidthA>';
        floorXML += '<ULWidthB>0</ULWidthB>';
        floorXML += '<ULWidthC>0</ULWidthC>';
        floorXML += '<ULMiddleWidth>0</ULMiddleWidth>';
        floorXML += `<RefKey>${BecadHelper.GenerateRefKey()}</RefKey>`;
        floorXML += '<Assembly></Assembly>';
        return floorXML;
    }

    /**
     * Export the entity's options
     * Loop through all the optionsFamilies and export the ones that exist in the entity
     */
    static exportOptions(entity) {
        let entityOptions = '';

        Object.values(_context.modules.optionManager.optionController.optionsFamilies).forEach((optionFamilyFlag) => {
            const optionMeshes = _context.modules.optionManager.optionController.getOptionMeshes(optionFamilyFlag, entity);

            if (optionMeshes) {
                let groupName = entity.group.default ? '' : entity.group.getName();

                // Avoid ampersand bug (&)
                // https://stackoverflow.com/questions/4026502/xml-error-at-ampersand
                groupName = groupName.replace('&', '&amp;');

                // This suffix store the option type for options that got one reference for
                // multiple options types
                // (i.e) in the case of SIMPLE or DOUBLE lights the reference is 250 05 50 S
                let optionType = '';
                const optionName = _context.modules.optionManager.optionController.getOptionFlagName(optionFamilyFlag);
                const swappedInt = entity.swappedOptions[optionName] ? 1 : 0;
                const optionSwapped = `${refKeySeparator}${swappedInt}`;
                if (optionFamilyFlag === _context.modules.optionManager.optionController.optionsFamilies.LIGHT) {
                    optionType = `${refKeySeparator}${entity.lightOption.toString()}`;
                    optionType += `${refKeySeparator}${entity.lightSingularity.toString()}`;
                }
                if (optionFamilyFlag === _context.modules.optionManager.optionController.optionsFamilies.INFILL) {
                    optionType = `${refKeySeparator}${entity.infillOption.toString()}`;
                    groupName += '_Panels';
                }

                optionMeshes.forEach((mesh) => {
                    let ref = _context.modules.optionManager.optionController.getOptionReference(optionFamilyFlag, entity);
                    // This allow us to get infills references for those who have one infill
                    // reference for each side of the frame
                    if (
                        optionFamilyFlag === _context.modules.optionManager.optionController.optionsFamilies.INFILL &&
                        entity.subCategory.toUpperCase() !== 'STRAIGHT FRAMES' &&
                        entity.category.toUpperCase() !== 'LIGHTBOXES' &&
                        entity.category.toUpperCase() !== 'MOTIONSKIN'
                    ) {
                        ref = mesh.id.slice(0, mesh.id.indexOf('-'));
                    }
                    // TODO: Remove this when shelf origins are corrected (just keep the else part)
                    let absPos;
                    if (mesh.name === 'shelf') {
                        const originalPos = mesh.position.clone();
                        mesh.position.x = 0;
                        mesh.computeWorldMatrix(true);
                        absPos = mesh.absolutePosition.clone();
                        mesh.position = originalPos;
                        mesh.computeWorldMatrix(true);
                    } else {
                        absPos = mesh.absolutePosition;
                    }
                    const catalogItem = _context.modules.catalogManager.products[ref];
                    const inventorPartLocation = catalogItem.partLocation.includes('.ipt')
                        ? catalogItem.partLocation
                        : `${catalogItem.partLocation}\\${ref}.ipt`;
                    entityOptions += '<Part>';
                    entityOptions += `<Name>${catalogItem.name}</Name>`;
                    entityOptions += `<PartNumber>${catalogItem.partNumber}</PartNumber>`;
                    entityOptions += `<InventorPartNumber>${catalogItem.inventorPartNumber}</InventorPartNumber>`;
                    entityOptions += `<InventorPartLocation>${inventorPartLocation}</InventorPartLocation>`;
                    entityOptions += '<IsSpecial>false</IsSpecial>';
                    entityOptions += `${BecadHelper.GenerateVector3(absPos.scale(1 / scaleFactor), 'Origin')}`;
                    entityOptions += `${BecadHelper.GenerateVector3(mesh.right, 'XVec', true)}`;
                    entityOptions += `${BecadHelper.GenerateVector3(mesh.up, 'YVec', true)}`;
                    entityOptions += `${BecadHelper.GenerateVector3(mesh.forward, 'ZVec', true)}`;
                    entityOptions += '<Length>0</Length>';
                    entityOptions += BecadHelper.handlePerfectCorners(entity);
                    entityOptions += '<RefKey>';
                    entityOptions += `${BecadHelper.GenerateRefKey()}${refKeySeparator}${entity.id}`;
                    entityOptions += `${optionSwapped}${optionType}`;
                    entityOptions += '</RefKey>';
                    entityOptions += `<Assembly>${groupName}</Assembly>`;
                    entityOptions += '</Part>';
                });
            }
        });

        return entityOptions;
    }

    /**
     * Function that export an element (connectors or frame) in XML
     * @param {*} entity
     */
    static exportElementXML(entity) {
        const catalogItem = _context.modules.catalogManager.products[entity.ref];
        if (!catalogItem || !entity.mesh) {
            return '';
        }
        const typeTag = entity.__name__ === 'connector' ? 'Connector' : 'Part';

        let groupName = entity.group.default ? '' : entity.group.getName();

        // Avoid ampersand bug (&)
        // https://stackoverflow.com/questions/4026502/xml-error-at-ampersand
        groupName = groupName.replace('&', '&amp;');

        let elementXML = `<${typeTag}>`;
        elementXML += `<Name>${catalogItem.name}</Name>`; // Supposed to be a unique name
        elementXML += `<PartNumber>${catalogItem.partNumber}</PartNumber>`;
        elementXML += `<InventorPartNumber>${catalogItem.inventorPartNumber}</InventorPartNumber>`;
        elementXML += `<InventorPartLocation>${catalogItem.partLocation}</InventorPartLocation>`;
        elementXML += '<IsSpecial>false</IsSpecial>';
        elementXML += `${BecadHelper.GenerateVector3(entity.mesh.absolutePosition.scale(1 / scaleFactor), 'Origin')}`;
        elementXML += `${BecadHelper.GenerateVector3(entity.mesh.right, 'XVec', true)}`;
        elementXML += `${BecadHelper.GenerateVector3(entity.mesh.up, 'YVec', true)}`;
        elementXML += `${BecadHelper.GenerateVector3(entity.mesh.forward, 'ZVec', true)}`;
        elementXML += BecadHelper.handleCoversLength(entity);
        elementXML += BecadHelper.handlePerfectCorners(entity);
        elementXML += `<RefKey>${entity.getId()}</RefKey>`;
        elementXML += `<Assembly>${groupName}</Assembly>`;
        elementXML += `</${typeTag}>`;

        if (entity.isOptionable) {
            elementXML += BecadHelper.exportOptions(entity);
        }

        return elementXML;
    }

    /**
     * Return a string containing informations about height of the cover
     * otherwise the will be set to 0
     * @param {*} entity
     * @return {string}
     */
    static handleCoversLength(entity) {
        // This is the only object that set the length component
        let height = 0;
        if (entity.category === 'PARTS' && entity.ref.includes(' ')) {
            const secondGroupText = entity.ref.split(' ')[1];
            const isNumberOnly = /^\d+$/.test(secondGroupText);
            if (isNumberOnly) {
                height = secondGroupText;
            }
        }

        return `<Length>${height}</Length>`;
    }

    /**
     * Complete the parameters used by beCad to generate connectors
     * on perfect corners
     * @param {*} entity
     */
    static handlePerfectCorners(entity) {
        let perfectCornersParameters = '';

        // Is this frame a perfect corner
        if (entity.subCategory === 'PERFECT CORNERS') {
            const frameDimensions = entity.ref.split(' ');
            const paddedSize = frameDimensions[1].padStart(4, '0');

            if (frameDimensions[0] === '690' || frameDimensions[0] === '670') {
                // Perfect L
                perfectCornersParameters += `<ULWidthA>${paddedSize}</ULWidthA>`;
                perfectCornersParameters += `<ULWidthB>${paddedSize}</ULWidthB>`;
                perfectCornersParameters += '<ULWidthC>0</ULWidthC>';
                perfectCornersParameters += '<ULMiddleWidth>0</ULMiddleWidth>';
            } else if (frameDimensions[0] === '691' || frameDimensions[0] === '671') {
                // Perfect U
                perfectCornersParameters += '<ULWidthA>0</ULWidthA>';
                perfectCornersParameters += `<ULWidthB>${paddedSize}</ULWidthB>`;
                perfectCornersParameters += `<ULWidthC>${paddedSize}</ULWidthC>`;
                perfectCornersParameters += '<ULMiddleWidth>0496</ULMiddleWidth>';
            }
        } else if (
            entity.subCategory === 'LEDSKIN' &&
            (entity.ref.includes('290 0496 0496') || entity.ref.includes('290 01 00 C') || entity.ref.includes('290 01 00 W'))
        ) {
            perfectCornersParameters += '<ULWidthA>496</ULWidthA>';
            perfectCornersParameters += '<ULWidthB>496</ULWidthB>';
            perfectCornersParameters += '<ULWidthC>0</ULWidthC>';
            perfectCornersParameters += '<ULMiddleWidth>0</ULMiddleWidth>';
        } else {
            perfectCornersParameters += '<ULWidthA>0</ULWidthA>';
            perfectCornersParameters += '<ULWidthB>0</ULWidthB>';
            perfectCornersParameters += '<ULWidthC>0</ULWidthC>';
            perfectCornersParameters += '<ULMiddleWidth>0</ULMiddleWidth>';
        }

        return perfectCornersParameters;
    }

    /**
     * Extract informations from each element and instantiate it
     * in the scene
     * @param {Array|Object|undefined} elements
     * @param {Function} instancingFunc function used to add the element to the scene
     * The function arguments are : a ref, a position, a quaternion, a group
     * @returns loading objects prromises
     */
    static importElements(elements, instancingFunc) {
        const elementsArray = BecadHelper.checkArrayElements(elements);
        const promises = {};

        elementsArray.forEach((elementInfos) => {
            const { ref } = elementInfos;
            const refKey = elementInfos.RefKey;
            // Floor handler
            if (elementInfos.InventorPartNumber.includes('Floor')) {
                const gridName = elementInfos.InventorPartNumber;
                BecadHelper.ParseGridDimension(gridName);
                return;
            }

            // Not tested before because ref is being modified for variable size ref
            // and is not needed for Floors

            if (!ref) {
                _context.log.error(
                    `InventorPartLocation not found in DB : ${elementInfos.InventorPartLocation}, ref : ${ref}, elementsInfos: ${elementInfos}`,
                );

                return;
            }

            if (refKey && refKey.includes(refKeySeparator)) {
                const parentEntityRefKey = refKey.split(refKeySeparator)[1];
                promises[parentEntityRefKey] = promises[parentEntityRefKey].then(BecadHelper.importOption(elementInfos));
                return;
            }

            const isSpecialObject = specialCoordinatesObjects.includes(ref);
            const groupName = elementInfos.Assembly;
            const position = BecadHelper.ParseVector3(elementInfos.Origin, false).scaleInPlace(0.001);
            const x = BecadHelper.ParseVector3(elementInfos.XVec, true, isSpecialObject);
            const y = BecadHelper.ParseVector3(elementInfos.YVec, true, isSpecialObject);
            const z = BecadHelper.ParseVector3(elementInfos.ZVec, true, isSpecialObject);
            const basis = Quaternion.RotationQuaternionFromAxis(x, y, z);
            promises[refKey] = instancingFunc(ref, position, basis, groupName, refKey);
        });

        if (Object.values(promises).length > 0) {
            return Promise.all(Object.values(promises));
        }
        return Promise.resolve(null);
    }

    /**
     * Add an option to the parent enity
     * @param {*} elementInfos info about the option
     */
    static importOption(elementInfos) {
        const refKey = elementInfos.RefKey;
        const { ref } = elementInfos;

        // This function will be chained to the parent frame loading
        return (struct) => {
            const optionInfo = _context.modules.optionManager.optionController.getOptionTypeFromReference(ref);
            let promise = null;
            const needSwap = parseInt(refKey.split(refKeySeparator)[2], 10) > 0;

            // If there is any get the option type at the end of the refKey
            // Only for LIGHT or INFILLS bc the reference is the same for both option types
            if (
                optionInfo.optionFamily === _context.modules.optionManager.optionController.optionsFamilies.LIGHT &&
                refKey.split(refKeySeparator)[3]
            ) {
                struct.lightOption = parseInt(refKey.split(refKeySeparator)[3], 10);
                struct.lightSingularity = parseInt(refKey.split(refKeySeparator)[4], 10);
            }

            // Variable number options
            if (
                optionInfo.optionFamily === _context.modules.optionManager.optionController.optionsFamilies.SCREEN &&
                optionInfo.optionType === OptionableMixin.SCREEN_OPTION.UNIVERSAL
            ) {
                struct.screenNumber += 1;
            } else if (optionInfo.optionFamily === _context.modules.optionManager.optionController.optionsFamilies.SHELF) {
                struct.shelfNumber += 1;
            } else if (optionInfo.optionFamily === _context.modules.optionManager.optionController.optionsFamilies.LIGHT) {
                struct.lightNumber += 1 / struct.lightSingularity;
            } else if (optionInfo.optionFamily === _context.modules.optionManager.optionController.optionsFamilies.INFILL) {
                optionInfo.optionType = parseInt(refKey.split(refKeySeparator)[3], 10);
            }

            // Refresh the parent frame options
            // Is in a promise because of the option mesh instantiation
            promise = _context.modules.optionManager.optionController
                .addOption(optionInfo.optionFamily, optionInfo.optionType, struct)
                .then(() => {
                    // Check if the options need to be swapped
                    if (_context.modules.optionManager.optionController.isSwappable(optionInfo.optionFamily, optionInfo.optionType)) {
                        if (needSwap) {
                            const familyName = _context.modules.optionManager.optionController.getOptionFlagName(optionInfo.optionFamily);
                            if (!struct.swappedOptions[familyName]) {
                                _context.modules.optionManager.optionController.swapOptionsSide(optionInfo.optionFamily, struct);
                            }
                        }
                    }
                    return struct;
                });
            return promise;
        };
    }

    /**
     * Check if the passed argument is an array
     * If not createan array with out of it
     * @param {Array|Object|undefined} elements
     * @returns {Array} array of one or more elements
     */
    static checkArrayElements(elements) {
        let elementsArray = elements;

        if (elementsArray) {
            if (!Array.isArray(elementsArray)) {
                elementsArray = [elementsArray];
            }
        } else {
            elementsArray = [];
        }

        return elementsArray;
    }

    /**
     * Parse the grid name to create a grid with the matching dimensions
     * @param {*} gridName name of the grid from the XML,
     * it contains dimensions of the grid
     */
    static ParseGridDimension(gridName) {
        const regex = /\d+(,\d+)?/g;
        const matched = gridName.match(regex);
        matched.forEach((match, index) => {
            if (match.includes(',')) {
                matched[index] = match.replace(',', '.');
            }
        });
        const width = Number.parseFloat(matched[0]);
        const depth = Number.parseFloat(matched[1]);
        _context.modules.gridManager.updateGrid({ width, height: depth }, false);
    }

    /**
     * Parse the JSON in argument to create a vector out of it
     * @param {Vector3} becadVector3
     * @param {Vector3} isDirection
     * @param {Vector3} isSpecial change the way we import direction vectors
     * @returns {Vector3}
     */
    static ParseVector3(becadVector3, isDirection, isSpecial) {
        if (isDirection) {
            if (isSpecial) {
                return new Vector3(
                    Number.parseFloat(-becadVector3.X),
                    Number.parseFloat(-becadVector3.Z),
                    Number.parseFloat(becadVector3.Y),
                );
            }
            return new Vector3(Number.parseFloat(becadVector3.X), Number.parseFloat(-becadVector3.Z), Number.parseFloat(becadVector3.Y));
        }

        return new Vector3(Number.parseFloat(-becadVector3.X), Number.parseFloat(becadVector3.Z), Number.parseFloat(-becadVector3.Y));
    }

    /**
     * When loading XML file returned from beCad lambda we have to rename the Connection.s tags by Connector.s
     * @param {*} str
     */
    static renameConnectionStringByConnector(str) {
        return str.replace(/Connection/g, 'Connector');
    }

    /**
     * @param {*} str
     */
    static removeAutoClosedConnectors(str) {
        return str.replace(/<Connectors\/>/g, '');
    }

    /**
     * Create a string containing a XML version of the vector3
     * @param {Vector3} babylonVector3
     * @param {string} tagName
     * @returns {string}
     */
    static GenerateVector3(babylonVector3, tagName, isDirection = false) {
        let xmlVector3 = `<${tagName}>`;
        if (isDirection) {
            xmlVector3 += `<X>${Number.parseFloat(babylonVector3.x.toFixed(6))}</X>`;
            xmlVector3 += `<Y>${Number.parseFloat(babylonVector3.z.toFixed(6))}</Y>`;
            xmlVector3 += `<Z>${Number.parseFloat(-babylonVector3.y.toFixed(6))}</Z>`;
            xmlVector3 += `</${tagName}>`;
            return xmlVector3;
        }

        xmlVector3 += `<X>${Number.parseFloat(-babylonVector3.x.toFixed(2))}</X>`;
        xmlVector3 += `<Y>${Number.parseFloat(-babylonVector3.z.toFixed(2))}</Y>`;
        xmlVector3 += `<Z>${Number.parseFloat(babylonVector3.y.toFixed(2))}</Z>`;
        xmlVector3 += `</${tagName}>`;
        return xmlVector3;
    }

    /**
     * Returns a uuid that you can use as RefKey for XML export
     */
    static GenerateRefKey() {
        return uuid();
    }
}
