import { APP_SYMBOL } from '../../..';
export default {
    name: 'TextPopup',
    props: {
        title: {
            type: String,
            default: function () { return ''; },
        },
        content: {
            type: String,
            default: function () { return ''; },
        },
    },
    methods: {
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
