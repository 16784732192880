import { AbstractMesh, TransformNode, Vector3 } from '@babylonjs/core';
import { TextBlock, Ellipse, Line, Rectangle } from '@babylonjs/gui';

/*
 * Gui interface that shows the size on each axis between a start and an end point
 * It allows to either :
 * - Display a dashed lines with text indicating the drag distance in mm
 * - Display a triangle with lines between summit and the size on each axis, and points on summits
 */
export default class DragSizeViewer {
    /**
     * Create gui elements needed, start, end and middle points
     * projected lines x, y and hypotenus
     * label and text for each lines
     * @param {GuiController} guiController
     * @param {Scene} scene scene to project the points into
     */
    constructor(guiController, scene, context) {
        this.context = context;
        this.scene = scene;

        if (guiController.isReady) {
            this.initGuiElements(guiController);
        } else {
            this.context.events.on('gui-ready', () => {
                this.initGuiElements(guiController);
            });
        }
    }

    initGuiElements(guiController) {
        this.initPoints(guiController);
        this.initDistanceLabel(guiController);
        this.initLines(guiController);
    }

    initPoints(guiController) {
        this.originPoint = new Ellipse('start-point');
        this.originPoint.width = '10px';
        this.originPoint.background = 'red';
        this.originPoint.height = '10px';
        this.originPoint.thickness = 0;
        this.originPoint.isVisible = false;
        guiController.addControl(this.originPoint);
        this._startPos = new Vector3();

        this.middlePoint = new Ellipse('middle-point');
        this.middlePoint.width = '10px';
        this.middlePoint.background = 'blue';
        this.middlePoint.height = '10px';
        this.middlePoint.thickness = 0;
        this.middlePoint.isVisible = false;
        guiController.addControl(this.middlePoint);

        this.endPoint = new Ellipse('end-point');
        this.endPoint.width = '10px';
        this.endPoint.background = 'red';
        this.endPoint.height = '10px';
        this.endPoint.thickness = 0;
        this.endPoint.isVisible = false;
        guiController.addControl(this.endPoint);
        this._endPos = new Vector3();
    }

    initDistanceLabel(guiController) {
        // Projected vectors label and text
        this.distanceXLabel = new Rectangle('distance-x-label');
        this.distanceXLabel.adaptWidthToChildren = true;
        this.distanceXLabel.adaptHeightToChildren = true;
        this.distanceXLabel.thickness = 0;
        this.distanceXLabel.zIndex = 10;
        guiController.addControl(this.distanceXLabel);

        this.distanceXText = new TextBlock();
        this.distanceXText.zIndex = 10;
        this.distanceXLabel.addControl(this.distanceXText);
        this.distanceXLabel.isVisible = false;

        this.distanceYLabel = new Rectangle('distance-y-label');
        this.distanceYLabel.adaptWidthToChildren = true;
        this.distanceYLabel.adaptHeightToChildren = true;
        this.distanceYLabel.thickness = 0;
        this.distanceYLabel.zIndex = 10;
        guiController.addControl(this.distanceYLabel);

        this.distanceYText = new TextBlock();
        this.distanceYText.zIndex = 10;
        this.distanceYLabel.addControl(this.distanceYText);
        this.distanceYLabel.isVisible = false;

        // Hypotenus distance label and text
        this.distanceLabel = new Rectangle('hypotenus-distance-label');
        this.distanceLabel.adaptWidthToChildren = true;
        this.distanceLabel.adaptHeightToChildren = true;
        this.distanceLabel.thickness = 0;
        this.distanceLabel.zIndex = 10;
        guiController.addControl(this.distanceLabel);

        this.distanceText = new TextBlock();
        this.distanceText.zIndex = 10;
        this.distanceLabel.addControl(this.distanceText);
        this.distanceLabel.isVisible = false;
    }

    initLines(guiController) {
        // Hypotenus line
        this.line = new Line();
        this.line.alpha = 0.5;
        this.line.color = 'black';
        this.line.lineWidth = 5;
        this.line.dash = [5, 10];
        this.line.isVisible = false;
        guiController.addControl(this.line);
        this.line.connectedControl = this.originPoint;

        // Projections lines
        this.lineX = new Line();
        this.lineX.alpha = 0.5;
        this.lineX.color = 'black';
        this.lineX.lineWidth = 5;
        this.lineX.isVisible = false;
        guiController.addControl(this.lineX);
        this.lineX.connectedControl = this.endPoint;

        this.lineY = new Line();
        this.lineY.alpha = 0.5;
        this.lineY.color = 'black';
        this.lineY.lineWidth = 5;
        this.lineY.isVisible = false;
        guiController.addControl(this.lineY);
        this.lineY.connectedControl = this.originPoint;
    }

    setEndPointPosition(position, updateMeasures = true) {
        this.endPoint.moveToVector3(position, this.scene);
        this._endPos = position;
        if (updateMeasures) {
            this.updateMeasures();
        }
    }

    setOriginPointPosition(position, updateMeasures = true) {
        this.originPoint.moveToVector3(position, this.scene);
        this._startPos = position;
        if (updateMeasures) {
            this.updateMeasures();
        }
    }

    updateMeasures(middle = null) {
        const middlePos = middle ?? new Vector3(this._endPos.x, this._endPos.y, this._startPos.z);
        this.middlePoint.moveToVector3(middlePos, this.scene);
        this.line.moveToVector3(this._endPos, this.scene);
        this.lineX.moveToVector3(middlePos, this.scene);
        this.lineY.moveToVector3(middlePos, this.scene);
        this.setLineDisplay(this._startPos, this._endPos);
        this.setLineXDisplay(middlePos, this._endPos);
        this.setLineYDisplay(this._startPos, middlePos);
    }

    setDistanceText(text) {
        this.distanceText.text = `${text} mm`;
    }

    setDistanceYText(text) {
        this.distanceYText.text = `${text} mm`;
    }

    setDistanceXText(text) {
        this.distanceXText.text = `${text} mm`;
    }

    moveDistanceLabel(vector) {
        this.distanceLabel.moveToVector3(vector, this.scene);
    }

    moveDistanceYLabel(vector) {
        this.distanceYLabel.moveToVector3(vector, this.scene);
    }

    moveDistanceXLabel(vector) {
        this.distanceXLabel.moveToVector3(vector, this.scene);
    }

    moveLine(vector) {
        this.line.moveToVector3(vector, this.scene);
    }

    moveLineX(vector) {
        this.lineX.moveToVector3(vector, this.scene);
    }

    moveLineY(vector) {
        this.lineY.moveToVector3(vector, this.scene);
    }

    /**
     * Set size displayed for the hypotenus line and the line label position
     */
    setLineDisplay(startPoint, endPoint) {
        const vector = endPoint.subtract(startPoint);

        this.setDistanceText(`${Math.round(vector.length() * 1000)}`);
        this.moveDistanceLabel(Vector3.Center(startPoint, endPoint));
    }

    /**
     * Set size displayed for X line and the line label position
     */
    setLineXDisplay(startPoint, endPoint) {
        const xVector = endPoint.subtract(startPoint);

        this.setDistanceXText(`${Math.round(xVector.length() * 1000)}`);
        this.moveDistanceXLabel(Vector3.Center(startPoint, endPoint));
    }

    /**
     * Set size displayed for Y line and the line label position
     */
    setLineYDisplay(startPoint, endPoint) {
        const yVector = endPoint.subtract(startPoint);

        this.setDistanceYText(`${Math.round(yVector.length() * 1000)}`);
        this.moveDistanceYLabel(Vector3.Center(startPoint, endPoint));
    }

    setPointFromMesh(mesh, reset = false, updateMeasures = true) {
        let meshCenter = null;
        if (mesh instanceof AbstractMesh) {
            meshCenter = mesh.entity.getBoundingBox().centerWorld.clone();
        } else if (mesh instanceof TransformNode) {
            meshCenter = mesh.getAbsolutePosition().clone();
        } else {
            throw new Error("Can't drag plane because the given object is not a mesh or a node");
        }

        // Set gui points position
        if (reset) {
            this.setOriginPointPosition(meshCenter, false);
        }
        this.setEndPointPosition(meshCenter, updateMeasures);
    }

    setPointFromMeshInAxis(mesh, axis) {
        this.setPointFromMesh(mesh, false, false);
        const dragVector = this._endPos.subtract(this._startPos);

        // Find projection point of the vector on axis of the basis
        const projectedPoint = axis.scale(Vector3.Dot(axis, dragVector));

        // Put the point in the translation space
        projectedPoint.addInPlace(this._startPos);

        this.updateMeasures(projectedPoint);
    }

    /*
     * Toggle the visibility of all gui elements to provide a right triangle with lines between
     * summits and points on summits
     *
     * @param {boolean} visibility
     */
    togglePlaneModeVisibility(visibility) {
        this.originPoint.isVisible = visibility;
        this.middlePoint.isVisible = visibility;
        this.endPoint.isVisible = visibility;

        this.distanceLabel.isVisible = false;
        this.distanceXLabel.isVisible = visibility;
        this.distanceYLabel.isVisible = visibility;

        this.line.isVisible = false;
        this.lineX.isVisible = visibility;
        this.lineY.isVisible = visibility;
    }

    /*
     * Only toggle the visibility of the start point, the end point and the dashed line
     * @param {boolean} visibility
     */
    toggleLineModeVisibility(visibility) {
        this.originPoint.isVisible = visibility;
        this.middlePoint.isVisible = false;
        this.endPoint.isVisible = visibility;

        this.distanceLabel.isVisible = visibility;
        this.distanceXLabel.isVisible = false;
        this.distanceYLabel.isVisible = false;

        this.line.isVisible = visibility;
        this.lineX.isVisible = false;
        this.lineY.isVisible = false;
    }
}
