import { APP_SYMBOL } from '../../..';
import { IMAGE_MODES } from '../../../../building-plan-manager/src/controller/building-plan-image-controller';
import EventBus from '../../eventBus';
export default {
    name: 'BuildingPlanTable',
    props: {
        annotationData: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
            bpImageController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanImageController,
            IMAGE_MODES: null,
            canvasScaleRatio: 4,
            canvas: null,
            canvasContext: null,
            annotation: null,
            annotationX: 0,
            annotationY: 0,
            annotationDataMutable: this.annotationData,
        };
    },
    computed: {
        imageUrl: function () {
            if (this.annotationData.image) {
                return {
                    backgroundImage: "url(".concat(this.annotationData.image, ")"),
                };
            }
            return '';
        },
        canBeModified: function () {
            return this.bpController.isPageEditable();
        },
    },
    mounted: function () {
        // TODO: Use only one canvas to draw all the lines ! #performanceImprovement
        // Doing the resize manually instead off using a class because
        // the class style is applied after the mounted
        // So we got a canvas that is to small with a line that can't go to our target position,
        // upscaled.
        this.canvas = this.$refs.annotationCanvas;
        this.annotation = this.$refs.annotationCircle;
        // Set canvas size in CSS
        this.canvas.style.width = this.canvas.parentElement.offsetWidth;
        this.canvas.style.height = this.canvas.parentElement.offsetHeight;
        // Set canvas pixel size
        this.canvas.width = this.canvas.parentElement.offsetWidth * this.canvasScaleRatio;
        this.canvas.height = this.canvas.parentElement.offsetHeight * this.canvasScaleRatio;
        this.context2d = this.canvas.getContext('2d');
        this.context2d.scale(this.canvasScaleRatio, this.canvasScaleRatio);
        this.container = document.getElementById('canvas-container');
        // Restore annotation position
        var positionX = this.annotationDataMutable.annotationPosition.x;
        var positionY = this.annotationDataMutable.annotationPosition.y;
        this.setAnnotationPosition(positionX, positionY, false);
        this.drawLine(positionX, positionY);
        window[APP_SYMBOL].events.on('@building-plan-manager.end-page-editing', this.onEndPageEditing);
        // if this annotation has a parent annotation, listen fot its events
        // to reproduce its movement
        if (this.annotationDataMutable.parent) {
            EventBus.$on('annotation-move', this.onParentMove);
            // position saved when clicking
            EventBus.$on('annotation-click', this.onParentClick);
        }
        if (this.annotationData.editing) {
            this.addListeners();
        }
    },
    /**
     * remove events once destroyed
     */
    unmounted: function () {
        if (this.annotationDataMutable.parent) {
            EventBus.$off('annotation-move', this.onParentMove);
            EventBus.$off('annotation-click', this.onParentClick);
        }
        window[APP_SYMBOL].events.removeListener('@building-plan-manager.end-page-editing', this.onEndPageEditing);
    },
    methods: {
        drawLine: function (startPositionX, startPositionY) {
            this.context2d.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context2d.beginPath();
            // Line
            this.context2d.moveTo(startPositionX, startPositionY);
            this.context2d.lineTo(this.annotationData.tipPosition.x, this.annotationData.tipPosition.y);
            this.context2d.stroke();
            this.context2d.closePath();
            // Tip point
            this.context2d.beginPath();
            this.context2d.arc(this.annotationData.tipPosition.x, this.annotationData.tipPosition.y, 2.5, 0, 2 * Math.PI);
            this.context2d.fill();
            this.context2d.closePath();
        },
        setAnnotationPosition: function (startPositionX, startPositionY, propagate) {
            if (propagate === void 0) { propagate = true; }
            if (propagate) {
                // event to update the child annotations
                EventBus.$emit('annotation-move', {
                    uuid: this.annotationDataMutable.uuid,
                    dx: startPositionX - this.annotationX,
                    dy: startPositionY - this.annotationY,
                });
            }
            this.annotation.style.left = startPositionX - this.annotation.offsetWidth / 2;
            this.annotation.style.top = startPositionY - this.annotation.offsetHeight / 2;
            this.annotationX = startPositionX;
            this.annotationY = startPositionY;
        },
        onMoveLocal: function (event) {
            var positionX = event.target.offsetLeft + event.offsetX;
            var positionY = event.target.offsetTop + event.offsetY;
            this.setAnnotationPosition(positionX, positionY);
            this.drawLine(positionX, positionY);
        },
        onMoveOnCanvas: function (event) {
            this.setAnnotationPosition(event.offsetX, event.offsetY);
            this.drawLine(event.offsetX, event.offsetY);
        },
        onClick: function (event) {
            if (this.annotationData.editing) {
                this.removeListeners();
                this.bpImageController.captureImage(IMAGE_MODES.HTML);
                // Center of the circle
                this.annotationDataMutable.annotationPosition.x = event.target.offsetLeft + event.target.offsetWidth / 2;
                this.annotationDataMutable.annotationPosition.y = event.target.offsetTop + event.target.offsetHeight / 2;
                // event to update the child annotations
                EventBus.$emit('annotation-click', { uuid: this.annotationDataMutable.uuid });
            }
            else {
                this.addListeners();
            }
            this.annotationDataMutable.editing = !this.annotationDataMutable.editing;
        },
        onEndPageEditing: function () {
            if (this.annotationData.editing) {
                this.removeListeners();
                this.bpImageController.captureImage(IMAGE_MODES.HTML);
                this.annotationDataMutable.editing = false;
            }
        },
        onParentMove: function (_a) {
            var uuid = _a.uuid, dx = _a.dx, dy = _a.dy;
            if (this.annotationDataMutable.parent.uuid === uuid) {
                var newPos = { x: this.annotationX + dx, y: this.annotationY + dy };
                this.setAnnotationPosition(newPos.x, newPos.y);
                this.annotationData.tipPosition.x += dx;
                this.annotationData.tipPosition.y += dy;
                this.drawLine(newPos.x, newPos.y);
            }
        },
        onParentClick: function (_a) {
            var uuid = _a.uuid;
            if (this.annotationDataMutable.parent.uuid === uuid) {
                this.annotationDataMutable.annotationPosition.x = this.annotationX;
                this.annotationDataMutable.annotationPosition.y = this.annotationY;
            }
        },
        addListeners: function () {
            this.container.addEventListener('pointermove', this.onMoveLocal);
        },
        removeListeners: function () {
            this.container.removeEventListener('pointermove', this.onMoveLocal);
        },
    },
};
