import BecadHelper from '../helper/becad-helper';
import pako from 'pako';

export default class LambdaConverter {
    static encodeToBase64(XMLString) {
        const compressedXML = pako.deflate(XMLString, {
            raw: true,
            to: 'string',
        });
        return Buffer.from(compressedXML, 'latin1').toString('base64');
    }

    static decodeFromBase64(XMLBase64) {
        const compressedXML = Buffer.from(JSON.parse(XMLBase64), 'base64').toString('latin1');
        let XML = pako.inflate(compressedXML, {
            raw: true,
            to: 'string',
        });
        XML = BecadHelper.removeAutoClosedConnectors(XML);
        XML = BecadHelper.renameConnectionStringByConnector(XML);
        return XML;
    }
}
