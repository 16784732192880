import { APP_SYMBOL } from '../../..';
export default {
    name: 'DialogPopin',
    props: {
        title: {
            type: String,
            default: function () { return ''; },
        },
        description: {
            type: String,
            default: function () { return ''; },
        },
        onValidate: {
            type: Function,
            default: function () { return function () { }; },
        },
        onCancel: {
            type: Function,
            default: function () { return function () { }; },
        },
        isRequired: {
            type: Boolean,
            default: function () { return false; },
        },
    },
    emits: ['hidePopin'],
    methods: {
        validate: function () {
            if (typeof this.onValidate === 'function') {
                this.onValidate();
                this.$emit('hidePopin');
            }
            window[APP_SYMBOL].events.emit('popin-closed');
        },
        cancel: function () {
            this.$emit('hidePopin');
            if (typeof this.onCancel === 'function') {
                this.onCancel();
            }
            window[APP_SYMBOL].events.emit('popin-closed');
        },
        close: function () {
            this.$emit('hidePopin');
            window[APP_SYMBOL].events.emit('popin-closed');
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
