import camera from './camera.vue';
import history from './history.vue';
import actions from './actions.vue';
import sceneInteractions from './scene-interactions.vue';
import grid from './grid.vue';
import EventBus from '../eventBus';
import RightsHelper from '../../../../helpers/rights-helper';
import { PAGE_TYPES } from '../../../building-plan-manager/src/model/page-structure';
import { IMAGE_MODES } from '../../../building-plan-manager/src/controller/building-plan-image-controller';
import { APP_SYMBOL } from '../..';
export default {
    name: 'TopMenu',
    components: {
        camera: camera,
        history: history,
        actions: actions,
        sceneInteractions: sceneInteractions,
        grid: grid,
    },
    props: {
        cameraData: {
            type: Object,
            default: function () { return ({
                orthographic: false,
            }); },
        },
        gridData: {
            type: Object,
            default: function () { return ({}); },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
        optionsData: {
            type: Object,
            default: function () { return ({
                optionsFamilies: {},
                showInfills: true,
                infillsVisibilityLocked: false,
            }); },
        },
        materialData: {
            type: Object,
            default: function () { return ({
                areFramesTransparent: false,
            }); },
        },
        measurementData: {
            type: Object,
            default: function () { return ({
                measureModeActivated: false,
            }); },
        },
        buildingPlanData: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            isExpanded: false,
            isPublic: RightsHelper.isModePublic(),
            localMeasureMode: this.measurementData.measureModeActivated,
            bpController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController,
            bpImageController: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController.imageController,
            bpHelper: window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanHelper,
            connectorSelectionModeEnabled: false,
            PAGE_TYPES: PAGE_TYPES,
        };
    },
    computed: {
        canDisplayGrid: function () {
            return RightsHelper.isRoleDesigner() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayScreenshotButton: function () {
            return (RightsHelper.isRoleDesigner() &&
                RightsHelper.isPlanBasic() &&
                !RightsHelper.isModePublic() &&
                !RightsHelper.isModeBuildingPlan());
        },
        canDisplayCamera: function () {
            return this.isScenePageActiveCamera();
        },
        canDisplaySceneInteractions: function () {
            return !this.buildingPlanData.isCameraFixed && this.buildingPlanData.currentPage.type === PAGE_TYPES.SCENE;
        },
        canDisplayMeasurementButton: function () {
            return ((RightsHelper.isRoleDesigner() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan()) ||
                (RightsHelper.isModeBuildingPlan() && this.isScenePageActiveCamera()));
        },
        canDisplayHistory: function () {
            return RightsHelper.isRoleDesigner() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayActions: function () {
            return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplaySaveButton: function () {
            return RightsHelper.isRoleDesigner() && RightsHelper.isMode3dAuthenticated();
        },
        isConnectorSelectionOn: function () {
            return RightsHelper.isModeBuildingPlan() && this.canBeEdited && this.pageConnectorSelectionModeEnabled;
        },
        isConnectorSelectionOff: function () {
            return RightsHelper.isModeBuildingPlan() && this.canBeEdited && !this.pageConnectorSelectionModeEnabled;
        },
        // Pdf mode only
        // Does the page have a scene screenshot
        canDisplayPdfModeButtons: function () {
            return RightsHelper.isModeBuildingPlan() && RightsHelper.isPlanEssential();
        },
        canBeValidated: function () {
            var page = this.bpController.getPage();
            return (this.bpController.isPageEditable() &&
                (page.type === PAGE_TYPES.HTML || (page.type === PAGE_TYPES.SCENE && page.images.SCENE)));
        },
        canBeModified: function () {
            if (!this.bpController) {
                return false;
            }
            var page = this.bpController.getPage();
            return (page &&
                !this.bpController.isPageEditable() &&
                (page.type === PAGE_TYPES.HTML || (page.type === PAGE_TYPES.SCENE && page.images.SCENE)));
        },
        canBeEdited: function () {
            var page = this.bpController.getPage();
            return this.bpController.isPageEditable() && page.type === PAGE_TYPES.SCENE && page.images.SCENE;
        },
        pageConnectorSelectionModeEnabled: function () {
            var page = this.bpController.getPage();
            return this.bpController.isPageEditable() && page.scenePageMetadata.connectorSelectionModeEnabled;
        },
    },
    mounted: function () {
        var _this = this;
        EventBus.$on('toggle-groups', function (open) {
            _this.isExpanded = !open;
        });
        window[APP_SYMBOL].events.on('checkVersion', function (onValidateEvent, isRequired) {
            if (isRequired === void 0) { isRequired = false; }
            if (_this.config.versionName === '') {
                if (onValidateEvent === 'save') {
                    _this.openVersionPopin(onValidateEvent, isRequired);
                }
                else {
                    _this.openMustSavePopin(onValidateEvent, isRequired);
                }
            }
            else {
                if (onValidateEvent === 'save') {
                    _this.$emit('onSaveProject');
                }
                window[APP_SYMBOL].modules.iframeApi.sendEvent(onValidateEvent);
                EventBus.$emit(onValidateEvent);
            }
        });
    },
    methods: {
        isScenePageActiveCamera: function () {
            return !this.buildingPlanData.isCameraFixed && this.buildingPlanData.currentPage.type === this.PAGE_TYPES.SCENE;
        },
        environmentSelection: function () {
            window[APP_SYMBOL].events.emit('checkVersion', 'open-environment-selector', true);
        },
        /**
         * Shows a popin where the user give its project a version name
         */
        openVersionPopin: function (onValidateEvent, isRequired) {
            var _this = this;
            if (onValidateEvent === void 0) { onValidateEvent = 'save'; }
            if (isRequired === void 0) { isRequired = false; }
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('New version'),
                description: "".concat(this._('Please insert the version name'), "\n                    : <br />\n                        <input type=\"text\" id=\"version-name\" required pattern= \"^([a-zA-Z0-9.]|\\s)+$\">\n                    <br />\n                        <span class=\"warning-info\">\n                            ").concat(this._('Warning : Please give a valid version name' +
                    ', if you write any special characters like (!,_-^%)' +
                    ' or give an empty version name,the submission will be blocked.'), "\n                        </span>"),
                isRequired: isRequired,
                onValidate: function () {
                    window[APP_SYMBOL].events.emit('lockUnload');
                    _this.config.versionName = document.getElementById('version-name').value;
                    if (onValidateEvent === 'save') {
                        _this.$emit('onSaveProject');
                    }
                    window[APP_SYMBOL].modules.iframeApi.sendEvent(onValidateEvent);
                },
            });
        },
        openMustSavePopin: function () {
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._("Can't take screenshot"),
                description: "".concat(this._('You must have saved your project to take a screenshot')),
                isRequired: true,
                onValidate: function () { },
            });
        },
        save: function () {
            window[APP_SYMBOL].events.emit('checkVersion', 'save', true);
        },
        toggleMeasurement: function () {
            window[APP_SYMBOL].modules.measurementManager.controller.toggleMeasureMode();
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
        // Pdf mode only
        validatePage: function () {
            if (this.bpController.currentPage.type === PAGE_TYPES.HTML) {
                this.bpController.imageController.captureImage(IMAGE_MODES.HTML);
            }
            this.bpController.writePageToPdf();
            this.bpController.endPageEditing();
        },
        modifyPage: function () {
            var selectionController = window[APP_SYMBOL].modules.selectionManager;
            selectionController.unselectAll();
            this.bpController.clearPage();
            this.bpController.recreatePage();
            this.bpController.startPageEditing();
        },
        /**
         * Toggle connector selection mode
         * We also need to toggle frame transparency and infill visibility
         */
        toggleConnectorSelectionMode: function () {
            var page = this.bpController.getPage();
            var connectorSelectionMode = !page.scenePageMetadata.connectorSelectionModeEnabled;
            this.bpHelper.setConnectorSelectionMode(page, connectorSelectionMode);
        },
        /**
         * Saves the building plan structure as an obsidian file
         */
        savePdf: function () {
            var _this = this;
            if (!this.bpController.isPdfComplete()) {
                this.openPdfIncompletePopin(false, function () {
                    _this.bpController.save();
                });
            }
            else {
                this.bpController.save();
            }
        },
        /**
         * Download the building plan pdf passing by BO
         */
        downloadPdf: function () {
            var _this = this;
            if (!this.bpController.isPdfComplete()) {
                this.openPdfIncompletePopin(false, function () {
                    _this.bpController.generatesTemporaryPdf();
                });
            }
            else {
                this.bpController.generatesTemporaryPdf();
            }
        },
        openPdfIncompletePopin: function (isRequired, onValidate) {
            window[APP_SYMBOL].events.emit('open-popin', {
                title: this._('PDF incomplete'),
                description: "".concat(this._('You are about to download an imcomplete PDF'), "\n                    <br />\n                    <br />\n                    <span class=\"warning-info\">\n                        ").concat(this._('Warning : Pages are still being edited.' +
                    'The resulting PDF can be different ' +
                    'from the preview or pages maybe blank!'), "\n                    </span>"),
                isRequired: isRequired,
                onValidate: onValidate,
            });
        },
    },
};
