import UiController from './src/ui-controller';

const MODULE_NAME = 'bematrix-ui';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'camera-manager',
        'grid-manager',
        'catalog-manager',
        'scene-manager',
        'gizmo-manager',
        'project-manager',
        'group-manager',
        'iframe-api',
        'obsidian-engine',
        'option-manager',
        'stonejs',
        'history',
        'init-utility',
        'selection-manager',
        'snapping-manager',
        'data-store',
        'material-manager',
        'building-plan-manager',
        'measurement-manager',
        'entity-manager',
        'assets-manager',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        return new UiController(app);
    },
    unload() {},
};
