// Overridable in .env.local, default for dev is 'DEV' but fallback value is PROD
const LAMBDA_ENV = process.env.LAMBDA_ENV_TARGET ?? 'PROD';

export default class LambdaParamsFactory {
    static buildLambdaParamsForConnectors(payload) {
        return {
            FunctionName: `StandEngineer:${LAMBDA_ENV}`,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload),
            LogType: 'None',
        };
    }

    static buildLambdaParamsForInfills(payload) {
        return {
            FunctionName: `PanelPlan:${LAMBDA_ENV}`,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload),
            LogType: 'None',
        };
    }

    static buildLambdaParamsForCovers(payload) {
        return {
            FunctionName: `Covers:${LAMBDA_ENV}`,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload),
            LogType: 'None',
        };
    }

    static buildLambdaParamsForPanelPlan(payload) {
        return {
            FunctionName: `GenerateDXF:${LAMBDA_ENV}`,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload),
            LogType: 'None',
        };
    }
}
