import SelectionController from './src/controllers/selection-controller';
import SelectionHelper from './src/helpers/selection-helper';

const MODULE_NAME = 'selection-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: [
        'obsidian-engine',
        'gizmo-manager',
        'collision-manager',
        'geometry-utility',
        'history',
        'data-store',
        'option-manager',
        'highlight-manager',
        'grid-manager',
        'mesh-manager',
        'entity-manager',
        'gui-manager',
        'option-manager',
        'camera-manager',
        'measurement-manager',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        SelectionHelper.setContext(app);
        return new SelectionController(app);
    },
    unload() {},
};
