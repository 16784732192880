import { APP_SYMBOL } from '../..';
export default {
    name: 'Grid',
    props: {
        gridData: {
            type: Object,
            default: function () { return ({
                height: 10,
                width: 12,
            }); },
        },
        config: {
            type: Object,
            default: function () { return ({}); },
        },
    },
    data: function () {
        return {
            maxWidth: 99,
            maxHeight: 99,
            width: 0,
            height: 0,
        };
    },
    computed: {
        unit: function () {
            return this.config.unitMode === 'us' ? 'in' : 'm';
        },
        // Use parse(stringify) to avoid the vue to point directly on the data and modify it
        gridWidth: function () {
            return JSON.parse(JSON.stringify(this.gridData.width));
        },
        gridHeight: function () {
            return JSON.parse(JSON.stringify(this.gridData.height));
        },
        updateWidth: {
            get: function () {
                return this.width - (this.width % 0.5);
            },
            set: function (val) {
                var width = this.comaToDot(val);
                if (Number.isNaN(Number(width))) {
                    width = 0;
                }
                else if (this.config.unitMode === 'eu' && width > this.maxWidth) {
                    width = this.maxWidth;
                }
                else if (this.config.unitMode === 'us' && width > this.toInches(this.maxWidth)) {
                    width = this.toInches(this.maxWidth);
                }
                this.width = width;
            },
        },
        updateHeight: {
            get: function () {
                return this.height - (this.height % 0.5);
            },
            set: function (val) {
                var height = this.comaToDot(val);
                if (Number.isNaN(Number(height))) {
                    height = 0;
                }
                else if (this.config.unitMode === 'eu' && height > this.maxHeight) {
                    height = this.maxHeight;
                }
                else if (this.config.unitMode === 'us' && height > this.toInches(this.maxHeight)) {
                    height = this.toInches(this.maxHeight);
                }
                this.height = height;
            },
        },
    },
    watch: {
        gridWidth: function (newVal) {
            this.width = newVal;
        },
        gridHeight: function (newVal) {
            this.height = newVal;
        },
    },
    mounted: function () {
        this.width = this.gridWidth;
        this.height = this.gridHeight;
        window[APP_SYMBOL].events.on('modify-unit', function () {
            window[APP_SYMBOL].modules.gridManager.switchUnit();
        });
    },
    methods: {
        comaToDot: function (value) {
            var finalValue = value;
            if (Number.isInteger(value)) {
                finalValue = value.toString();
            }
            if (typeof finalValue === 'string') {
                if (finalValue.includes(',')) {
                    finalValue = finalValue.replace(',', '.');
                }
                finalValue = Number.parseFloat(finalValue);
            }
            return finalValue;
        },
        toInches: function (meterValue) {
            return meterValue * this.gridData.toInchesConstant;
        },
        updateGridData: function () {
            // update vue gridData with the form content
            this.gridData.width = this.width;
            this.gridData.height = this.height;
            // send them to the grid module
            window[APP_SYMBOL].modules.gridManager.computeDataFromVue(this.width, this.height);
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
