import EntityController from './src/controllers/entity-controller';
import EntityStructure from './src/model/entity-structure';
import FrameStructure from './src/model/frame-structure';
import CoverStructure from './src/model/cover-structure';
import CornerStructure from './src/model/corner-structure';
import StructuralStructure from './src/model/structural-structure';
import ConnectorStructure from './src/model/connector-structure';
import LedskinStructure from './src/model/ledskin-structure';
import SideledLightboxStructure from './src/model/sideled-lightbox-structure';
import BackledLightboxStructure from './src/model/backled-lightbox-structure';
import FlooringConnectorStructure from './src/model/flooring-connector-structure';
import MotionskinStructure from './src/model/motionskin-structure';
import DoorStructure from './src/model/door-structure';
import TracklightStructure from './src/model/tracklight-structure';
import PopinStructure from './src/model/popin-structure';
import BeTrussB30Structure from './src/model/betruss-b30-structure';
import BeTrussSquareStructure from './src/model/betruss-square-structure';
import Helper from './src/helpers/entity-helper';

const MODULE_NAME = 'entity-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);

export default {
    name: MODULE_NAME,
    requires: ['data-store', 'option-manager', 'catalog-manager', 'geometry-utility', 'mesh-manager', 'measurement-manager'],
    load(app) {
        window[APP_SYMBOL] = app;
        return {
            controller: new EntityController(app),
            entityStructure: EntityStructure,
            frameStructure: FrameStructure,
            connectorStructure: ConnectorStructure,
            coverStructure: CoverStructure,
            cornerStructure: CornerStructure,
            structuralStructure: StructuralStructure,
            ledskinStructure: LedskinStructure,
            sideledLightboxStructure: SideledLightboxStructure,
            backledLightboxStructure: BackledLightboxStructure,
            motionskinStructure: MotionskinStructure,
            flooringConnectorStructure: FlooringConnectorStructure,
            doorStructure: DoorStructure,
            tracklightStructure: TracklightStructure,
            popinStructure: PopinStructure,
            beTrussB30Structure: BeTrussB30Structure,
            beTrussSquareStructure: BeTrussSquareStructure,
            Helper,
            PRODUCT_ENTITIES_NAME: Helper.PRODUCT_ENTITIES_NAME,
        };
    },
    unload() {},
};
