import { HemisphericLight, PointLight, Vector3 } from '@babylonjs/core';

export default class LightController {
    constructor(context) {
        this.context = context;
        this.engineController = this.context.modules.obsidianEngine.controller;
        this.lights = [];
        this.context.events.on('@camera-manager.camera-ready', () => {
            this.init();
            this.initEvents();
        });
    }

    init() {
        this.scene = this.engineController.scene;
        // Add lights to the scene
        this.topLight = new HemisphericLight('top-light', new Vector3(0, 1, 0), this.scene);
        this.topLight.falloffType = HemisphericLight.FALLOFF_STANDARD;
        this.cameraLight = new HemisphericLight('camera-light', new Vector3(0, 1, 0), this.scene);
        this.cameraLight.falloffType = HemisphericLight.FALLOFF_STANDARD;
        this.updateLightDirection();
        this.cameraLight.intensity = 0.3;

        this.lights.push(this.topLight);
        this.lights.push(this.cameraLight);
    }

    initEvents() {
        this.engineController.scene.activeCamera.onViewMatrixChangedObservable.add(() => {
            this.updateLightDirection();
        });
    }

    createPointLight(position) {
        const light = new PointLight(`pointLight${this.lights.length}`, position, this.light);
        light.falloffType = PointLight.FALLOFF_STANDARD;
        this.lights.push(light);
    }

    updateLightDirection() {
        const direction = this.scene.activeCamera.position.subtract(this.scene.activeCamera.target).normalize();
        this.cameraLight.direction = direction;
    }
}
