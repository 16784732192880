import { APP_SYMBOL } from '../..';
export default {
    name: 'History',
    data: function () {
        return {
            isFirst: window[APP_SYMBOL].modules.history.isFirst(),
            isLast: window[APP_SYMBOL].modules.history.isLast(),
        };
    },
    mounted: function () {
        var _this = this;
        ['@history.history-snapshot', '@history.history-go', '@history.history-clear'].forEach(function (event) {
            window[APP_SYMBOL].events.on(event, function () {
                _this.updateButtons();
            });
        });
    },
    methods: {
        back: function () {
            window[APP_SYMBOL].modules.entityManager.controller.freezeAll(false, false);
            window[APP_SYMBOL].modules.selectionManager.unselectAll();
            window[APP_SYMBOL].modules.history.back();
        },
        forward: function () {
            window[APP_SYMBOL].modules.entityManager.controller.freezeAll(false, false);
            window[APP_SYMBOL].modules.selectionManager.unselectAll();
            window[APP_SYMBOL].modules.history.forward();
        },
        updateButtons: function () {
            // isFirst and isLast are only getters, we can't set vueData so we need to
            // update informations on snapshot or on "go"
            this.isFirst = window[APP_SYMBOL].modules.history.isFirst();
            this.isLast = window[APP_SYMBOL].modules.history.isLast();
        },
    },
};
