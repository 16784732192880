import productOption from './product-option.vue';
import RightsHelper from '../../../../../helpers/rights-helper';
import { PAGE_TYPES } from '../../../../building-plan-manager/src/model/page-structure';
import { APP_SYMBOL } from '../../..';
export default {
    name: 'GroupProduct',
    components: { productOption: productOption },
    props: {
        product: {
            type: Object,
            default: function () { return ({}); },
        },
        showOptions: {
            type: Boolean,
            default: true,
        },
        groupSelected: {
            type: Boolean,
            default: true,
        },
        groupIsDisplayed: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            optionController: null,
            options: null,
            optionsNumber: null,
            buildingPlanData: null,
        };
    },
    computed: {
        // Check if the product is selected
        isSelected: function () {
            var groupSelection = window[APP_SYMBOL].modules.groupManager.GroupSelection;
            return groupSelection.isSelectedProduct(this.product);
        },
        canInteractWithProduct: function () {
            return !RightsHelper.isModePublic() && !this.buildingPlanData.isCameraFixed;
        },
        canRemoveProduct: function () {
            return !RightsHelper.isModeBuildingPlan();
        },
    },
    created: function () {
        this.optionController = window[APP_SYMBOL].modules.optionManager.optionController;
        var buildingPlanController = window[APP_SYMBOL].modules.buildingPlanManager.buildingPlanController;
        this.buildingPlanData = buildingPlanController.vueData;
    },
    mounted: function () {
        var _this = this;
        this.options = this.optionController.getEntityOptions(this.product);
        this.optionsNumber = this.optionController.getEntityOptionsNumber(this.product);
        this.initUpdateOptionsEvent();
        window[APP_SYMBOL].events.on('@building-plan-manager.change-product-visibility', function () {
            _this.$forceUpdate();
        });
    },
    methods: {
        /**
         * Update option UI on layers UI when the options are updated
         */
        initUpdateOptionsEvent: function () {
            var _this = this;
            [
                '@option-manager.update-baseplate-option',
                '@option-manager.update-groundplate-option',
                '@option-manager.update-light-option',
                '@option-manager.update-shelf-option',
                '@option-manager.update-screen-option',
                '@option-manager.update-shelf-number',
                '@option-manager.update-screen-number',
                '@option-manager.update-light-number',
                '@option-manager.update-infill-option',
            ].forEach(function (event) {
                window[APP_SYMBOL].events.on(event, function (id) {
                    if (id === _this.product.id || id === _this.product) {
                        _this.options = _this.optionController.getEntityOptions(_this.product);
                        _this.optionsNumber = _this.optionController.getEntityOptionsNumber(_this.product);
                    }
                });
            });
        },
        // Unselect or select a product on the UI
        toggleProductSelection: function () {
            var measurementController = window[APP_SYMBOL].modules.measurementManager.controller;
            var groupSelection = window[APP_SYMBOL].modules.groupManager.GroupSelection;
            if (!this.buildingPlanData.isCameraFixed &&
                !measurementController.measureModeActivated &&
                this.buildingPlanData.currentPage.type !== PAGE_TYPES.HTML) {
                if (this.isSelected) {
                    groupSelection.unselectProduct(this.product);
                }
                else {
                    groupSelection.selectProduct(this.product);
                }
            }
        },
        // We emit events so the parent (group) will update it's UI in case of
        // hiding or displaying a frame of a group
        setProductVisibility: function (visibility) {
            if (!visibility && this.isSelected) {
                this.toggleProductSelection();
            }
            var DisplayHelper = window[APP_SYMBOL].modules.groupManager.DisplayHelper;
            DisplayHelper.setProductVisibility(this.product, visibility);
            if (!visibility) {
                this.$emit('productHidden');
            }
            else {
                this.$emit('productDisplayed');
            }
            this.$forceUpdate();
        },
        removeProduct: function () {
            var selectionManager = window[APP_SYMBOL].modules.selectionManager;
            if (selectionManager.selectedMeshes.length === 1 && selectionManager.selectedMeshes[0].entity.id === this.product.id) {
                selectionManager.removeCurrentEntity();
                window[APP_SYMBOL].modules.history.snapshot();
            }
            else {
                selectionManager.deleteEntityByRef(this.product);
            }
        },
        _: function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = window[APP_SYMBOL].modules.stonejs).gettext.apply(_a, args);
        },
    },
};
