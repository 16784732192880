import BuildingPlanController, { TEXT_SIZES } from './src/controller/building-plan-controller';
import BuildingPlanHelper from './src/helper/building-plan-helper';
import { PAGE_TYPES } from './src/model/page-structure';

import './src/vendors/jspdf-api-extension';

const MODULE_NAME = 'building-plan-manager';
export const APP_SYMBOL = Symbol(MODULE_NAME);
export default {
    name: MODULE_NAME,
    requires: [
        'obsidian-engine',
        'data-store',
        'camera-manager',
        'group-manager',
        'selection-manager',
        'material-manager',
        'option-manager',
        'catalog-manager',
        'iframe-api',
        'geometry-utility',
        'measurement-manager',
        'stonejs',
    ],
    load(app) {
        window[APP_SYMBOL] = app;
        BuildingPlanHelper.setContext(app);

        const buildingPlanController = new BuildingPlanController(app);
        const buildingPlanImageController = buildingPlanController.imageController;

        return {
            buildingPlanController,
            buildingPlanImageController,
            buildingPlanHelper: BuildingPlanHelper,
            PAGE_TYPES,
            TEXT_SIZES,
        };
    },
    unload() {},
};
