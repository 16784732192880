import EntityStructure from './entity-structure';
import Entity from '../../../data-store/src/entity';

const FlooringConnectorStructure = EntityStructure.$extend({
    __name__: 'flooring-connector',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(FlooringConnectorStructure);
export default FlooringConnectorStructure;
